import React, { FunctionComponent } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import {
  SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType,
} from 'react-swipeable-list';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import ListItem from '../../../components/ListItem/ListItem';
import Text from '../../../components/CustomTextFormatter/Text';
import PTExercisesController from './controller/PTExercisesPage.controller';
import { PATH_CONSTANTS } from '../../../helper/constants';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import {
  ActionContent,
  deleteIconStyle,
  deleteSwipeStyle,
  editSwipeStyle,
  IconContainer,
  ItemColumnCentered,
} from './PTExercisePage.style';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import { IExercise } from '../../../common/interfaces/Exercise';
import { pageMainCard } from '../../../common/styles/commonComponents';
import CustomSearchBar from '../../../components/SearchBar/SearchBar';

interface OwnProps {}

type Props = OwnProps;

const PTExercises: FunctionComponent<Props> = () => {
  const {
    searching, onChange, resetSearch, exerciseToDisplay, loading,
    setApiError, apiError, setExerciseToBeHandled, setOpenDeletePopup, onDeleteExercise,
    openDeletePopup, onClickSearchButton,
  } = PTExercisesController();

  const navigate = useNavigate();

  const {
    EXERCISES_PT_PATH, ADD_EXERCISE_PT_PATH, EDIT_EXERCISE_PT_PATH,
  } = PATH_CONSTANTS;

  const trailingActions = (ex: IExercise) => (
    <TrailingActions>
      <SwipeAction onClick={() => navigate(`${EDIT_EXERCISE_PT_PATH}/${ex._id}`)}>
        <ActionContent style={editSwipeStyle}>
          Edit
        </ActionContent>
      </SwipeAction>
      <SwipeAction
        onClick={() => {
          setExerciseToBeHandled(ex);
          setOpenDeletePopup(true);
        }}
      >
        <ActionContent style={deleteSwipeStyle}>
          <ItemColumnCentered>
            <span className="icon">
              <DeleteOutlineIcon sx={deleteIconStyle} />
            </span>
          </ItemColumnCentered>
        </ActionContent>
      </SwipeAction>
    </TrailingActions>
  );

  if (loading) return (<LoaderSpinner />);

  return (
    <Layout>
      <>
        <Pagebar pt indexPage title="Esercizi" />

        <CustomSearchBar
          placeholder="Cerca esercizi"
          value={searching}
          onCancelSearch={resetSearch}
          onChange={onChange}
          onClick={onClickSearchButton}
          showCancelButton
        />
        <Card
          additionalStyle={pageMainCard}
        >
          <>
            <IconContainer
              onClick={() => navigate(`${ADD_EXERCISE_PT_PATH}/`)}
            >
              <AddIcon style={{ color: 'white' }} />
            </IconContainer>
            <SwipeableList
              fullSwipe
              type={ListType.IOS}
            >
              {exerciseToDisplay?.map((ex) => (
                <SwipeableListItem
                  key={ex._id}
                  trailingActions={trailingActions(ex)}
                >
                  <ListItem
                    avatarSource={ex?.muscleGroups ? ex?.muscleGroups[0]?.muscleGroup?.icon : ''}
                    key={ex._id}
                    onClick={() => navigate(`${EXERCISES_PT_PATH}/${ex._id}`)}
                    name={ex.name || 'Default'}
                  />
                </SwipeableListItem>
              ))}
            </SwipeableList>
            {exerciseToDisplay?.length === 0 && (
            <Text
              additionalStyle={{ margin: '2rem' }}
              type="body1"
            >
              Non ci sono esercizi registrati. Puoi aggiungerne uno cliccando sul tasto + qui sopra.
            </Text>
            )}
          </>
        </Card>

        <SlidingPopup
          open={openDeletePopup}
          handleClose={() => onDeleteExercise()}
          actions
          negativeAction
          negativeActionLabel="No"
          actionLabel="Si"
          handleNegativeAction={() => setOpenDeletePopup(false)}
        >
          <>
            <Text type="body1">
              Elimina esercizio
            </Text>
            <Text type="body2">
              Sei sicuro di voler eliminare questo esercizio? Questo esercizio verrà eliminato da tutti i piani in cui è utilizzato.
            </Text>
          </>

        </SlidingPopup>

        <ErrorPopup
          open={apiError !== ''}
          handleClose={() => setApiError('')}
          title="An error occurred :("
          caption={apiError}
          actions
        />
      </>
    </Layout>
  );
};

export default PTExercises;
