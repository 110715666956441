export const detailsBoxStyle = {
  width: '3rem',
  height: '3rem',
  position: 'absolute',
  left: '1.5rem',
  top: '1.5rem',
  borderRadius: '1rem',
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0rem',
};
