import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { ADD_MUSCLE_GROUP } from '../../../../apollo/mutations/addMuscleGroup';
import { AuthContext } from '../../../../context/auth';

type ControllerFN = () => {
  name: string,
  setName: Dispatch<SetStateAction<string>>,
  onSaveMuscleGroup: ()=>void
  setIcon: Dispatch<SetStateAction<string>>,
  icon: string
  formError: string
  setFormError: Dispatch<SetStateAction<string>>,
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PTAddMuscleGroupsController: ControllerFN = () => {
  const [name, setName] = useState<string>('');
  const [icon, setIcon] = useState<string>('no');
  const [formError, setFormError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<string>('');

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const [addMuscleGroup, { loading: addMuscleGroupLoading, error }] = useMutation(ADD_MUSCLE_GROUP);

  useEffect(() => {
    if (addMuscleGroupLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addMuscleGroupLoading]);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  const { MUSCLE_GROUPS_PT_PATH } = PATH_CONSTANTS;

  const onSaveMuscleGroup = () => {
    if (name !== '') {
      const muscleGroupInput = {
        name,
        icon,
        pt: id,
      };
      addMuscleGroup({
        variables: {
          muscleGroupInput,
        },
      }).then(() => {
        navigate(`${MUSCLE_GROUPS_PT_PATH}/`);
      });
    } else {
      setFormError('You must insert a name');
    }
  };

  return {
    name,
    setName,
    onSaveMuscleGroup,
    setIcon,
    icon,
    formError,
    setFormError,
    loading,
    setApiError,
    apiError,
  };
};

export default PTAddMuscleGroupsController;
