import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import HomeBar from '../../../components/HomeBar/HomeBar';
import HomePlanCard from '../../../components/HomePlanCard/HomePlanCard';
import PlansController from './controller/plans.controller';
import StatsController from './controller/stats.controller';
import Calendar from '../../../components/Calendar/Calendar';
import HomePageController from './controller/Home.controller';
import { ButtonContainerRow, shortcutStyle } from './Home.styles';
import ShortcutCard from '../../../components/ShortcutCard/ShortcutCard';
import { PATH_CONSTANTS } from '../../../helper/constants';
import { ReactComponent as PlansIcon } from '../../../asset/icons/plans.svg';
import { ReactComponent as ReportsIcon } from '../../../asset/icons/reports.svg';
import { iconStyle } from '../../../common/styles/svgIcons.style';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import CustomButton from '../../../components/CustomButton/CustomButton';
import HomePageTasks from './components/HomePageTasks/HomePageTasks';

interface OwnProps {}

type Props = OwnProps;

const Home: FunctionComponent<Props> = () => {
  const {
    activePlan, loading, apiPlansError, setApiPlansError,
  } = PlansController();
  const {
    macronutrients, loadingStats, apiStatsError, setApiStatsError,
  } = StatsController();
  const {
    selectedDay, onChangeDate,
    openUserPopup, setOpenUserPopup,
    logout,
  } = HomePageController();

  const navigate = useNavigate();
  const {
    PLANS_PATH, REPORTS_PATH,
  } = PATH_CONSTANTS;

  if (loading || loadingStats) return <LoaderSpinner />;

  return (
    <Layout>
      <>
        <HomeBar onClickOnAvatar={() => setOpenUserPopup(true)} />

        <HomePlanCard plan={activePlan} macronutrients={macronutrients} />

        <Calendar selectedDay={selectedDay} onClick={onChangeDate} />

        <ButtonContainerRow>
          <ShortcutCard
            label="Piani"
            icon={<PlansIcon style={iconStyle} />}
            onClick={() => navigate(`${PLANS_PATH}`)}
            additionalStyle={shortcutStyle}
          />
          <ShortcutCard
            label="Report"
            icon={<ReportsIcon style={iconStyle} />}
            onClick={() => navigate(`${REPORTS_PATH}`)}
            additionalStyle={shortcutStyle}
          />
        </ButtonContainerRow>

        <HomePageTasks />

        <SlidingPopup
          open={openUserPopup}
          handleClose={() => setOpenUserPopup(false)}
          additionalStyle={{ height: '8vh' }}
        >
          <CustomButton label="Logout" onClick={logout} />
        </SlidingPopup>

        <ErrorPopup
          open={apiStatsError !== '' || apiPlansError !== ''}
          handleClose={() => {
            setApiStatsError('');
            setApiPlansError('');
          }}
          title="An error occurred :("
          caption={apiStatsError !== '' ? apiStatsError : apiPlansError}
          actions
        />
      </>
    </Layout>
  );
};

export default Home;
