/* eslint-disable operator-linebreak,react/no-array-index-key */
import React, { useEffect, useReducer } from 'react';
import { CircleSlider } from 'react-circle-slider';
import TimerIcon from '@mui/icons-material/Timer';
import { GiFireRay, GiStripedSword } from 'react-icons/gi';
import { IoMdCodeWorking } from 'react-icons/io';
import { Box, Grid } from '@mui/material';
import Text from '../../../../../components/CustomTextFormatter/Text';
import ListItem from '../../../../../components/ListItem/ListItem';
import Card from '../../../../../components/Card/Card';
import { IExercise } from '../../../../../common/interfaces/Exercise';
import RepsChip from '../../../../../components/RepsChip/RepsChip';
import {
  addSetButton, addSetButtonLabel, cardStyle, IconContainer, WATextField,
} from './AddingExerciseDetails.style';
import CustomSlider from '../../../../../components/CustomSlider/CustomSlider';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import Divider from '../../../../../components/Divider/Divider';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import SlidingPopup from '../../../../../components/SlidingPopup/SlidingPopup';
import { IExerciseDetails } from '../../interfaces/exerciseDetails.form';
import { exerciseDetailsReducer } from './controller/reducer';

interface IAddingExerciseDetails {
  selectedExercise: IExercise | null
  onSaveExerciseInPlan: (exercise: IExercise, submittedExerciseDetails: IExerciseDetails)=>void
  onEditExerciseInPlan: (exercise: IExercise | null, submittedExerciseDetails: IExerciseDetails)=>void
  editExerciseInPlanMode: boolean
  openPopup: boolean
  handleClosePopup: ()=>void
  exerciseDetails: IExerciseDetails
}

export default function AddingExerciseDetails({
  selectedExercise,
  editExerciseInPlanMode,
  onEditExerciseInPlan,
  openPopup,
  handleClosePopup,
  exerciseDetails,
  onSaveExerciseInPlan,
}
  : IAddingExerciseDetails) {
  const setTypeList = [
    'Normal',
    'Stripping',
    'RestPause',
  ];

  const [{
    formFields, selectedExerciseSetRep, error,
  }, dispatch] =
      // @ts-ignore
      useReducer(
        exerciseDetailsReducer,
        { ...exerciseDetails, error: '' },
      );

  // WA Aggiorna lo stato quando si è in edit mode con i parametri impostati in precedenza
  useEffect(() => {
    if (exerciseDetails && Object.keys(exerciseDetails).length > 0 && editExerciseInPlanMode) {
      dispatch({
        type: 'setFormFields',
        payload: {
          ...exerciseDetails.formFields,
        },
      });

      dispatch({
        type: 'setSelectedExerciseSetRep',
        payload: [
          ...exerciseDetails.selectedExerciseSetRep,
        ],
      });
    }
  }, [exerciseDetails]);

  const onChangeHandler = (value: number | string, field: string) => {
    dispatch({
      type: 'setFormFields',
      payload: {
        ...formFields,
        [field]: {
          value,
          error: '',
        },
      },
    });
  };

  const addSetToSelectedExercise = () => {
    const obj = {
      set: formFields.typeSet.value === 'RestPause' ? formFields.restPauseSets.value : 1,
      type: formFields.typeSet.value,
      rep: formFields.rep.value,
    };
    dispatch({
      type: 'setSelectedExerciseSetRep',
      payload: [...selectedExerciseSetRep, obj],
    });
    dispatch({
      type: 'setError',
      payload: '',
    });
  };

  const onDeleteSelectedExerciseSetRep = (index: number) => {
    const newSelectedExerciseSetRep = [...selectedExerciseSetRep];
    newSelectedExerciseSetRep.splice(index, 1);
    dispatch({
      type: 'setSelectedExerciseSetRep',
      payload: newSelectedExerciseSetRep,
    });
  };

  const onSubmitExercise = () => {
    if (selectedExerciseSetRep.length > 0) {
      if (editExerciseInPlanMode && selectedExercise) {
        onEditExerciseInPlan(selectedExercise, { formFields, selectedExerciseSetRep });
      } else if (selectedExercise) {
        onSaveExerciseInPlan(selectedExercise, { formFields, selectedExerciseSetRep });
      }
      dispatch({
        type: 'setSelectedExerciseSetRep',
        payload: [],
      });
      dispatch({
        type: 'setFormFields',
        payload: {
          note: {
            value: '',
            error: '',
          },
          recovery: {
            value: 120,
            error: '',
          },
          typeSet: {
            value: 'Normal',
            error: '',
          },
          restPauseSets: {
            value: 1,
            error: '',
          },
          rep: {
            value: 1,
            error: '',
          },
        },
      });
    } else {
      dispatch({
        type: 'setError',
        payload: 'Devi aggiungere almeno una serie',
      });
    }
  };

  return (
    <SlidingPopup handleClose={handleClosePopup} open={openPopup}>
      <Card additionalStyle={cardStyle}>
        <>
          <ListItem
            avatarSource={selectedExercise?.muscleGroups ? selectedExercise.muscleGroups[0].muscleGroup?.icon : ''}
            name={selectedExercise?.name || 'Default'}
          />

          <RepsChip
            exerciseReps={selectedExerciseSetRep || []}
            handleDelete={onDeleteSelectedExerciseSetRep}
          />

          <Divider />

          <Text type="body1" additionalStyle={{ marginBottom: '0.5rem' }}>
            Aggiungi serie
          </Text>

          <Grid container spacing={3}>
            {setTypeList?.map((type, index) => (
              <Grid key={index} item xs={3}>
                <DetailsBox
                  key={index}
                  onClick={() => onChangeHandler(type, 'typeSet')}
                  label={type}
                  additionalStyle={{
                    background: formFields.typeSet.value === type ? 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(89,89,89,1) 100%)'
                      : 'rgba(255, 255, 255, 0.01)',
                  }}
                >
                  <>
                    {type === 'Stripping' && <GiStripedSword style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />}
                    {type === 'RestPause' && <GiFireRay style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />}
                    {type === 'Normal' && <IoMdCodeWorking style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />}
                  </>
                </DetailsBox>
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            {setTypeList?.map((type, index) => (
              <Grid key={index} item xs={3}>
                <DetailsBox
                  key={index}
                  onClick={() => onChangeHandler(type, 'typeSet')}
                  label={type}
                  additionalStyle={{
                    background: formFields.typeSet.value === type ? 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(89,89,89,1) 100%)'
                      : 'rgba(255, 255, 255, 0.01)',
                  }}
                >
                  <>
                    {type === 'Stripping' && <GiStripedSword style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />}
                    {type === 'RestPause' && <GiFireRay style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />}
                    {type === 'Normal' && <IoMdCodeWorking style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />}
                  </>
                </DetailsBox>
              </Grid>
            ))}
          </Grid>

          <Grid sx={{ marginTop: '1rem' }} container alignSelf="center" alignItems="center" justifyContent="space-between">
            <Grid item xs={9}>
              <CustomSlider
                displayValue={formFields.rep.value}
                min={1}
                max={50}
                step={1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, _id: number | string, value: number) => {
                  onChangeHandler(value, 'rep');
                }}
                label="Rip."
              />
            </Grid>

            <Grid item xs={3}>
              <DetailsBox
                onClick={addSetToSelectedExercise}
                additionalStyle={addSetButton}
              >
                <Text type="body2" additionalStyle={addSetButtonLabel}>
                  +
                </Text>
              </DetailsBox>
            </Grid>

            {error !== '' && (
            <Text type="body1">
              {error}
            </Text>
            )}
          </Grid>

          {formFields.typeSet.value === 'RestPause' && (
          <Box sx={{ alignSelf: 'center' }}>
            <CircleSlider
              progressColor="#F9F9F9"
              circleColor="rgba(255,255,255,0.2)"
              size={100}
              value={formFields.restPauseSets.value}
              progressWidth={10}
              circleWidth={10}
              tooltipSize={20}
              tooltipColor="#F9F9F9"
              showTooltip
              knobColor="#F9F9F9"
              min={1}
              max={5}
              shadow
              knobRadius={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, 'restPauseSets')}
            />
          </Box>
          )}

          <Divider />

          <Text type="body1">
            Infomazioni esercizio
          </Text>

          <WATextField
            sx={{ input: { color: '#fff!important' } }}
            InputLabelProps={{
              style: { color: '#fff!important' },
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            name="Note"
            label="Note"
            placeholder="Gomiti stretti"
            type="text"
            value={formFields.note.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, 'note')}

          />

          <Grid sx={{ marginTop: '1rem' }} container alignSelf="center" alignItems="center" justifyContent="space-between">
            <Grid item xs={9}>
              <CustomSlider
                displayValue={formFields.recovery.value}
                max={500}
                step={5}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, id: number | string, value: number) => {
                  onChangeHandler(value, 'recovery');
                }}
                label=""
              />
            </Grid>

            <Grid item xs={3}>
              <DetailsBox
                additionalStyle={addSetButton}
              >
                <TimerIcon />
              </DetailsBox>
            </Grid>
          </Grid>

          <Divider />

          <IconContainer>
            <CustomButton
              additionalStyle={{ width: '100%', textAlign: 'center' }}
              onClick={onSubmitExercise}
              label={editExerciseInPlanMode ? 'Edit' : 'Add'}
            />
          </IconContainer>

        </>
      </Card>
    </SlidingPopup>
  );
}
