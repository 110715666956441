/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, ListItem, ListItemText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RepsChip from '../../../../../../../components/RepsChip/RepsChip';
import { listItemStyle } from './DraggableExerciseList.style';
import { IExerciseInPlan } from '../../../../../../../common/interfaces/Exercise';

interface IDraggableExerciseList {
  exerciseList: any
  muscleGroup: string
  onSelectExerciseInSuperSet: (exId: string) => void
  onSelectExerciseInSelectMode: (exId: string) => void
  enableSupersetSelection: boolean
  reorderExerciseCallback: (list: any, muscleGroup: string) => void
  reorderMode: boolean
  onClickEditExerciseToPlan: (ex: IExerciseInPlan) => void
  enableSelectMode: boolean
}

export default function DraggableExerciseList({
  reorderExerciseCallback, muscleGroup, exerciseList,
  onSelectExerciseInSuperSet, enableSupersetSelection,
  reorderMode, onClickEditExerciseToPlan, enableSelectMode,
  onSelectExerciseInSelectMode,
} : IDraggableExerciseList) {
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? 'lightgreen' : 'rgba(255, 255, 255, 0.2)',
    margin: '0.5rem',
    ...draggableStyle,
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: '1.2rem',
  });

  const getListStyle = () => ({
    borderRadius: '1.2rem',
    padding: '0.5rem',
    width: '100%',
  });

  const onDragEnd = ((result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedExercise = reorder(
      exerciseList,
      result.source.index,
      result.destination.index,
    );

    reorderExerciseCallback(reorderedExercise, muscleGroup);
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
          >
            {exerciseList?.map((ex: any, index: number) => (
              <Draggable isDragDisabled={!reorderMode} key={ex.exercise._id} draggableId={ex.exercise._id} index={index}>
                {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <ListItem
                      style={listItemStyle}
                      onClick={() => {
                        if (!reorderMode && !enableSelectMode && !enableSupersetSelection) {
                          onClickEditExerciseToPlan(ex);
                        }
                      }}
                    >
                      <Box sx={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
                      }}
                      >
                        {enableSupersetSelection && !ex.color && <Checkbox onChange={() => onSelectExerciseInSuperSet(ex.exercise._id)} />}
                        {enableSelectMode && <Checkbox onChange={() => onSelectExerciseInSelectMode(ex.exercise._id)} />}
                        {ex.color && (
                          <Box style={{
                            width: '0.6rem',
                            height: '0.6rem',
                            borderRadius: '100%',
                            backgroundColor: ex.color,
                            position: 'absolute',
                            right: '0.5rem',
                          }}
                          />
                        )}
                        <Box sx={{ width: '100%' }}>
                          <ListItemText
                            primary={ex.exercise.name}
                            secondary={ex.note}
                          />
                          <RepsChip exerciseReps={ex.setRep} />
                        </Box>
                      </Box>
                    </ListItem>
                  </div>
                )}
              </Draggable>

            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
