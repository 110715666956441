import React, {
  Dispatch, FunctionComponent, SetStateAction, useState,
} from 'react';
import randomColor from 'randomcolor';
import {
  Box, Grid, IconButton, Slide,
} from '@mui/material';
import { TbArrowBigDownLine, TbArrowBigUpLine } from 'react-icons/tb';
import { FaSortAmountDownAlt, FaSuperpowers } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { VscListSelection } from 'react-icons/vsc';
import { Fade } from 'react-awesome-reveal';
import Divider from '../../../../../components/Divider/Divider';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { IconContainer, titleBarStyle } from '../../PTEditPlan.style';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import DraggableExerciseList from './components/DraggableExerciseList/DraggableExerciseList';
import SlidingPopup from '../../../../../components/SlidingPopup/SlidingPopup';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import { IExerciseInPlan } from '../../../../../common/interfaces/Exercise';

interface IButtonRenderingProps {
  visible: boolean
  selected: boolean
  disabled: boolean
}

interface IButtonRendering {
  reorder: IButtonRenderingProps
  delete: IButtonRenderingProps
  select: IButtonRenderingProps
  superset: IButtonRenderingProps
}

interface OwnProps {
  onAddSupersetToExercisesInPlan: (supersetExercisesList: any) => void
  open: boolean
  exercisesByDayByMuscleGroup: any
  setExerciseByDayByMuscleGroup: Dispatch<SetStateAction<any>>
  muscleGroupsOrder: string[]
  setMuscleGroupsOrder: Dispatch<SetStateAction<string[]>>
  onClickEditExerciseToPlan: (ex: IExerciseInPlan) => void
  onDeleteExerciseFromPlan: (exId: string) => void
}

type Props = OwnProps;

const PlanPreview: FunctionComponent<Props> = ({
  muscleGroupsOrder, setMuscleGroupsOrder, exercisesByDayByMuscleGroup,
  setExerciseByDayByMuscleGroup, onAddSupersetToExercisesInPlan, open,
  onClickEditExerciseToPlan, onDeleteExerciseFromPlan,
}) => {
  const [supersetDraft, setSupersetDraft] = useState<string[]>([]);
  const [supersetError, setSupersetError] = useState<boolean>(false);
  const [exercisesToDelete, setExercisesToDelete] = useState<string[]>([]);

  const [buttonRendering, setButtonRendering] = useState<IButtonRendering>({
    reorder: {
      selected: false,
      visible: true,
      disabled: false,
    },
    select: {
      selected: false,
      visible: true,
      disabled: false,
    },
    delete: {
      selected: false,
      visible: true,
      disabled: true,
    },
    superset: {
      selected: false,
      visible: true,
      disabled: false,
    },
  });

  const reorderExerciseCallback = (reorderedList: any, muscleGroup: string) => {
    const reordered = {
      ...exercisesByDayByMuscleGroup,
      [muscleGroup]: reorderedList,
    };

    setExerciseByDayByMuscleGroup(reordered);
  };

  const reorderMuscleGroups = (direction: string, muscleGroup: string) => {
    const exerciseByDayByMuscleGroupClone = { ...exercisesByDayByMuscleGroup };
    const currentIndex = muscleGroupsOrder.findIndex((mgo) => mgo === muscleGroup);
    const muscleGroupsOrderClone = [...muscleGroupsOrder];
    const temp = muscleGroupsOrderClone[currentIndex];

    if (direction === 'up') {
      muscleGroupsOrderClone[currentIndex] = muscleGroupsOrderClone[currentIndex - 1];
      muscleGroupsOrderClone[currentIndex - 1] = temp;
    } else {
      muscleGroupsOrderClone[currentIndex] = muscleGroupsOrderClone[currentIndex + 1];
      muscleGroupsOrderClone[currentIndex + 1] = temp;
    }

    setMuscleGroupsOrder(muscleGroupsOrderClone);

    const reorderedExerciseByDayByMuscleGroup: any = {};
    muscleGroupsOrderClone.forEach((mg) => {
      reorderedExerciseByDayByMuscleGroup[mg] = [...exerciseByDayByMuscleGroupClone[mg]];
    });

    setExerciseByDayByMuscleGroup(reorderedExerciseByDayByMuscleGroup);
  };

  const onClickConfirmSupersetSuperSet = () => {
    if (supersetDraft.length >= 2) {
      const obj = {
        exercises: supersetDraft,
        randomColor: randomColor({
          luminosity: 'dark',
          hue: 'random',
        }),
      };
      onAddSupersetToExercisesInPlan(obj);
      setSupersetDraft([]);
      setButtonRendering({
        reorder: {
          selected: false,
          visible: true,
          disabled: false,
        },
        select: {
          selected: false,
          visible: true,
          disabled: false,
        },
        delete: {
          selected: false,
          visible: true,
          disabled: true,
        },
        superset: {
          selected: false,
          visible: true,
          disabled: false,
        },
      });
    } else {
      setSupersetError(true);
    }
  };

  const onSelectExerciseInSuperSet = (exId: string) => {
    setSupersetDraft((prev: any) => [...prev, exId]);
  };

  const onSelectExerciseInSelect = (exId: string) => {
    setExercisesToDelete((prev) => [...prev, exId]);
  };

  const onClickDeleteButton = () => {
    exercisesToDelete.forEach((etd) => {
      onDeleteExerciseFromPlan(etd);
    });
    setExercisesToDelete([]);
    setButtonRendering({
      reorder: {
        selected: false,
        visible: true,
        disabled: false,
      },
      select: {
        selected: false,
        visible: true,
        disabled: false,
      },
      delete: {
        selected: false,
        visible: true,
        disabled: true,
      },
      superset: {
        selected: false,
        visible: true,
        disabled: false,
      },
    });
  };

  const onClickButton = (buttonLabel: string) => {
    switch (buttonLabel) {
      case 'select':
        setButtonRendering((prev) => ({
          select: {
            selected: !prev.select.selected,
            visible: true,
            disabled: false,
          },
          reorder: {
            selected: false,
            disabled: !prev.select.selected,
            visible: true,
          },
          delete: {
            selected: false,
            disabled: false,
            visible: true,
          },
          superset: {
            selected: false,
            disabled: !prev.select.selected,
            visible: true,
          },
        }));
        break;
      case 'reorder':
        setButtonRendering((prev) => ({
          select: {
            selected: false,
            visible: true,
            disabled: prev.reorder.selected,
          },
          reorder: {
            selected: !prev.reorder.selected,
            disabled: false,
            visible: true,
          },
          delete: {
            selected: false,
            disabled: !prev.reorder.selected,
            visible: true,
          },
          superset: {
            selected: false,
            disabled: !prev.reorder.selected,
            visible: true,
          },
        }));
        break;
      case 'superset':
        setButtonRendering((prev) => ({
          select: {
            selected: false,
            disabled: !prev.superset.selected,
            visible: false,
          },
          reorder: {
            selected: false,
            disabled: !prev.superset.selected,
            visible: false,
          },
          delete: {
            selected: false,
            disabled: !prev.superset.selected,
            visible: false,
          },
          superset: {
            selected: !prev.superset.selected,
            disabled: false,
            visible: false,
          },
        }));
        break;
      case 'delete':
        onClickDeleteButton();
        break;
      default: break;
    }
  };

  return (
    <>
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: '2rem',
        }}
        >

          <Box sx={{ height: '100%', overflow: 'auto', marginBottom: '2rem' }}>

            <IconContainer>
              <Fade delay={100} cascade>
                {!buttonRendering.superset.selected && (
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <DetailsBox
                      additionalStyle={{ pointerEvents: buttonRendering.select.disabled ? 'none' : 'auto' }}
                      label="Seleziona"
                      onClick={() => onClickButton('select')}
                    >
                      <IconButton>
                        <VscListSelection />
                      </IconButton>
                    </DetailsBox>
                  </Grid>
                  <Grid item xs={3}>
                    <DetailsBox
                      containerAdditionalStyle={{ pointerEvents: buttonRendering.superset.disabled ? 'none' : 'auto' }}
                      label="Superserie"
                      onClick={() => onClickButton('superset')}
                    >
                      <IconButton disabled={buttonRendering.superset.disabled}>
                        <FaSuperpowers />
                      </IconButton>
                    </DetailsBox>
                  </Grid>
                  <Grid item xs={3}>
                    <DetailsBox
                      containerAdditionalStyle={{ pointerEvents: buttonRendering.reorder.disabled ? 'none' : 'auto' }}
                      label="Ordina"
                      onClick={() => onClickButton('reorder')}
                    >
                      <IconButton disabled={buttonRendering.reorder.disabled}>
                        <FaSortAmountDownAlt />
                      </IconButton>
                    </DetailsBox>
                  </Grid>
                  <Grid item xs={3}>
                    <DetailsBox
                      containerAdditionalStyle={{ pointerEvents: buttonRendering.delete.disabled ? 'none' : 'auto' }}
                      label="Elimina"
                      onClick={() => onClickButton('delete')}
                    >
                      <IconButton disabled={buttonRendering.delete.disabled}>
                        <RiDeleteBinLine />
                      </IconButton>
                    </DetailsBox>
                  </Grid>
                </Grid>
                )}

                {buttonRendering.superset.selected && (
                <>
                  <CustomButton
                    additionalStyle={{ width: '50%', textAlign: 'center' }}
                    onClick={() => {
                      setButtonRendering((prev) => ({
                        select: {
                          selected: false,
                          disabled: false,
                          visible: true,
                        },
                        reorder: {
                          selected: false,
                          disabled: false,
                          visible: true,
                        },
                        delete: {
                          selected: false,
                          disabled: true,
                          visible: true,
                        },
                        superset: {
                          selected: false,
                          disabled: false,
                          visible: true,
                        },
                      }));
                      setSupersetDraft([]);
                    }}
                    label="Cancel Superset"
                  />
                  <CustomButton
                    additionalStyle={{ width: '50%', textAlign: 'center' }}
                    onClick={onClickConfirmSupersetSuperSet}
                    label="Confirm Superset"
                  />
                </>
                )}
              </Fade>
            </IconContainer>

            <Divider />

            {exercisesByDayByMuscleGroup && Object.keys(exercisesByDayByMuscleGroup)?.map((mg: string) => (

              <Box key={mg} sx={{ width: '100%' }}>
                <TitleBar additionalStyle={titleBarStyle}>
                  <Text type="h1">
                    {// @ts-ignore
                            mg || 'Undefined'
                        }
                  </Text>
                </TitleBar>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {buttonRendering.reorder.selected && (
                  <Box>
                    <IconButton
                      disabled={muscleGroupsOrder.findIndex((mgo) => mgo === mg) === 0}
                      onClick={() => reorderMuscleGroups('up', mg)}
                    >
                      <TbArrowBigUpLine
                        color={muscleGroupsOrder.findIndex((mgo) => mgo === mg) !== 0
                          ? '#f9f9f9' : 'rgba(255, 255, 255, 0.2)'}
                        size="1.5rem"
                      />
                    </IconButton>
                  </Box>
                  )}

                  <DraggableExerciseList
                    exerciseList={exercisesByDayByMuscleGroup[mg]}
                    onSelectExerciseInSuperSet={onSelectExerciseInSuperSet}
                    onSelectExerciseInSelectMode={onSelectExerciseInSelect}
                    enableSelectMode={buttonRendering.select.selected}
                    enableSupersetSelection={buttonRendering.superset.selected}
                    reorderExerciseCallback={reorderExerciseCallback}
                    muscleGroup={mg}
                    reorderMode={buttonRendering.reorder.selected}
                    onClickEditExerciseToPlan={onClickEditExerciseToPlan}
                  />

                  {buttonRendering.reorder.selected && (
                  <Box>
                    <IconButton
                      disabled={muscleGroupsOrder.findIndex((mgo) => mgo === mg) === muscleGroupsOrder.length - 1}
                      onClick={() => reorderMuscleGroups('down', mg)}
                    >
                      <TbArrowBigDownLine
                        color={muscleGroupsOrder.findIndex((mgo) => mgo === mg) !== muscleGroupsOrder.length - 1
                          ? '#f9f9f9' : 'rgba(255, 255, 255, 0.2)'}
                        size="1.5rem"
                      />
                    </IconButton>
                  </Box>
                  )}

                </Box>
              </Box>
            ))}

          </Box>
        </Box>
      </Slide>

      <SlidingPopup
        open={supersetError}
        handleClose={() => setSupersetError(false)}
        actions
        additionalStyle={
              {
                width: '60%',
                position: 'relative',
                background: '#1B1833',
                padding: '1.5rem 1.8rem 1.5rem 1.8rem',
                borderRadius: '1rem 1rem 1rem 1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 0,
                gap: '2rem',
              }
            }
      >
        <>
          <Text type="h2">Wait!</Text>
          <Text type="body1">You must select at least two exercises to create a superset</Text>
        </>
      </SlidingPopup>

    </>
  );
};

export default PlanPreview;
