/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Box, List, ListItem, ListItemText,
} from '@mui/material';
import { CircleSlider } from 'react-circle-slider';
import RepsChip from '../../../../../../../components/RepsChip/RepsChip';
import { listItemStyle, listStyle } from './ExerciseList.style';
import { IExerciseInPlan } from '../../../../../../../common/interfaces/Exercise';

export interface ICirceSliderValues {
  exercise: string
  muscleGroup: {
    _id: string,
    name: string
  }
  value: number
}

interface IExerciseList {
  exerciseList: IExerciseInPlan[]
  onSelectTrainingSets: (values: ICirceSliderValues[]) => void
}

export default function ExerciseList({
  exerciseList,
  onSelectTrainingSets,
} : IExerciseList) {
  const [circleSliderValues, setCircleSliderValues] = useState<ICirceSliderValues[]>([]);

  // useLayoutEffect(() => () => {
  //   if (exerciseList.length > 0) {
  //     const initialCircleValues = exerciseList.map((ex: any) => ({
  //       exercise: ex.exercise._id || '',
  //       muscleGroup: {
  //         _id: ex.exercise?.muscleGroups ? ex.exercise.muscleGroups[0].muscleGroup._id : '',
  //         name: ex.exercise?.muscleGroups ? ex.exercise.muscleGroups[0].muscleGroup.name : '',
  //       },
  //       value: 0,
  //     }));
  //     setCircleSliderValues(initialCircleValues);
  //   }
  // }, [exerciseList]);

  const onChangeTrainingSetsSlider = (exerciseId: string, trainingSetValue: number) => {
    const circleSliderValuesClone = [...circleSliderValues];
    const newTrainingSet = {
      exercise: exerciseId,
      muscleGroup: {
        // @ts-ignore
        _id: exerciseList.filter((ex) => ex.exercise._id === exerciseId)[0].exercise.muscleGroups[0].muscleGroup._id || '',
        // @ts-ignore
        name: exerciseList.filter((ex) => ex.exercise._id === exerciseId)[0].exercise.muscleGroups[0].muscleGroup.name || '',
      },
      value: trainingSetValue,
    };

    const newCircleSliderValue = circleSliderValuesClone.filter((ex) => ex.exercise !== exerciseId);
    newCircleSliderValue.push(newTrainingSet);
    setCircleSliderValues(newCircleSliderValue);
    // const newCircleSliderValue = circleSliderValuesClone.map((csd) => {
    //   if (csd.exercise === exerciseId) {
    //     return {
    //       ...csd,
    //       value: trainingSetValue,
    //     };
    //   }
    //   return csd;
    // });

    // if is to prevent too many call WA
    if (JSON.stringify(newCircleSliderValue) !== JSON.stringify(circleSliderValues)) {
      onSelectTrainingSets(newCircleSliderValue);
    }
  };

  return (
    <List sx={listStyle}>
      {exerciseList?.map((ex: IExerciseInPlan) => (
        <ListItem style={listItemStyle}>
          <Box sx={{
            display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%',
          }}
          >

            <Box sx={{ width: '100%' }}>
              <ListItemText
                primary={ex.exercise.name}
                secondary={ex.note}
              />
              <RepsChip exerciseReps={ex.setRep} />
            </Box>
            <CircleSlider
              progressColor="#F9F9F9"
              circleColor="rgba(255,255,255,0.2)"
              size={100}
              value={circleSliderValues?.find((csd: ICirceSliderValues) => csd.exercise === ex.exercise?._id)?.value || 0}
              progressWidth={10}
              circleWidth={10}
              tooltipSize={20}
              tooltipColor="#F9F9F9"
              showTooltip
              knobColor="#F9F9F9"
              min={0}
              max={ex.setRep.length}
              shadow
              knobRadius={10}
              onChange={(value: number) => onChangeTrainingSetsSlider(ex.exercise._id || '', value)}
            />
          </Box>
        </ListItem>
      ))}
    </List>
  );
}
