import { ApolloError, gql, useQuery } from '@apollo/client';
import { IMuscleGroupPercentageInPlanStatsResponse } from '../../common/interfaces/Stats';

const GET_MUSCLE_GROUP_PERCENTAGE_IN_PLANS = gql`
    query muscleGroupPercentageInPlansStats($idUser: ID!, $period: Int!){
        muscleGroupPercentageInPlansStats(idUser: $idUser, period: $period) {
            muscleGroup
            percentage
            icon
        }
    }
`;

type ControllerFN = (idUser: string, period: number) => {
    muscleGroupPercentageInPlansStats: IMuscleGroupPercentageInPlanStatsResponse[] | any;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetMuscleGroupPercentageInPlans: ControllerFN = (idUser: string, period: number) => {
  const { data, loading, error } = useQuery(GET_MUSCLE_GROUP_PERCENTAGE_IN_PLANS, {
    variables: { idUser, period },
  });
  const muscleGroupPercentageInPlansStats = data?.muscleGroupPercentageInPlansStats;
  return { muscleGroupPercentageInPlansStats, loading, error };
};
