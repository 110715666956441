export const cardStyle = {
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
  width: '100%',
  borderRadius: '1rem!important',
  flexGrow: 0.8,
};

export const textStyle = {
  color: '#F9F9F9',
};

export const taskStyle = {
  color: '#F9F9F9',
  margin: '1rem 0 1rem 0',
  fontStyle: 'italic',
};
