import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useGetLoggedUserMacronutrients } from '../../../../apollo/hooks/useGetLoggedUserMacronutrients';
import { IMacronutrients } from '../../../../common/interfaces/User';

type ControllerFN = () => {
  macronutrients: IMacronutrients | undefined
  loadingStats: boolean
  apiStatsError: string
  setApiStatsError: Dispatch<SetStateAction<string>>
};

const StatsController: ControllerFN = () => {
  const { macronutrients, loading: macronutrientsLoading, error } = useGetLoggedUserMacronutrients();

  const [loadingStats, setLoading] = useState<boolean>(true);
  const [apiStatsError, setApiStatsError] = useState<string>('');

  useEffect(() => {
    if (error) {
      setApiStatsError(error.message);
    } else {
      setApiStatsError('');
    }
  }, [error]);

  useEffect(() => {
    const calculateLoadingValue = macronutrientsLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [macronutrientsLoading]);

  return {
    macronutrients, loadingStats, apiStatsError, setApiStatsError,
  };
};

export default StatsController;
