import React, { FunctionComponent } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useNavigate } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { GiCometSpark } from 'react-icons/gi';
import Layout from '../../../components/Layout/Layout';
import TrainingController from './controller/training.controller';
import Pagebar from '../../../components/Pagebar/Pagebar';
import HomePlanCard from '../../../components/HomePlanCard/HomePlanCard';
import Text from '../../../components/CustomTextFormatter/Text';
import DetailsBox from '../../../components/DetailsBox/DetailsBox';
import { PATH_CONSTANTS } from '../../../helper/constants';
import { DetailsBoxContainer, Scroller, Wrapper } from './Training.style';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';

interface OwnProps {}

type Props = OwnProps;

const Training: FunctionComponent<Props> = () => {
  const {
    activePlan, macronutrients,
    loading, sessions, lastTrainingStats, openNotePopup, setOpenNotePopup,
    apiError, setApiError,
  } = TrainingController();
  const navigate = useNavigate();
  const { TRAINING_PATH } = PATH_CONSTANTS;

  const getRatingIcon = (ratingNumber: number) => {
    switch (ratingNumber) {
      case 1:
        return <SentimentVeryDissatisfiedIcon sx={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />;
      case 2:
        return <SentimentSatisfiedIcon sx={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />;
      case 3:
        return <SentimentVerySatisfiedIcon sx={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />;
      default:
        return <Text type="body2">N/D</Text>;
    }
  };

  if (loading) return <LoaderSpinner />;

  return (
    <Layout>
      <>
        {!loading && (
          <>
            <Pagebar indexPage title="Allenamento" />
            <Text additionalStyle={{ alignSelf: 'flex-start' }} type="h2">
              Piano
            </Text>
            <HomePlanCard plan={activePlan} macronutrients={macronutrients} />
            <Wrapper>
              <Text additionalStyle={{ alignSelf: 'flex-start' }} type="h2">
                Workout
              </Text>
              <Scroller>
                {sessions?.map((session) => (
                  <DetailsBox key={session} onClick={() => navigate(`${TRAINING_PATH}/${session}`)} label={`Workout ${session}`}>
                    <GiCometSpark style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />
                  </DetailsBox>
                ))}
              </Scroller>

              <Text additionalStyle={{ alignSelf: 'flex-start' }} type="h2">
                Ultimo allenamento
              </Text>
              <DetailsBoxContainer>
                <DetailsBox label={lastTrainingStats.date}>
                  <CalendarTodayIcon sx={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />
                </DetailsBox>
                <DetailsBox onClick={() => setOpenNotePopup(true)} label="Note">
                  <EventNoteIcon sx={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />
                </DetailsBox>
                <DetailsBox label="Rating">
                  {getRatingIcon(lastTrainingStats.rating)}
                </DetailsBox>
              </DetailsBoxContainer>

            </Wrapper>
          </>
        )}

        <SlidingPopup
          open={openNotePopup}
          handleClose={() => setOpenNotePopup(false)}
          additionalStyle={{ height: '20vh' }}
        >
          <Text type="body1">
            {lastTrainingStats?.note}
          </Text>
        </SlidingPopup>
        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default Training;
