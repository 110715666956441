import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const SelectionBox = styled(Box)`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0 1rem 0;
`;

export const IconContainer = styled(Box)`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
`;

export const planInfoCardStyle = {
  background: '#323741',
  width: '100%',
  borderRadius: '1rem!important',
  flexGrow: 1,
};

export const planInfoCardContentStyle = {
  boxSizing: 'border-box', display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto',
};

export const exerciseSelectionCardContainer = {
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
  width: '100%',
  borderRadius: '0.5rem!important',
  flexGrow: 1,
  overflowX: 'hidden',
};

export const titleBarStyle = { margin: 0 };

export const exerciseSelectionCardContentContainer = {
  display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto',
};
