import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Scroller = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0.5rem 0;
  overflow: auto;
  gap: 0.8rem;
`;

export const DayTextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const iconButtonStyle = {
  backgroundColor: 'rgba(255,255,255,0.2)',
  height: '55px',
  width: '40px',
  color: ' white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1rem',
};
