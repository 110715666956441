import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const BackButtonContainer = styled(Box)`
  height: 2.75rem;
  width: 2.75rem; 
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.01rem solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  `;

export const iconStyle = {
  width: '1.5rem',
  height: '1.5rem',
  color: '#F9F9F9',
};
