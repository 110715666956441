import React from 'react';
import { Box } from '@mui/material';
import Text from '../../../CustomTextFormatter/Text';

interface Props {
    weekday: string,
    number: string,
    badge: boolean,
    selected: boolean,
    // eslint-disable-next-line no-unused-vars
    onClick?: (number: string) => void
}

export default function Day({
  weekday, number, badge, onClick, selected,
}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        background: selected ? 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(89,89,89,1) 100%)' : 'rgba(255, 255, 255, 0.01)',
        border: '0.01rem solid rgba(255, 255, 255, 0.1)',
        flexDirection: 'column',
        borderRadius: '1.125rem',
        width: '2.375rem',
        height: '4.375rem',
        position: 'relative',
      }}
      onClick={onClick ? () => onClick(number) : () => { console.log('tbh'); }}
    >
      {badge && (
      <Box sx={{
        width: '0.5rem', height: '0.5rem', backgroundColor: 'white', position: 'absolute', top: '0.08rem', right: '0.08rem', borderRadius: '100%',
      }}
      />
      )}
      <Text type="body1" additionalStyle={{ color: 'white' }}>
        {weekday}
      </Text>
      <Text type="body2" additionalStyle={{ color: 'white' }}>
        {number}
      </Text>
    </Box>
  );
}
