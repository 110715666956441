import {
  ChangeEvent, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { AuthContext } from '../../../../context/auth';
import { IAthleteState } from '../interfaces/athlete.form';
import { useGetUser } from '../../../../apollo/hooks/useGetUser';
import { UPDATE_USER } from '../../../../apollo/mutations/updateUser';
import {
  IMaximal, IMeasuresInput, IUserInput, IWeightInput,
} from '../../../../common/interfaces/User';

const EditAthleteController = (athleteId: string | undefined) => {
  const { ATHLETES_PT_PATH } = PATH_CONSTANTS;

  const [kcals, setKcals] = useState<number>(0);
  const [proteins, setProteins] = useState<number>(0);
  const [fats, setFats] = useState<number>(0);
  const [carbs, setCarbs] = useState<number>(0);
  const [measures, setMeasures] = useState<IMeasuresInput>({
    date: dayjs(),
    bmi: 0,
    bust: 0,
    waist: 0,
    hips: 0,
    thigh: 0,
  });
  const [weight, setWeight] = useState<IWeightInput>({
    date: dayjs(),
    weight: 0,
  });
  const [maximal, setMaximal] = useState<IMaximal>({
    benchPress: 0,
    deadLift: 0,
    squat: 0,
    militaryPress: 0,
  });
  const [apiError, setApiError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { user: userById, loading: loadingUserById, error: errorUserById } = useGetUser(athleteId);

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const [updateUser, { error, loading: updateUserLoading }] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else if (errorUserById) {
      setApiError(errorUserById.message);
    } else {
      setApiError('');
    }
  }, [error, errorUserById]);

  useEffect(() => {
    if (updateUserLoading || loadingUserById) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateUserLoading, loadingUserById]);

  useEffect(() => {
    if (userById) {
      if (userById.measures && userById.measures.length > 0) {
        const previousMeasures = [...userById.measures];
        setMeasures({
          ...measures,
          bmi: previousMeasures[previousMeasures.length - 1].bmi,
          bust: previousMeasures[previousMeasures.length - 1].bust,
          waist: previousMeasures[previousMeasures.length - 1].waist,
          hips: previousMeasures[previousMeasures.length - 1].hips,
          thigh: previousMeasures[previousMeasures.length - 1].thigh,
        });
      }
      if (userById.weight && userById.weight.length > 0) {
        const previousWeights = [...userById.weight];
        setWeight({
          ...weight,
          weight: previousWeights[previousWeights.length - 1].weight,
        });
      }
      if (userById.maximal) {
        setMaximal({
          benchPress: userById.maximal.benchPress,
          deadLift: userById.maximal.deadLift,
          squat: userById.maximal.squat,
          militaryPress: userById.maximal.militaryPress,
        });
      }
    }
  }, [userById]);

  // Athlete Info
  const [athleteInfo, setAthleteInfo] = useState<IAthleteState>({
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    name: {
      value: '',
      error: '',
    },
    age: {
      value: '',
      error: '',
    },
    startDate: {
      value: dayjs(),
      error: '',
    },
  });

  const macroAccordionSliders = [
    {
      defaultValue: 0,
      displayValue: kcals,
      max: 5000,
      step: 50,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => { setKcals(value); },
      label: 'Kcals',
    },
    {
      defaultValue: 0,
      displayValue: carbs,
      max: 500,
      step: 10,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => { setCarbs(value); },
      label: 'Carbs',
    },
    {
      defaultValue: 0,
      displayValue: proteins,
      max: 300,
      step: 10,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => { setProteins(value); },
      label: 'Prots',
    },
    {
      defaultValue: 0,
      displayValue: fats,
      max: 300,
      step: 10,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => { setFats(value); },
      label: 'Fats',
    },
  ];

  const onChangeMeasuresSliders = (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => {
    setMeasures({
      ...measures,
      [idS]: value,
    });
  };

  const measuresAccordionSliders = [
    {
      defaultValue: 0,
      displayValue: measures.bmi,
      max: 60,
      step: 0.1,
      onChange: onChangeMeasuresSliders,
      label: 'BMI',
      id: 'bmi',
    },
    {
      defaultValue: 0,
      displayValue: measures.bust,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Torace',
      id: 'bust',
    },
    {
      defaultValue: 0,
      displayValue: measures.waist,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Vita',
      id: 'waist',
    },
    {
      defaultValue: 0,
      displayValue: measures.hips,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Fianchi',
      id: 'hips',
    },
    {
      defaultValue: 0,
      displayValue: measures.thigh,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Coscia',
      id: 'thigh',
    },
  ];

  const weightAccordionSliders = [
    {
      defaultValue: 0,
      displayValue: weight.weight,
      max: 60,
      step: 0.1,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => setWeight({ ...weight, weight: value }),
      label: 'kg',
      id: 'weight',
    },
  ];

  const maximalAccordionSliders = [
    {
      defaultValue: 0,
      displayValue: maximal.benchPress,
      max: 300,
      step: 0.5,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => setMaximal({ ...maximal, benchPress: value }),
      label: 'Bench Press',
      id: 'benchPress',
    },
    {
      defaultValue: 0,
      displayValue: maximal.deadLift,
      max: 300,
      step: 0.5,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => setMaximal({ ...maximal, deadLift: value }),
      label: 'Dead Lift',
      id: 'deadLift',
    },
    {
      defaultValue: 0,
      displayValue: maximal.squat,
      max: 300,
      step: 0.5,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => setMaximal({ ...maximal, squat: value }),
      label: 'Squat',
      id: 'squat',
    },
    {
      defaultValue: 0,
      displayValue: maximal.militaryPress,
      max: 300,
      step: 0.5,
      onChange: (e: ChangeEvent<HTMLInputElement>, idS: number|string, value: number) => setMaximal({ ...maximal, militaryPress: value }),
      label: 'Military Press',
      id: 'militaryPress',
    },
  ];

  const navigate = useNavigate();

  const onSubmitAthlete = (athleteInfoSubmit: IAthleteState) => {
    setAthleteInfo(athleteInfoSubmit);
    let userInput: IUserInput = {
      email: athleteInfoSubmit.email.value,
      password: athleteInfoSubmit.password.value,
      name: athleteInfoSubmit.name.value,
      age: athleteInfoSubmit.age.value,
      startDate: athleteInfoSubmit.startDate.value || dayjs(),
      role: 'ATHLETE',
      pt: [id],
      macronutrients: {
        kcals,
        proteins,
        carbs,
        fats,
      },
      measures: [],
      weight: [],
    };
    // Add measure is it is different from the previous one
    if (userById.measures && userById.measures.length > 0) {
      const previousMeasures = [...userById.measures];
      const previousMeasure = {
        bmi: previousMeasures[previousMeasures.length - 1].bmi,
        bust: previousMeasures[previousMeasures.length - 1].bust,
        waist: previousMeasures[previousMeasures.length - 1].waist,
        hips: previousMeasures[previousMeasures.length - 1].hips,
        thigh: previousMeasures[previousMeasures.length - 1].thigh,
      };
      const currentMeasure = {
        bmi: measures.bmi,
        bust: measures.bust,
        waist: measures.waist,
        hips: measures.hips,
        thigh: measures.thigh,
      };
      if (JSON.stringify(previousMeasure) !== JSON.stringify(currentMeasure)) {
        userInput = {
          ...userInput,
          measures: [...userById.measures.map((oldMeasure) => ({
            ...oldMeasure,
            date: dayjs(oldMeasure.date),
          })), measures],
        };
      } else {
        userInput = {
          ...userInput,
          measures: [...userById.measures.map((oldMeasure) => ({
            ...oldMeasure,
            date: dayjs(oldMeasure.date),
          }))],
        };
        console.log(userInput, 'userInput');
      }
    }
    // Add weight if it is different from the previous one
    if (userById.weight && userById.weight.length > 0) {
      const previousWeights = [...userById.weight];
      const previousWeight = {
        weight: previousWeights[previousWeights.length - 1].weight,
      };
      const currentWeight = {
        weight: weight.weight,
      };
      if (JSON.stringify(previousWeight) !== JSON.stringify(currentWeight)) {
        userInput = {
          ...userInput,
          weight: [...userById.weight.map((oldWeight) => ({
            ...oldWeight,
            date: dayjs(oldWeight.date),
          })), weight],
        };
      } else {
        userInput = {
          ...userInput,
          weight: [...userById.weight.map((oldWeight) => ({
            ...oldWeight,
            date: dayjs(oldWeight.date),
          }))],
        };
      }
    }
    // Add maximal if it is different from the previous one
    if (userById.weight && userById.weight.length > 0) {
      const previousMaximals = { ...userById.maximal };
      if (JSON.stringify(previousMaximals) !== JSON.stringify(maximal)) {
        userInput = {
          ...userInput,
          maximal,
        };
      } else {
        userInput = {
          ...userInput,
          maximal: {
            benchPress: previousMaximals.benchPress || 0,
            deadLift: previousMaximals.deadLift || 0,
            squat: previousMaximals.squat || 0,
            militaryPress: previousMaximals.militaryPress || 0,
          },
        };
      }
    }

    updateUser({
      variables: {
        id: athleteId,
        userInput,
      },
    }).then(() => {
      navigate(ATHLETES_PT_PATH);
    });
  };

  return {
    athleteInfo,
    measuresAccordionSliders,
    macroAccordionSliders,
    weightAccordionSliders,
    measures,
    weight,
    setFats,
    setCarbs,
    setProteins,
    setKcals,
    apiError,
    setApiError,
    loading,
    onSubmitAthlete,
    kcals,
    proteins,
    fats,
    carbs,
    userById,
    maximalAccordionSliders,
  };
};

export default EditAthleteController;
