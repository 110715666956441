import { styled } from '@mui/system';
import { Box, Chip } from '@mui/material';

export const RepsBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  `;

export const StyledRepsChip = styled(Chip)`
  color: #F9F9F9!important;
  font-weight: 600!important;
  width: fit-content!important;
  background: rgba(255, 255, 255, 0.1)!important;
  border-radius: 1rem!important;
  `;
