import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_TRAINING_PHASES_BY_PT_ID } from '../../../../apollo/hooks/useGetTrainingPhasesByPtId';
import { IPhases, isPhases, ITrainingPhase } from '../../../../common/interfaces/TrainingPhases';
import { AuthContext } from '../../../../context/auth';
import { UPDATE_TRAINING_PHASE } from '../../../../apollo/mutations/updateTrainingPhase';
import { DELETE_TRAINING_PHASE } from '../../../../apollo/mutations/deleteTrainingPhase';
import { UPDATE_PLAN } from '../../../../apollo/mutations/updatePlan';
import { useGetPlansByPt } from '../../../../apollo/hooks/useGetPlansByPt';
import { createPlanInputObjectFromPlanResponse } from '../../../../apollo/helper/utils';

type ControllerFN = () => {
    searching: string;
    onChange: (e: any) => void
    onClickSearchButton: () => void
    resetSearch: () => void
    trainingPhaseToDisplay: IPhases[]| []
    loading: boolean
    apiError: string
    setApiError: Dispatch<SetStateAction<string>>
    setOpenDeletePopup: Dispatch<SetStateAction<boolean>>
    setTrainingPhaseToBeHandled: Dispatch<SetStateAction<IPhases| Record<string, unknown>>>
    openDeletePopup: boolean
    onDeleteTrainingPhase: () => void
};

const PTPhasesController: ControllerFN = () => {
  const [searching, setSearching] = useState<string>('');
  const [trainingPhaseToDisplay, setTrainingPhaseToDisplay] = useState<IPhases[]| []>([]);
  const [trainingPhases, setTrainingPhases] = useState<ITrainingPhase | null>(null);
  const [apiError, setApiError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [trainingPhaseToBeHandled, setTrainingPhaseToBeHandled] = useState < IPhases | Record<string, unknown> >({});

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const { plansByPt, loading: plansByPtLoading, error: plansByPtError } = useGetPlansByPt(id);
  const [getTrainingPhases, { loading: trainingPhasesLoading, error }] = useLazyQuery(GET_TRAINING_PHASES_BY_PT_ID, {
    variables: {
      ptId: id,
    },
  });
  const [updateTrainingPhase, { loading: updateTrainingPhaseLoading, error: updateTrainingPhaseError }] = useMutation(UPDATE_TRAINING_PHASE);
  const [deleteTrainingPhase, { loading: deleteTrainingPhaseLoading, error: deleteTrainingPhaseError }] = useMutation(DELETE_TRAINING_PHASE);
  const [updatePlan, { loading: updatePlanLoading, error: updatePlanError }] = useMutation(UPDATE_PLAN);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else if (updateTrainingPhaseError) {
      setApiError(updateTrainingPhaseError.message);
    } else if (deleteTrainingPhaseError) {
      setApiError(deleteTrainingPhaseError.message);
    } else if (updatePlanError) {
      setApiError(updatePlanError.message);
    } else if (plansByPtError) {
      setApiError(plansByPtError.message);
    } else {
      setApiError('');
    }
  }, [error, updateTrainingPhaseError, deleteTrainingPhaseError, updatePlanError, plansByPtError]);

  useEffect(() => {
    const calculateLoadingValue = updatePlanLoading && updateTrainingPhaseLoading
        && trainingPhasesLoading && deleteTrainingPhaseLoading && plansByPtLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updatePlanLoading, trainingPhasesLoading, updateTrainingPhaseLoading, deleteTrainingPhaseLoading, plansByPtLoading]);

  const getData = () => {
    getTrainingPhases().then((res) => {
      setTrainingPhases(res.data.trainingPhases);
      setTrainingPhaseToDisplay(res?.data?.trainingPhases?.phases || []);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onDeleteTrainingPhase = () => {
    setOpenDeletePopup(false);
    if (trainingPhases?.phases?.length === 1) {
      deleteTrainingPhase({
        variables: {
          idPhase: trainingPhases._id,
        },
      }).then(() => {
        plansByPt.forEach((p) => {
          if (p.phase === trainingPhaseToBeHandled.name) {
            const updatedPlan = { ...p, phase: 'Nessuna fase' };
            const planInput = createPlanInputObjectFromPlanResponse(updatedPlan);
            updatePlan({
              variables: {
                idUser: updatedPlan.owner?._id,
                idPlan: updatedPlan._id,
                planInput,
                ptId: id,
              },
            }).then(() => {
              console.log('updated plan');
            });
          }
        });
        setTrainingPhaseToBeHandled({});
        getData();
      });
    } else if (trainingPhases?.phases && trainingPhases?.phases?.length > 0 && isPhases(trainingPhaseToBeHandled)) {
      const phases = [...trainingPhases.phases];
      const newPhases = phases.filter((p) => p.name !== trainingPhaseToBeHandled.name);
      const phaseInput = {
        phases: newPhases,
        pt: id,
      };
      updateTrainingPhase({
        variables: {
          phaseInput,
          idPhase: trainingPhases._id,
        },
      }).then(() => {
        plansByPt.forEach((p) => {
          if (p.phase === trainingPhaseToBeHandled.name) {
            const updatedPlan = { ...p, phase: 'Nessuna fase' };
            const planInput = createPlanInputObjectFromPlanResponse(updatedPlan);
            updatePlan({
              variables: {
                idUser: updatedPlan.owner?._id,
                idPlan: updatedPlan._id,
                planInput,
                ptId: id,
              },
            }).then(() => {
              console.log('updated plan');
            });
          }
        });
        setTrainingPhaseToBeHandled({});
        getData();
      });
    }
  };

  const resetSearch = () => {
    setSearching('');
    setTrainingPhaseToDisplay(trainingPhases?.phases || []);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (trainingPhases) {
      console.log(
        trainingPhases?.phases ? [...trainingPhases.phases.filter((phase: IPhases) => phase?.name?.includes(newValue))] : [],
        'trainingPhases?.phases ? [...trainingPhases.phases.filter((phase: IPhases) => phase?.name?.includes(newValue))] : []',
      );
      console.log(trainingPhases, 'trainingPhases');
      console.log(trainingPhases.phases, 'trainingPhases.phases');
      setTrainingPhaseToDisplay(trainingPhases?.phases ? [...trainingPhases.phases.filter((phase: IPhases) => phase?.name?.includes(newValue))] : []);
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (trainingPhases) {
      setTrainingPhaseToDisplay(trainingPhases?.phases
        ? [...trainingPhases.phases.filter((phase: IPhases) => phase?.name?.includes(searching))] : []);
    }
  };

  return {
    searching,
    onChange,
    resetSearch,
    trainingPhaseToDisplay,
    loading,
    apiError,
    setApiError,
    openDeletePopup,
    setOpenDeletePopup,
    onDeleteTrainingPhase,
    setTrainingPhaseToBeHandled,
    onClickSearchButton,
  };
};

export default PTPhasesController;
