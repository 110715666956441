import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IMuscleGroup } from '../../../../common/interfaces/MuscleGroup';
import { GET_MUSCLE_GROUPS_BY_PT } from '../../../../apollo/hooks/useGetMuscleGroupsByPt';
import { AuthContext } from '../../../../context/auth';
import { UPDATE_TRAINING_PHASE } from '../../../../apollo/mutations/updateTrainingPhase';
import { DELETE_MUSCLE_GROUP } from '../../../../apollo/mutations/deleteMuscleGroup';

type ControllerFN = () => {
  searching: string;
  onChange: (e: any) => void
  onClickSearchButton: () => void
  resetSearch: () => void
  muscleGroupsToDisplay: IMuscleGroup[] | null
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
  setOpenDeletePopup: Dispatch<SetStateAction<boolean>>
  setMuscleGroupToBeHandled: Dispatch<SetStateAction<IMuscleGroup| Record<string, unknown>>>
  openDeletePopup: boolean
  onDeleteMuscleGroup: () => void
};

const PTMuscleGroupsController: ControllerFN = () => {
  const [searching, setSearching] = useState<string>('');
  const [muscleGroups, setMuscleGroups] = useState<IMuscleGroup[]| null>(null);
  const [muscleGroupsToDisplay, setMuscleGroupsToDisplay] = useState<IMuscleGroup[]| null>(null);
  const [apiError, setApiError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [muscleGroupToBeHandled, setMuscleGroupToBeHandled] = useState < IMuscleGroup | Record<string, unknown> >({});

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const [getMuscleGroupsByPtId, { loading: muscleGroupsLoading, error }] = useLazyQuery(GET_MUSCLE_GROUPS_BY_PT, {
    variables: {
      ptId: id,
    },
  });
  const [updateMuscleGroup, { loading: updateMuscleGroupLoading, error: updateMuscleGroupError }] = useMutation(UPDATE_TRAINING_PHASE);
  const [deleteMuscleGroup, { loading: deleteMuscleGroupLoading, error: deleteMuscleGroupError }] = useMutation(DELETE_MUSCLE_GROUP);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else if (updateMuscleGroupError) {
      setApiError(updateMuscleGroupError.message);
    } else if (deleteMuscleGroupError) {
      setApiError(deleteMuscleGroupError.message);
    } else {
      setApiError('');
    }
  }, [error, updateMuscleGroupError, deleteMuscleGroupError]);

  useEffect(() => {
    const calculateLoadingValue = muscleGroupsLoading && updateMuscleGroupLoading && deleteMuscleGroupLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [muscleGroupsLoading, updateMuscleGroupLoading, deleteMuscleGroupLoading]);

  const getData = () => {
    getMuscleGroupsByPtId().then((res) => {
      setMuscleGroups(res.data?.muscleGroupsByPtId);
      setMuscleGroupsToDisplay(res?.data?.muscleGroupsByPtId || []);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const resetSearch = () => {
    setSearching('');
    setMuscleGroupsToDisplay(muscleGroups);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (muscleGroups) {
      setMuscleGroupsToDisplay([...muscleGroups.filter((ex: IMuscleGroup) => ex?.name?.includes(newValue))]);
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (muscleGroups) {
      setMuscleGroupsToDisplay([...muscleGroups.filter((ex: IMuscleGroup) => ex?.name?.includes(searching))]);
    }
  };

  const onDeleteMuscleGroup = () => {
    setOpenDeletePopup(false);
    deleteMuscleGroup({
      variables: {
        id: muscleGroupToBeHandled._id,
        ptId: id,
      },
    }).then(() => {
      setMuscleGroupToBeHandled({});
      getData();
    });
  };

  return {
    searching,
    onChange,
    resetSearch,
    muscleGroupsToDisplay,
    loading,
    apiError,
    setApiError,
    openDeletePopup,
    setOpenDeletePopup,
    onDeleteMuscleGroup,
    setMuscleGroupToBeHandled,
    onClickSearchButton,
  };
};

export default PTMuscleGroupsController;
