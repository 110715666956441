import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../context/auth';

const AuthRoute = ({ children } : {children : JSX.Element}) => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthRoute;
