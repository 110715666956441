import React from 'react';
import { Container, Scroller } from './HorizontalSection.style';
import TitleBar from '../TitleBar/TitleBar';
import Text from '../CustomTextFormatter/Text';

interface Props {
    title?: string,
    subtitle?: string,
    additionalStyle?: any,
    children: JSX.Element,
    onClickSubtitle?: () => void
}

export default function HorizontalSection({
  title, subtitle, additionalStyle, children, onClickSubtitle,
}: Props) {
  return (
    <Container sx={additionalStyle}>
      {title && (
      <TitleBar>
        <>
          {title && (
          <Text type="subtitle1" additionalStyle={{ flexGrow: 0.8 }}>
            {title}
          </Text>
          )}
          {subtitle && (
          <Text
            onClick={onClickSubtitle}
            type="subtitle2"
            additionalStyle={{ color: 'rgba(42, 51, 60, 0.3)', position: 'absolute', right: '1rem' }}
          >
            {subtitle}
          </Text>
          )}
        </>
      </TitleBar>
      )}
      <Scroller>
        {children}
      </Scroller>
    </Container>
  );
}
