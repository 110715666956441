import React from 'react';
import { Box } from '@mui/material';
import Day from './components/Day/Day';

interface IWeekdays {
    // eslint-disable-next-line react/no-unused-prop-types
    weekday: string,
    // eslint-disable-next-line react/no-unused-prop-types
    number: string
}

const curr = new Date();

const getCurrentWeek = () => {
  const week: IWeekdays[] | Record<string, unknown> = [];

  const weekdays: Record<string, string> = {
    1: 'L',
    2: 'M',
    3: 'M',
    4: 'G',
    5: 'V',
    6: 'S',
    7: 'D',
  };

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 7; i++) {
    const first = curr.getDate() - curr.getDay() + i;
    const obj: IWeekdays = {
      weekday: weekdays[i].toString(),
      number: first.toString(),
    };
    week.push(obj);
  }
  return week;
};

export default function Calendar({ selectedDay, onClick } : {selectedDay?: string, onClick?: (number: string)=>void}) {
  const week = getCurrentWeek();
  const selected = selectedDay || curr.getUTCDate().toString();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
      {week.map(({ weekday, number } : IWeekdays) => (
        <Day key={number} weekday={weekday} number={number} badge={false} selected={number === selected} onClick={onClick} />
      ))}
    </Box>
  );
}
