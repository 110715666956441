import { ApolloError, gql, useQuery } from '@apollo/client';
import { IUser } from '../../common/interfaces/User';

const GET_USER = gql`
    query user($id: ID!){
        user(id: $id) {
            _id
            name
            email
            age
            avatarSrc
            plans {
                _id
            }
            startDate
            macronutrients {
                kcals
                proteins
                carbs
                fats
            }
            measures {
                date
                bmi
                bust
                hips
                thigh
                waist
            }
            weight {
                date
                weight
            }
            maximal {
                benchPress
                deadLift
                squat
                militaryPress
            }
        }
    }
`;

type ControllerFN = (userId: string | undefined) => {
    user: IUser;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetUser: ControllerFN = (userId: string | undefined) => {
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: userId },
  });
  const user = data?.user;
  return { loading, user, error };
};
