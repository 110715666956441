import { ApolloError, gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { IUser } from '../../common/interfaces/User';
import { AuthContext } from '../../context/auth';

export const GET_ATHLETES_BY_PT = gql`
    query athletesByPtId($ptId: ID!){
        athletesByPtId(ptId: $ptId) {
            _id
            name
            age
            plans{
                _id
            }
        }
    }
`;

type ControllerFN = () => {
    athletes: IUser[] | undefined;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetAthletesByPersonalTrainer: ControllerFN = () => {
  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  const { data, loading, error } = useQuery(GET_ATHLETES_BY_PT, {
    variables: { ptId: id },
  });
  const athletes = data?.athletesByPtId;
  return { loading, athletes, error };
};
