import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const cardStyle = {
  borderBottom: '0.01rem solid rgba(255, 255, 255, 0.1)',
  background: 'transparent',
  borderRadius: '0.5rem',
  padding: '0.3rem',
  width: '100%',
  marginBottom: '1rem',
  boxShadow: '0rem 0rem 0rem',
};

export const cardContentStyle = {
  padding: 0,
  paddingBottom: '0!important',
  display: 'flex',
  gap: '1rem',
};

export const muscleGroupNameStyle = {
  textAlign: 'center',
};

export const LinearProgressContainer = styled(Box)`
  width: 98%;
  display: flex;
  align-items: center
  `;

export const MuscleNameContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 1rem;
  `;
