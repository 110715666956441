import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import Layout from '../../../components/Layout/Layout';
import ExerciseTrainingController from './controller/exerciseTraining.controller';
import AboveTrainingPage from './components/AboveTrainingPage/AboveTrainingPage';
import TrainingCompleted from './components/TrainingCompleted/TrainingCompleted';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import BelowTrainingPage from './components/BelowTrainingPage/BelowTrainingPage';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import SelectExerciseToTrain from './components/SelectExerciseToTrain/SelectExerciseToTrain';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';

interface OwnProps {}

type Props = OwnProps;

const ExerciseTraining: FunctionComponent<Props> = () => {
  const { id } = useParams();
  const {
    currentExercise,
    onClickTimerIcon,
    recover,
    loading,
    setExercise,
    currentSet,
    setRecover,
    exerciseCompleted,
    onChangeSlider,
    onClickSendLoads,
    trainingCompleted,
    activePlan,
    exercisesByDay,
    onPlayClick,
    expandExerciseList,
    setExpandExerciseList,
    onClickStopTimer,
    setOpenConfirmSkipDialog,
    openConfirmSkipDialog,
    setTrainingCompleted,
    selectedRating,
    setSelectedRating,
    trainingNote,
    setTrainingNote,
    onTrainingCompleted,
    currentExerciseVideo,
    apiError,
    setApiError,
  } = ExerciseTrainingController(id);

  if (loading) return (<LoaderSpinner />);

  return (
    <Layout additionalStyle={{ height: '100%', padding: 0 }}>
      <>
        <AboveTrainingPage
          exerciseCompleted={exerciseCompleted}
          recover={recover}
          setRecover={setRecover}
          currentExercise={currentExercise}
          currentExerciseVideo={currentExerciseVideo}
        />
        <BelowTrainingPage
          exerciseCompleted={exerciseCompleted}
          currentSet={currentSet}
          currentExercise={currentExercise}
          onChangeSlider={onChangeSlider}
          recover={recover}
          setExpandExerciseList={setExpandExerciseList}
          onClickTimerIcon={onClickTimerIcon}
          onClickStopTimer={onClickStopTimer}
          setOpenConfirmSkipDialog={setOpenConfirmSkipDialog}
          onClickSendLoads={onClickSendLoads}
        />

        <SlidingPopup
          open={trainingCompleted}
          handleClose={() => setTrainingCompleted(false)}
          handleAction={onTrainingCompleted}
          actionLabel="Termina allenamento"
          actions
          additionalStyle={
              {
                width: '60%',
                position: 'relative',
                background: '#1B1833',
                padding: '1.5rem 1.8rem 1.5rem 1.8rem',
                borderRadius: '1rem 1rem 1rem 1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 0,
                gap: '2rem',
              }
            }
        >
          <TrainingCompleted
            selectedRating={selectedRating}
            setSelectedRating={setSelectedRating}
            trainingNote={trainingNote}
            setTrainingNote={setTrainingNote}
          />
        </SlidingPopup>

        <SlidingPopup
          open={openConfirmSkipDialog}
          handleClose={() => setOpenConfirmSkipDialog(false)}
          additionalStyle={
            {
              width: '60%',
              position: 'relative',
              background: '#1B1833',
              padding: '1.5rem 1.8rem 1.5rem 1.8rem',
              borderRadius: '1rem 1rem 1rem 1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 0,
              gap: '2rem',
            }
          }
        >
          <ConfirmDialog
            title={'Non hai ancora completato l\'esercizio'}
            subtitle="Sei sicuro di volerlo saltare?"
            undoFunction={() => setOpenConfirmSkipDialog(false)}
            confirmFunction={() => setExercise(null, undefined, true)}
          />
        </SlidingPopup>

        <SlidingPopup
          open={expandExerciseList}
          handleClose={() => {
            setExpandExerciseList(false);
          }}
          additionalStyle={{ height: '60vh' }}
        >
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          <>
            { id && exercisesByDay[id] && Object.keys(exercisesByDay[id])?.map((mg: string) => (
              <SelectExerciseToTrain
                session={id}
                activePlan={activePlan}
                exercisesByDay={exercisesByDay}
                onPlayClick={onPlayClick}
                mg={mg}
              />
            ))}
          </>
        </SlidingPopup>

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default ExerciseTraining;
