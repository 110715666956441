import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const listStyle = {
  borderRadius: '1rem!important',
  width: '100%',
};
export const listItemStyle = {
  borderBottom: '0.01rem solid rgba(255, 255, 255, 0.1)',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
  height: '2.2rem',
};
export const listItemTextStyle = { textAlign: 'right', color: '#F9F9F9' };
export const iconStyle = { color: 'rgb(101, 193, 52)', width: '3rem', height: '3rem' };

export const ListItemContainer = styled(Box)`
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
  background: transparent;
  width: calc(100% - 2rem);
  height: 2.2rem;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin: 0.5rem 0 0.5rem 0;
  `;
