import { styled } from '@mui/system';
import { Box, TextField } from '@mui/material';

export const WATextField = styled(TextField)`
    & label.Mui-focused {
      color: white!important;
    },
    & .MuiInputLabel-root {
      color: white!important;
    },
    & .MuiOutlinedInput-root {
    & fieldset {
        border-color: rgba(255,255,255,0.2)!important;
      },
    & textarea {
        color: rgba(255,255,255,1)!important;
      },
    &:hover fieldset {
        border-color: rgba(255,255,255,0.2)!important;
      },
    &.Mui-focused fieldset {
        border-color: rgba(255,255,255,0.2)!important;
      },
    },
    input: {
      color: white!important
    },
    textarea: {
      color: white!important
    },
    label: {
      color: white!important
    },
`;

export const IconContainer = styled(Box)`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
`;

export const AddSetContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1rem;
`;

export const sliderStyle = {
  display: 'flex', alignItems: 'center', gap: '1rem', margin: '0 0.5rem 0 1rem',
};

export const addSetButton = {
  width: '3rem',
  height: '3rem',
  background: 'rgba(255,255,255,0.2)',
};

export const addSetButtonLabel = { color: '#F9F9F9', textAlign: 'center' };

export const cardStyle = {
  width: '100%', backgroundColor: 'rgba(255,255,255,0.2)!important', height: '90%', overflow: 'auto',
};
