import { gql } from '@apollo/client';

export interface IPerDate {
    date: number
    averageLoad: number
}

export const GET_AVERAGE_LOAD_PER_EXERCISE_IN_ALL_PLANS = gql`
    query averageLoadsPerExerciseInAllPlans($idExercise: ID!, $userId: ID!, $ptId: ID!){
        averageLoadsPerExerciseInAllPlans(idExercise: $idExercise, userId: $userId, , ptId: $ptId) {
            exercise
            averageLoad
            perDate {
                date,
                averageLoad
            }
        }
    }
`;
