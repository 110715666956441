import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const CssPicker = styled(TextField)`

    & .MuiOutlinedInput-root {
        & fieldset {
          border-color: rgba(255,255,255,0.2)!important;
        },
        & textarea {
          color: rgba(255,255,255,1)!important;
        },
        &:hover fieldset {
          border-color: rgba(255,255,255,0.2)!important;
        },
        &.Mui-focused fieldset {
          border-color: rgba(255,255,255,0.2)!important;
        },
        &.label{
          color: #f9f9f9!important;
        },
    },
  
    label: {
      color: #f9f9f9!important;
    },
`;
