import { useMemo } from 'react';
import { DoughnutData } from '../../Charts/Doughnut/interfaces/Doughnut';
import { IMuscleGroupPercentageInPlanStatsResponse } from '../../../common/interfaces/Stats';

// eslint-disable-next-line no-unused-vars
type ControllerFN = (planStats: IMuscleGroupPercentageInPlanStatsResponse[]) => {
  data: DoughnutData,
  options: any
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,

      labels: {
        color: 'rgba(255, 255, 255, 1)',
        pointStyle: 'circle',
      },
    },
  },
  maintainAspectRatio: false,
};

const PlanCardController: ControllerFN = (planStats: IMuscleGroupPercentageInPlanStatsResponse[]) => {
  const data = useMemo(() => ({
    labels: planStats?.map((ps) => ps.muscleGroup),
    datasets: [
      {
        label: 'Muscle Group Percentage',
        data: planStats?.map((ps) => ps.percentage),
        backgroundColor: [
          'rgb(113, 26, 117)',
          'rgb(241, 0, 134)',
          'rgb(119, 67, 96)',
          'rgb(178, 80, 104)',
          'rgb(231, 171, 121)',
          'rgb(193, 71, 233)',
          'rgb(229, 184, 244)',
        ],
        borderWidth: 0,
      },
    ],
  }), []);

  return {
    data, options,
  };
};

export default PlanCardController;
