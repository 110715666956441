import { useEffect, useState } from 'react';
import { IExercise } from '../../../common/interfaces/Exercise';

type ControllerFN = (exercise : IExercise | null, type: string) => {
  data: any
};

const ExerciseCardController: ControllerFN = (exercise : IExercise | null, type: string) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (type === 'polar') {
      const labels = exercise?.muscleGroups?.map((mg) => mg?.muscleGroup?.name);
      const dataChart = exercise?.muscleGroups?.map((mg) => mg.percentage);
      const chart = {
        labels,
        datasets: [
          {
            label: 'Muscle Groups',
            data: dataChart,
            backgroundColor: [
              'rgba(255, 99, 132, 0.4)',
              'rgba(54, 162, 235, 0.4)',
              'rgba(255, 206, 86, 0.4)',
              'rgba(75, 192, 192, 0.4)',
              'rgba(153, 102, 255, 0.4)',
              'rgba(255, 159, 64, 0.4)',
            ],
          },
        ],
      };
      setData(chart);
    }
  }, [exercise]);

  return {
    data,
  };
};

export default ExerciseCardController;
