import React from 'react';
import {
  ArcElement, Chart as ChartJS, Legend, Tooltip,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ChartOpt } from '../../../common/interfaces/Chart';
import { DoughnutData } from './interfaces/Doughnut';

ChartJS.register(ArcElement, Tooltip, Legend);

export function DoughnutChart({ data, options }:{data: DoughnutData | Record<string, unknown>, options?: ChartOpt}) {
  return (
    <Doughnut
      width="30%"
      height="30%"
      redraw
      // @ts-ignore
      data={data}
      options={options}
    />
  );
}
