import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

export const Menu = styled(Box)`
  margin: 0;
  display: flex;
  /* Works well with 100% width  */
  width: 100%;
  font-size: 1.5em;
  position: fixed;
  height: 3.5rem;
  bottom: 0;
  align-items: flex-start;
  justify-content: space-around;
  background-color: rgb(67, 67, 67);
  z-index: 100;
`;

export const MenuBorder = styled(Box)`
  left: 0;
  bottom: 60%;
  width: 9rem;
  height: 3rem;
  position: absolute;
  clip-path: url(#menu);
  will-change: transform;
  background-color: rgb(67, 67, 67);
  transition: transform 0.5s;
`;

export const useStyles = makeStyles({
  menuItem: {
    all: 'unset',
    zIndex: '100',
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: '50%',
    alignItems: 'center',
    willChange: 'transform',
    justifyContent: 'center',
    transition: 'transform 0.5s',
    height: '3rem',
    width: '3rem',
    '&::before': {
      content: '',
      zIndex: '-1',
      width: '4.2em',
      height: '4.2em',
      borderRadius: '50%',
      position: 'absolute',
      transform: 'scale(0)',
      transition: 'background-color .7s, transform .7s',
    },
  },
  menuItemActive: {
    background: 'linear-gradient(180deg, hsla(0, 86%, 66%, 1) 0%, hsla(0, 86%, 66%, 0.8) 100%)!important',
    transform: 'translate3d(0, -.3em , 0)',
    '&::before': {
      transform: 'scale(1)',
    },
    '& icon': {
      animation: '$strok 1.5s reverse',
      stroke: 'white',
    },
  },
  '@keyframes strok': {
    '100%': {
      strokeDashoffset: 400,
    },
  },
  navbarIcon: {
    width: '2rem',
    height: '2rem',
    fill: 'transparent!important',
    strokeWidth: ' 1pt',
    strokeMiterlimit: '10',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: '400',
  },

  customNavbarIcon: {
    width: '1.8rem',
    strokeWidth: '0.7rem',
    stroke: 'white',
    fill: 'white!important',
  },

  svgNavbarContainer: {
    width: 0,
    height: 0,
  },
});
