/* eslint-disable no-unused-vars */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import {
  DATE_PICKER, SELECT, SWITCH, TEXT_FIELD,
} from './constants';
import { IFormFields } from './interfaces/Form';
import Text from '../CustomTextFormatter/Text';
import { CssTextField } from './Form.style';
import { TextFieldContainer } from '../../pages/pt/PTAddPlan/components/PlanInfoForm/PlanInfoForm.style';
import { CssSelect } from '../Form/Form.style';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import SwitchButton from '../SwitchButton/SwitchButton';

export default function StateControlledForm(
  {
    formFields,
  } : {formFields: IFormFields[]},
) {
  return (
    <>
      {formFields.map((field) => {
        if (field.fieldType === TEXT_FIELD) {
          return (
            <TextFieldContainer>
              <CssTextField
                sx={{ input: { color: 'white!important' } }}
                InputLabelProps={{
                  style: { color: '#fff!important' },
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                type={field.type}
                error={field.error}
                helperText={field.helperText}
                value={field.value}
                onChange={field.onChange}
              />
            </TextFieldContainer>
          );
        }
        if (field.fieldType === SELECT) {
          return (
            <TextFieldContainer>
              <CssSelect
                sx={{ marginTop: '1rem', marginBottom: '0.5rem' }}
                labelId={field.id}
                id={field.id}
                value={field.value}
                label={field.label}
                    // @ts-ignore
                onChange={field.onChange}
                fullWidth
              >
                {field?.list?.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </CssSelect>
            </TextFieldContainer>
          );
        }
        if (field.fieldType === DATE_PICKER) {
          return (
            <CustomDatePicker
              label={field.label || ''}
              value={field.dateValue || dayjs()}
                  // @ts-ignore
              handleChange={field.handleChangeDate}
            />
          );
        }
        if (field.fieldType === SWITCH) {
          return (
            <SwitchButton
             // @ts-ignore
              onChange={field.onChange}
              checked={field.checked === undefined ? false : field.checked}
              labelChecked={field.labelChecked || ''}
              labelUnchecked={field.labelUnchecked || ''}
              additionalStyle={field.additionalStyle}
            />
          );
        }
        return (
          <Text
            type="h2"
          >
            Error
          </Text>
        );
      })}
    </>
  );
}
