import { ApolloError, gql, useQuery } from '@apollo/client';
import { IExercise } from '../../common/interfaces/Exercise';

const GET_EXERCISE_BY_ID = gql`
    query exercisesByPtId($ptId: ID!){
        exercisesByPtId(ptId: $ptId) {
            _id
            video
        }
    }
`;

type ControllerFN = (id: string | undefined) => {
    exercisesByPtIdVideo: IExercise[] | undefined;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetExerciseByPtIdVideo: ControllerFN = (ptId: string | undefined) => {
  const { data, loading, error } = useQuery(GET_EXERCISE_BY_ID, {
    variables: { ptId },
  });
  const exercisesByPtIdVideo = data?.exercisesByPtId;
  return { loading, exercisesByPtIdVideo, error };
};
