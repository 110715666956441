import React, { FunctionComponent } from 'react';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { ReactComponent as PlansIcon } from '../../../../../asset/icons/plans.svg';
import { ReactComponent as ReportsIcon } from '../../../../../asset/icons/reports.svg';
import ShortcutCard from '../../../../../components/ShortcutCard/ShortcutCard';
import { ButtonContainerRow, shortcutsButton } from './PTAthleteShortcuts.style';
import { PATH_CONSTANTS } from '../../../../../helper/constants';
import { iconStyle } from '../../../../../common/styles/svgIcons.style';

interface OwnProps {
  userId: string
}

type Props = OwnProps;

const PTAthleteShortcuts: FunctionComponent<Props> = ({ userId }) => {
  const navigate = useNavigate();
  const {
    PLANS_PT_PATH, ADD_PLAN_GROUP_PT_PATH, REPORTS_PT_PATH,
  } = PATH_CONSTANTS;
  return (
    <Box sx={{
      height: '40%', display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%',
    }}
    >
      <ButtonContainerRow>
        <ShortcutCard
          label="Report"
          icon={<ReportsIcon style={iconStyle} />}
          onClick={() => navigate(`${REPORTS_PT_PATH}/${userId}`)}
          additionalStyle={shortcutsButton}
        />
        <ShortcutCard
          label="Plani"
          icon={<PlansIcon style={iconStyle} />}
          onClick={() => navigate(`${PLANS_PT_PATH}/${userId}`)}
          additionalStyle={shortcutsButton}
        />
      </ButtonContainerRow>

      <ButtonContainerRow>
        <ShortcutCard
          label="Crea piano"
          icon={<AddIcon sx={{ color: '#f9f9f9' }} />}
          onClick={() => navigate(`${ADD_PLAN_GROUP_PT_PATH}/${userId}`)}
          additionalStyle={shortcutsButton}
        />
        <ShortcutCard
          label="Invia messaggio"
          icon={<AccessibilityNewIcon sx={{ color: '#f9f9f9' }} />}
          onClick={() => { console.log('todo socket'); }}
          additionalStyle={shortcutsButton}
        />
      </ButtonContainerRow>
    </Box>
  );
};

export default PTAthleteShortcuts;
