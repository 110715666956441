import { styled } from '@mui/system';
import { Avatar, Box } from '@mui/material';

export const ListItemContainer = styled(Box)`
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
  background: transparent;
  width: calc(100% - 2rem);
  height: 2.2rem;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin: 0.5rem 0 0.5rem 0;
  `;

export const StyledAvatar = styled(Avatar)`
  background: rgba(255, 255, 255, 0.2)
  `;
