import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { PALETTE } from '../../helper/constants';

interface Props {
  variant: string;
  endIcon: JSX.Element
}

const { WHITEISH } = PALETTE;

export const StyledButton = styled(Button)<Props>(() => ({
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '0.25rem',
  padding: '1rem',
  color: WHITEISH,
  width: '100%',
}));
