import React from 'react';
import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BarData } from './interfaces/Bar';
import { ChartOpt } from '../../../common/interfaces/Chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export default function BarChart({ data, options } : { data: BarData | Record<string, unknown>, options: ChartOpt }) {
  return (
  // @ts-ignore
    <Bar options={options} data={data} />
  );
}
