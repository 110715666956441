/* eslint-disable react/no-array-index-key */
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { RepsBox, StyledRepsChip } from './RepsChip.style';
import { ISetRep } from '../../common/interfaces/Exercise';

interface Props {
  exerciseReps: ISetRep[];
  additionalChipStyle?: any;
  handleDelete?: any;
}

export default function RepsChip({ exerciseReps, additionalChipStyle, handleDelete }: Props) {
  return (
    <RepsBox>
      {exerciseReps?.map(({ rep, type }, index) => {
        // eslint-disable-next-line no-nested-ternary
        const avatarText = type === 'RestPause' ? 'RP' : type === 'SuperSet' ? 'SS' : type === 'Stripping' ? 'ST' : 'N';
        if (handleDelete) {
          return (
            <StyledRepsChip
              key={index}
              sx={additionalChipStyle}
              avatar={<Avatar sx={{ color: 'white' }}>{avatarText}</Avatar>}
              size="small"
              label={`1 x ${rep.toString()}`}
              onDelete={() => handleDelete(index)}
            />
          );
        }
        return (
          <StyledRepsChip
            key={index}
            avatar={<Avatar sx={{ color: 'white' }}>{avatarText}</Avatar>}
            sx={additionalChipStyle}
            size="small"
            label={`1 x ${rep.toString()}`}
          />
        );
      })}
    </RepsBox>
  );
}
