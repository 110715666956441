import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { GiCometSpark } from 'react-icons/gi';
import PlanController from './controller/plan.controller';
import Pagebar from '../../../components/Pagebar/Pagebar';
import UserExerciseList from '../../../components/UserExerciseList/UserExerciseList';
import Text from '../../../components/CustomTextFormatter/Text';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { ButtonContainer, muscleGroupSection, titleBarStyle } from './Plan.style';
import Layout from '../../../components/Layout/Layout';
import Card from '../../../components/Card/Card';
import Divider from '../../../components/Divider/Divider';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import { pageMainCard } from '../../../common/styles/commonComponents';
import DetailsBox from '../../../components/DetailsBox/DetailsBox';

interface OwnProps {}

type Props = OwnProps;

const Plan: FunctionComponent<Props> = () => {
  const { id } = useParams();
  const {
    planByUserWithStats, loading, exercisesByDay, setSession, session, apiError, setApiError,
  } = PlanController(id);

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} title={planByUserWithStats?.plan?.name} />

        <Card
          additionalStyle={pageMainCard}
        >
          <>
            <ButtonContainer>
              {Object.keys(exercisesByDay).map((day) => (
                <DetailsBox
                  key={day}
                  onClick={() => setSession(day)}
                  label={`Workout ${day}`}
                  additionalStyle={{
                    background: session === day ? 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(89,89,89,1) 100%)'
                      : 'rgba(255, 255, 255, 0.01)',
                  }}
                >
                  <GiCometSpark style={{ width: '2rem', height: '2rem', color: '#F9F9F9' }} />
                </DetailsBox>
              ))}
            </ButtonContainer>

            <Divider />

            {exercisesByDay[session] && Object.keys(exercisesByDay[session])?.map((mg: string) => (
              <React.Fragment key={mg}>
                <TitleBar additionalStyle={titleBarStyle}>
                  <Text type="h1" additionalStyle={muscleGroupSection}>
                    {mg}
                  </Text>
                </TitleBar>
                <UserExerciseList
                  planId={planByUserWithStats?.plan?._id}
                  exerciseList={exercisesByDay[session][mg]}
                  icons={planByUserWithStats.planStats}
                  training={false}
                  muscleGroup={mg}
                />
              </React.Fragment>
            ))}
          </>

        </Card>
        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />

      </>
    </Layout>
  );
};

export default Plan;
