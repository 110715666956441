import React from 'react';
import HorizontalSection from '../../../../../components/HorizontalSection/HorizontalSection';
import StatsCard from '../../../../../components/StatsCard/StatsCard';
import { ExerciseList, statsCardStyle } from './ExerciseStats.style';
import ListItem from '../../../../../components/ListItem/ListItem';
import { IExercise } from '../../../../../common/interfaces/Exercise';
import CustomSearchBar from '../../../../../components/SearchBar/SearchBar';

interface Props {
    exerciseToDisplay: IExercise[] | null
    searching: string
    onChange: (e: any)=>void
    resetSearch: ()=>void
    onClickSearchButton: ()=>void
    onClickExercise: (idExercise: string)=>void
}

export default function ExerciseStats({
  searching, onChange, resetSearch, exerciseToDisplay, onClickExercise, onClickSearchButton,
}: Props) {
  return (
    <HorizontalSection title="Esercizio" additionalStyle={{ flexGrow: 1 }}>
      <StatsCard
        additionalCardStyle={statsCardStyle}
        statName="Peso medio per esercizio"
      >
        <>
          <CustomSearchBar
            placeholder="Cerca esercizio"
            value={searching}
            onCancelSearch={resetSearch}
            onChange={onChange}
            onClick={onClickSearchButton}
            showCancelButton
          />

          <ExerciseList>
            {exerciseToDisplay?.map((ex) => (
              <ListItem
                avatarSource={ex?.muscleGroups ? ex?.muscleGroups[0]?.muscleGroup?.icon : ''}
                key={ex._id}
                onClick={() => onClickExercise(ex._id || '')}
                name={ex.name || 'Default'}
              />
            ))}
          </ExerciseList>

        </>
      </StatsCard>
    </HorizontalSection>
  );
}
