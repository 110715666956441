import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AthleteIcon } from '../../../../../asset/icons/athlete.svg';
import { ReactComponent as ExercisesIcon } from '../../../../../asset/icons/exercise.svg';
import { ReactComponent as MuscleGroupsIcon } from '../../../../../asset/icons/musclegroups.svg';
import { ReactComponent as PhasesIcon } from '../../../../../asset/icons/phases.svg';
import ShortcutCard from '../../../../../components/ShortcutCard/ShortcutCard';
import { ButtonContainerRow, shortcutStyle } from './PTHomePageShortcuts.style';
import { PATH_CONSTANTS } from '../../../../../helper/constants';
import { iconStyle } from '../../../../../common/styles/svgIcons.style';

interface OwnProps {}

type Props = OwnProps;

const PTHomePageShortcuts: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const {
    EXERCISES_PT_PATH, MUSCLE_GROUPS_PT_PATH, ATHLETES_PT_PATH, PHASES_PT_PATH,
  } = PATH_CONSTANTS;
  return (
    <>
      <ButtonContainerRow>
        <ShortcutCard
          label="Atleti"
          icon={<AthleteIcon style={iconStyle} />}
          onClick={() => navigate(`${ATHLETES_PT_PATH}`)}
          additionalStyle={shortcutStyle}
        />
        <ShortcutCard
          label="Fasi di allenamento"
          icon={<PhasesIcon style={iconStyle} />}
          onClick={() => navigate(`${PHASES_PT_PATH}`)}
          additionalStyle={shortcutStyle}
        />
      </ButtonContainerRow>

      <ButtonContainerRow>
        <ShortcutCard
          label="Esercizi"
          icon={<ExercisesIcon style={iconStyle} />}
          onClick={() => navigate(`${EXERCISES_PT_PATH}`)}
          additionalStyle={shortcutStyle}
        />
        <ShortcutCard
          label="Gruppi muscolari"
          icon={<MuscleGroupsIcon style={iconStyle} />}
          onClick={() => navigate(`${MUSCLE_GROUPS_PT_PATH}`)}
          additionalStyle={shortcutStyle}
        />
      </ButtonContainerRow>
    </>
  );
};

export default PTHomePageShortcuts;
