import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { IMuscleGroupsInExercise } from '../../../../common/interfaces/Exercise';
import { useGetMuscleGroupsByPt } from '../../../../apollo/hooks/useGetMuscleGroupsByPt';
import { IMuscleGroup } from '../../../../common/interfaces/MuscleGroup';
import { ADD_EXERCISE } from '../../../../apollo/mutations/addExercise';
import { AuthContext } from '../../../../context/auth';
import { PATH_CONSTANTS } from '../../../../helper/constants';

type ControllerFN = () => {
  muscleGroupsInExercise:IMuscleGroupsInExercise[] | any
  muscleGroupsList: IMuscleGroup[]
  setMuscleGroupsInExercise: Dispatch<SetStateAction<IMuscleGroupsInExercise[]>>
  selectedMuscleGroups: IMuscleGroup[]
  setSelectedMuscleGroups: Dispatch<SetStateAction<IMuscleGroup[]>>
  name: string
  setName: Dispatch<SetStateAction<string>>
  howTo: string
  setHowTo: Dispatch<SetStateAction<string>>
  onSaveExercise: ()=>void
  formError: string,
  setFormError: Dispatch<SetStateAction<string>>
  youtubeVideo: string
  setYoutubeVideo: Dispatch<SetStateAction<string>>
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PTAddExerciseController: ControllerFN = () => {
  const { EXERCISES_PT_PATH } = PATH_CONSTANTS;

  const [muscleGroupsInExercise, setMuscleGroupsInExercise] = useState<IMuscleGroupsInExercise[] | any>([]);
  const [muscleGroupsList, setMuscleGroupsList] = useState<IMuscleGroup[] | any>([]);
  const [selectedMuscleGroups, setSelectedMuscleGroups] = useState<IMuscleGroup[]>([]);
  const [name, setName] = useState<string>('');
  const [howTo, setHowTo] = useState<string>('');
  const [youtubeVideo, setYoutubeVideo] = useState<string>('');
  const [formError, setFormError] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const { muscleGroupsByPtId, loading: muscleGroupByPtIdLoading, error: muscleGroupByPtIdError } = useGetMuscleGroupsByPt();
  const [addExercise, { loading: addExerciseLoading, error: addExerciseError }] = useMutation(ADD_EXERCISE);

  useEffect(() => {
    const calculateLoadingValue = addExerciseLoading && muscleGroupByPtIdLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [muscleGroupByPtIdLoading, addExerciseLoading]);

  useEffect(() => {
    if (addExerciseError) {
      setApiError(addExerciseError.message);
    } else if (muscleGroupByPtIdError) {
      setApiError(muscleGroupByPtIdError.message);
    } else {
      setApiError('');
    }
  }, [addExerciseError, muscleGroupByPtIdError]);

  useEffect(() => {
    if (muscleGroupsByPtId) {
      setMuscleGroupsList(muscleGroupsByPtId);
    }
  }, [muscleGroupsByPtId]);

  const onSaveExercise = () => {
    if (youtubeVideo.startsWith('https://www.youtube.com/') || youtubeVideo === '') {
      if (selectedMuscleGroups.length > 0 && name !== '') {
        const exerciseInput = {
          name,
          howTo,
          video: youtubeVideo,
          muscleGroups: muscleGroupsInExercise.map((mge: IMuscleGroupsInExercise) => (
            {
              muscleGroup: mge?.muscleGroup?._id,
              percentage: mge.percentage,
            }
          )).sort((a: IMuscleGroupsInExercise, b: IMuscleGroupsInExercise) => (b?.percentage || 0) - (a?.percentage || 0)),
          pt: id,
        };
        addExercise({
          variables: {
            exerciseInput,
          },
        }).then(() => {
          navigate(`${EXERCISES_PT_PATH}/`);
        });
      }
    } else {
      setFormError('You must choose a name and at least a muscle group before continue');
    }
  };

  return {
    name,
    setName,
    howTo,
    setHowTo,
    muscleGroupsInExercise,
    muscleGroupsList,
    setMuscleGroupsInExercise,
    selectedMuscleGroups,
    setSelectedMuscleGroups,
    onSaveExercise,
    formError,
    setFormError,
    youtubeVideo,
    setYoutubeVideo,
    loading,
    apiError,
    setApiError,
  };
};

export default PTAddExerciseController;
