import {
  Dispatch, SetStateAction, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import { IExercise } from '../../../../common/interfaces/Exercise';
import { useGetExerciseByAthlete } from '../../../../apollo/hooks/useGetExerciseByAthlete';
import {
  GET_AVERAGE_LOAD_PER_EXERCISE_IN_ALL_PLANS,
} from '../../../../apollo/hooks/useGetAverageLoadPerExerciseInAllPlans';
import { AuthContext } from '../../../../context/auth';
import { StatToDisplayEnum } from '../../../../common/enums/StatsToDisplayEnum';

type ControllerFN = () => {
  searching: string;
  onChange: (e: any) => void
  resetSearch: () => void
  onClickSearchButton: () => void
  onClickExercise: (idExercise: string) => void
  exerciseToDisplay: IExercise[] | null
  showStats: boolean
  setShowStats: Dispatch<SetStateAction<boolean>>
  exerciseByDateChart: any
  setStatToDisplay: Dispatch<SetStateAction<StatToDisplayEnum>>,
  statToDisplay: string,
  averagePerExerciseStat: number
  exerciseLoading: boolean
  apiExerciseError: string
  setApiExerciseError: Dispatch<SetStateAction<string>>
};

const ExercisesController: ControllerFN = () => {
  const [searching, setSearching] = useState<string>('');
  const [showStats, setShowStats] = useState<boolean>(false);
  const [exerciseToDisplay, setExerciseToDisplay] = useState<IExercise[]| null>(null);
  const [exercisePerDateStats, setExercisePerDateStats] = useState<any>([]);
  const [averagePerExerciseStat, setAveragePerExerciseStat] = useState<number>(0);
  const [statToDisplay, setStatToDisplay] = useState<StatToDisplayEnum>(StatToDisplayEnum.PER_DATE);
  const [exerciseLoading, setLoading] = useState<boolean>(true);
  const [apiExerciseError, setApiExerciseError] = useState<string>('');

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  // @ts-ignore
  const ptId = user?.pt[0]._id || '';

  const { exercisesByAthlete, exerciseListLoading, error: exerciseByAthleteError } = useGetExerciseByAthlete(id, ptId);
  const [
    getAverageLoadPerExerciseInAllPlans,
    { loading: averageLoadPerExerciseInAllPlansLoading, data, error: getAverageLoadPerExerciseInAllPlansError },
  ] = useLazyQuery(GET_AVERAGE_LOAD_PER_EXERCISE_IN_ALL_PLANS);

  useEffect(() => {
    if (exerciseByAthleteError) {
      setApiExerciseError(exerciseByAthleteError.message);
    } else if (getAverageLoadPerExerciseInAllPlansError) {
      setApiExerciseError(getAverageLoadPerExerciseInAllPlansError.message);
    } else {
      setApiExerciseError('');
    }
  }, [exerciseByAthleteError, getAverageLoadPerExerciseInAllPlansError]);

  useEffect(() => {
    const calculateLoadingValue = exerciseListLoading && averageLoadPerExerciseInAllPlansLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [exerciseListLoading, averageLoadPerExerciseInAllPlansLoading]);

  useEffect(() => {
    if (exercisesByAthlete) {
      setExerciseToDisplay(exercisesByAthlete);
    }
  }, [exercisesByAthlete]);

  useEffect(() => {
    if (data?.averageLoadsPerExerciseInAllPlans) {
      const { perDate } = data.averageLoadsPerExerciseInAllPlans;
      const sortByDate = perDate.sort((a: any, b: any) => a.date - b.date);
      const parsedDateStats = sortByDate.map((date: any) => ({
        ...date,
        date: format(date.date, 'dd/MM'),
      }));
      setExercisePerDateStats(parsedDateStats);
      setAveragePerExerciseStat(data?.averageLoadsPerExerciseInAllPlans.averageLoad);
    }
  }, [data]);

  const resetSearch = () => {
    setSearching('');
    setShowStats(false);
    setExerciseToDisplay(exercisesByAthlete);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (exercisesByAthlete) {
      setExerciseToDisplay([...exercisesByAthlete.filter((ex: IExercise) => ex?.name?.includes(newValue))]);
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (exercisesByAthlete) {
      setExerciseToDisplay([...exercisesByAthlete.filter((ex: IExercise) => ex?.name?.includes(searching))]);
    }
  };

  const onClickExercise = (idExercise: string) => {
    setShowStats(true);
    getAverageLoadPerExerciseInAllPlans({
      variables: { ptId, userId: id, idExercise },
    });
  };

  const exerciseByDateChart = useMemo(() => ({
    labels: exercisePerDateStats?.map(({ date }: {date: any}) => date),
    datasets: [
      {
        label: 'Average load per date',
        data: exercisePerDateStats?.map(({ averageLoad } : {averageLoad: any}) => averageLoad),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  }), [exercisePerDateStats]);

  return {
    searching,
    onChange,
    resetSearch,
    exerciseToDisplay,
    onClickExercise,
    showStats,
    exerciseByDateChart,
    setStatToDisplay,
    statToDisplay,
    averagePerExerciseStat,
    setShowStats,
    exerciseLoading,
    apiExerciseError,
    setApiExerciseError,
    onClickSearchButton,
  };
};

export default ExercisesController;
