import React, { FunctionComponent } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import PlanCard from '../../../components/PlanCard/PlanCard';
import Layout from '../../../components/Layout/Layout';
import PlansController from './controller/plans.controller';
import Pagebar from '../../../components/Pagebar/Pagebar';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { accordionStyle } from './Plans.style';
import Text from '../../../components/CustomTextFormatter/Text';
import { planNameStyle, StyledChip } from '../../../components/PlanCard/PlanCard.style';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import CustomSearchBar from '../../../components/SearchBar/SearchBar';

interface OwnProps {}

type Props = OwnProps;

const Plans: FunctionComponent<Props> = () => {
  const {
    plansToDisplay, loading, searching, onChange, resetSearch, apiError, setApiError, onClickSearchButton,
  } = PlansController();

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} title="Tutti i piani" />

        <TitleBar additionalStyle={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
          <CustomSearchBar
            placeholder="Cerca piano"
            value={searching}
            onCancelSearch={resetSearch}
            onChange={onChange}
            onClick={onClickSearchButton}
            showCancelButton
          />
        </TitleBar>

        {!loading && plansToDisplay?.map((plan) => (
          <Accordion key={plan.plan._id} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TitleBar>
                <>
                  <Text type="h2" additionalStyle={planNameStyle}>
                    {plan.plan.name}
                  </Text>
                  {plan.plan.active && <StyledChip size="small" label="Attivo" />}
                </>
              </TitleBar>
            </AccordionSummary>
            <AccordionDetails>
              <PlanCard
                key={plan.plan._id}
                plan={plan}
                additionalCardStyle={{ background: 'rgba(255,255,255,0.3)' }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default Plans;
