import { format } from 'date-fns';
import { IPlansCardResponse, IPlansInput, IVolumes } from '../../common/interfaces/Plans';
import { IExerciseInPlan } from '../../common/interfaces/Exercise';

export const createPlanInputObjectFromPlanResponse = (plan: IPlansCardResponse) => {
  const startDate = format(new Date(plan.startDate), 'MM/dd/yyyy');
  const endDate = format(new Date(plan.endDate), 'MM/dd/yyyy');
  const planInput: IPlansInput = {
    days: plan.days || 0,
    name: plan.name,
    startDate,
    endDate,
    phase: plan.phase,
    caption: plan.caption,
    lastTrainingNote: plan.lastTrainingNote ? plan.lastTrainingNote : '',
    lastTrainingDate: plan.lastTrainingDate ? format(new Date(plan.lastTrainingDate), 'MM/dd/yyyy') : format(new Date(0), 'MM/dd/yyyy'),
    lastTrainingRating: plan.lastTrainingRating ? plan.lastTrainingRating : 0,
    // @ts-ignore
    owner: plan.owner && plan?.owner?._id ? plan?.owner?._id : '',
    active: plan.active,
    volumes: plan.volumes.map((vol: IVolumes) => ({
      trainingSets: vol.trainingSets,
      volume: vol.volume,
      muscleGroup: vol.muscleGroup._id || '',
    })),
    // @ts-ignore
    exercise: plan?.exercise.map((ae: IExerciseInPlan) => (
      {
        exercise: ae.exercise._id,
        setRep: ae.setRep.map((sr: any) => ({
          set: sr.set,
          rep: sr.rep,
          type: sr.type,
        })),
        day: ae.day,
        note: ae.note,
        loads: ae.loads ? ae.loads.map((load: any) => (
          {
            date: format(new Date(load.date), 'MM/dd/yyyy'),
            set: load.set.map((s: any) => ({
              noSet: s.noSet,
              loadInKg: s.loadInKg,
            })),
          }
        )) : [],
        recovery: ae.recovery,
      }
    )),
    // owner: plan.owner,
  };
  return planInput;
};

export const createPlanInputExtendedObjectFromPlanResponse = (plan: IPlansCardResponse) => {
  const startDate = format(new Date(plan.startDate), 'MM/dd/yyyy');
  const endDate = format(new Date(plan.endDate), 'MM/dd/yyyy');
  const planInput: IPlansInput = {
    days: plan.days || 0,
    name: plan.name,
    startDate,
    endDate,
    phase: plan.phase,
    caption: plan.caption,
    lastTrainingNote: plan.lastTrainingNote ? plan.lastTrainingNote : '',
    lastTrainingDate: plan.lastTrainingDate ? format(new Date(plan.lastTrainingDate), 'MM/dd/yyyy') : format(new Date(0), 'MM/dd/yyyy'),
    lastTrainingRating: plan.lastTrainingRating ? plan.lastTrainingRating : 0,
    // @ts-ignore
    owner: plan.owner && plan?.owner?._id ? plan?.owner?._id : '',
    active: plan.active,
    volumes: plan.volumes.map((vol: IVolumes) => ({
      trainingSets: vol.trainingSets,
      volume: vol.volume,
      muscleGroup: vol.muscleGroup._id || '',
    })),
    // @ts-ignore
    exercise: plan?.exercise.map((ae: IExerciseInPlan) => (
      {
        exercise: ae.exercise,
        setRep: ae.setRep.map((sr: any) => ({
          set: sr.set,
          rep: sr.rep,
          type: sr.type,
        })),
        day: ae.day,
        note: ae.note,
        loads: ae.loads ? ae.loads.map((load: any) => (
          {
            date: format(new Date(load.date), 'MM/dd/yyyy'),
            set: load.set.map((s: any) => ({
              noSet: s.noSet,
              loadInKg: s.loadInKg,
            })),
          }
        )) : [],
        recovery: ae.recovery,
      }
    )),
    // @ts-ignore
    owner: plan.owner._id,
  };
  return planInput;
};
