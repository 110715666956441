import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/athlete/Home/Home';
import Plans from './pages/athlete/Plans/Plans';
import Plan from './pages/athlete/Plan/Plan';
import Exercise from './pages/athlete/Exercise/Exercise';
import Training from './pages/athlete/Training/Training';
import ExerciseTraining from './pages/athlete/ExerciseTraining/ExerciseTraining';
import SplashScreen from './components/SplashScreen/SplashScreen';
import Login from './pages/common/Login/Login';
import PTAddAthlete from './pages/pt/PTAddAthlete/PTAddAthlete';
import AuthRoute from './components/AuthRoute/AuthRoute';
import PTHomePage from './pages/pt/PTHomePage/PTHomePage';
import PTAthletes from './pages/pt/PTAthletesPage/PTAthletesPage';
import PTExercises from './pages/pt/PTExercisesPage/PTExercisesPage';
import PTMuscleGroups from './pages/pt/PTMuscleGroupsPage/PTMuscleGroupsPage';
import PTAthlete from './pages/pt/PTAthletePage/PTAthletePage';
import PTAddExercise from './pages/pt/PTAddExercise/PTAddExercise';
import PTAddMuscleGroup from './pages/pt/PTAddMuscleGroup/PTAddMuscleGroup';
import PTAddPlan from './pages/pt/PTAddPlan/PTAddPlan';
import PTExercise from './pages/pt/PTExercisePage/PTExercisePage';
import PTPlans from './pages/pt/PTPlans/PTPlans';
import PTPlan from './pages/pt/PTPlan/PTPlan';
import Navbar from './components/Navbar/Navbar';
import Reports from './pages/athlete/Reports/Reports';
import PTReports from './pages/pt/PTReports/PTReports';
import PTPhases from './pages/pt/PTPhasesTraining/PTPhasesPage';
import PTAddTrainingPhase from './pages/pt/PTAddTrainingPhase/PTAddTrainingPhase';
import PTEditAthlete from './pages/pt/PTEditAthlete/PTEditAthlete';
import PTEditExercise from './pages/pt/PTEditExercise/PTEditExercise';
import PTEditMuscleGroup from './pages/pt/PTEditMuscleGroup/PTEditMuscleGroup';
import PTEditTrainingPhase from './pages/pt/PTEditTrainingPhase/PTEditTrainingPhase';
import PTEditPlan from './pages/pt/PTEditPlan/PTEditPlan';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/dashboard" element={<AuthRoute><Home /></AuthRoute>} />
        <Route path="/reports" element={<AuthRoute><Reports /></AuthRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/selectProfile" element={<Home />} />
        <Route path="/notification" element={<Home />} />
        <Route path="/profile" element={<Home />} />
        <Route path="/plans" element={<AuthRoute><Plans /></AuthRoute>} />
        <Route path="/plans/:id" element={<AuthRoute><Plan /></AuthRoute>} />
        <Route path="/plans/:id/:exerciseId" element={<AuthRoute><Exercise /></AuthRoute>} />
        <Route path="/training" element={<AuthRoute><Training /></AuthRoute>} />
        <Route path="/training/:id" element={<AuthRoute><ExerciseTraining /></AuthRoute>} />
        <Route path="/pt/dashboard" element={<AuthRoute><PTHomePage /></AuthRoute>} />
        <Route path="/pt/athletes" element={<AuthRoute><PTAthletes /></AuthRoute>} />
        <Route path="/pt/athletes/:id" element={<AuthRoute><PTAthlete /></AuthRoute>} />
        <Route path="/pt/phases" element={<AuthRoute><PTPhases /></AuthRoute>} />
        <Route path="/pt/reports/:userId" element={<AuthRoute><PTReports /></AuthRoute>} />
        <Route path="/pt/muscleGroups" element={<AuthRoute><PTMuscleGroups /></AuthRoute>} />
        <Route path="/pt/addAthlete" element={<AuthRoute><PTAddAthlete /></AuthRoute>} />
        <Route path="/pt/addExercise" element={<AuthRoute><PTAddExercise /></AuthRoute>} />
        <Route path="/pt/addMuscleGroup" element={<AuthRoute><PTAddMuscleGroup /></AuthRoute>} />
        <Route path="/pt/addTrainingPhase" element={<AuthRoute><PTAddTrainingPhase /></AuthRoute>} />
        <Route path="/pt/editAthlete/:athleteId" element={<AuthRoute><PTEditAthlete /></AuthRoute>} />
        <Route path="/pt/editExercise/:exerciseId" element={<AuthRoute><PTEditExercise /></AuthRoute>} />
        <Route path="/pt/editMuscleGroup/:muscleGroupId" element={<AuthRoute><PTEditMuscleGroup /></AuthRoute>} />
        <Route path="/pt/editTrainingPhase/:trainingPhaseId" element={<AuthRoute><PTEditTrainingPhase /></AuthRoute>} />
        <Route path="/pt/addPlan/:userId" element={<AuthRoute><PTAddPlan /></AuthRoute>} />
        <Route path="/pt/editPlan/:planId" element={<AuthRoute><PTEditPlan /></AuthRoute>} />
        <Route path="/pt/plans/:userId" element={<AuthRoute><PTPlans /></AuthRoute>} />
        <Route path="/pt/plans/:userId/:planId" element={<AuthRoute><PTPlan /></AuthRoute>} />
        <Route path="/pt/exercises" element={<AuthRoute><PTExercises /></AuthRoute>} />
        <Route path="/pt/exercises/:exerciseId" element={<AuthRoute><PTExercise /></AuthRoute>} />
      </Routes>
      <Navbar />
    </>
  );
}

export default App;
