import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex; 
  justify-content: space-around; 
  flex-wrap: wrap
`;

export const detailsBoxStyle = { width: '4rem', height: '4rem', backgroundColor: 'rgba(255, 255, 255, 0.3)' };
