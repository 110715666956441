/* eslint-disable */
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IPlansCardWithStats} from '../../../../common/interfaces/Plans';
import {useGetPlansWithStatsByUserIdAndPlanId} from '../../../../apollo/hooks/useGetPlanWithStatsByUserIdAndPlanId';
import {groupPlanByDayAndMuscleGroup} from "../../../../helper/utility";

// eslint-disable-next-line no-unused-vars
type ControllerFN = (planId : string | undefined, userId: string | undefined) => {
  planByUserWithStats: IPlansCardWithStats;
  loading: boolean;
  exercisesByDay: any;
  setSession: Dispatch<SetStateAction<string>>;
  session: string;
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PlanController: ControllerFN = (planId : string | undefined, userId: string | undefined) => {
  const [exercisesByDay, setExerciseByDay] = useState<{} | undefined>({});
  const [session, setSession] = useState<string>('1');
  const [apiError, setApiError] = useState<string>('');

  const { planByUserWithStats, loading, error } = useGetPlansWithStatsByUserIdAndPlanId(planId, userId);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  useEffect(() => {
    if (planByUserWithStats) {
      setExerciseByDay(groupPlanByDayAndMuscleGroup(planByUserWithStats));
    }
  }, [planByUserWithStats]);

  return {
    planByUserWithStats, loading, exercisesByDay, setSession, session, apiError, setApiError
  };
};

export default PlanController;
