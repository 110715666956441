import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { IPlansCardWithStats } from '../../../../common/interfaces/Plans';
import { useGetPlansWithStatsByUserId } from '../../../../apollo/hooks/useGetPlansWithStatsByUserId';
import { AuthContext } from '../../../../context/auth';

type ControllerFN = () => {
  plansToDisplay: IPlansCardWithStats[] | null
  loading: boolean
  searching: string
  onChange: (e: any) => void
  onClickSearchButton: () => void
  resetSearch: () => void
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PlansController: ControllerFN = () => {
  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  const { plansByUserWithStats, loading: plansByUserWithStatsLoading, error } = useGetPlansWithStatsByUserId(id);
  const [searching, setSearching] = useState<string>('');
  const [plansToDisplay, setPlansToDisplay] = useState<IPlansCardWithStats[]| null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const [apiError, setApiError] = useState<string>('');

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  useEffect(() => {
    const calculateLoadingValue = plansByUserWithStatsLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [plansByUserWithStatsLoading]);

  useEffect(() => {
    if (plansByUserWithStats) {
      setPlansToDisplay(plansByUserWithStats);
    }
  }, [plansByUserWithStats]);

  const resetSearch = () => {
    setSearching('');
    setPlansToDisplay(plansByUserWithStats);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (plansByUserWithStats) {
      setPlansToDisplay([...plansByUserWithStats.filter((plan) => plan?.plan?.name?.includes(newValue))]);
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (plansByUserWithStats) {
      setPlansToDisplay([...plansByUserWithStats.filter((plan) => plan?.plan?.name?.includes(searching))]);
    }
  };

  return {
    loading,
    searching,
    onChange,
    plansToDisplay,
    resetSearch,
    apiError,
    setApiError,
    onClickSearchButton,
  };
};

export default PlansController;
