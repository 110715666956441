import React from 'react';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import Text from '../../../../../components/CustomTextFormatter/Text';
import UserExerciseList from '../../../../../components/UserExerciseList/UserExerciseList';
import { IPlansCardResponse } from '../../../../../common/interfaces/Plans';
import { muscleGroupSection, titleBarStyle } from './SelectExerciseToTrain.style';

interface Props {
    activePlan: IPlansCardResponse[] | any
    exercisesByDay: any
    onPlayClick: (id: string) => void
    session: string
    mg: string
}

export default function SelectExerciseToTrain({
  activePlan, exercisesByDay, onPlayClick, session, mg,
}: Props) {
  return (
    <>
      <TitleBar additionalStyle={titleBarStyle}>
        <Text type="h1" additionalStyle={muscleGroupSection}>
          {mg}
        </Text>
      </TitleBar>

      <UserExerciseList
        planId={activePlan?._id}
        exerciseList={exercisesByDay[session][mg]}
        training
        muscleGroup={mg}
        onPlayClick={onPlayClick}
      />
    </>
  );
}
