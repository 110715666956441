import React from 'react';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { convertHMS } from '../../../../../helper/utility';
import { IExerciseInPlan } from '../../../../../common/interfaces/Exercise';
import { Container } from './ExerciseTrainInfo.style';

interface Props {
    currentSet: number
    currentExercise: IExerciseInPlan | undefined
}

export default function ExerciseTrainInfo({
  currentSet, currentExercise,
}: Props) {
  return (
    <Container>
      <DetailsBox
        label="Serie"
      >
        <Text type="body1">
          {currentSet.toString()}
        </Text>
      </DetailsBox>
      <DetailsBox
        label="Ripetizioni"
      >
        <Text type="body1">
          {currentExercise?.setRep[currentSet - 1]?.rep.toString() || '1'}
        </Text>
      </DetailsBox>
      <DetailsBox label="Recupero ">
        <Text type="body1">
          {convertHMS(currentExercise?.recovery || 0)}
        </Text>
      </DetailsBox>
      <DetailsBox label="Tipo">
        <Text type="body1">
          {currentExercise?.setRep[currentSet - 1]?.type.toString() || 'Normal'}
        </Text>
      </DetailsBox>
      <DetailsBox label="Ultimo">
        <Text type="body1">
          {currentExercise?.loads?.sort((a, b) =>
            // @ts-ignore
            // eslint-disable-next-line implicit-arrow-linebreak
            new Date(a.date) - new Date(b.date))[0]?.set[currentSet - 1]?.loadInKg?.toString().concat(' kg') || 'N/D'}
        </Text>
      </DetailsBox>
    </Container>
  );
}
