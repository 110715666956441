import { ApolloError, gql, useQuery } from '@apollo/client';
import { IPlansCardWithStats } from '../../common/interfaces/Plans';

const GET_PLAN_WITH_STATS_BY_USER_AND_PLAN = gql`
    query plansByUserWithStats($idUser: ID!){
        plansByUserWithStats(idUser: $idUser) {
            plan {
                _id
                name
                startDate
                endDate
                days
                active
                exercise {
                    _id
                    exercise {
                        name
                        muscleGroups {
                            muscleGroup {
                                name
                            }
                        }
                        _id
                    }
                    loads{
                        date
                        set {
                            noSet
                            loadInKg
                        }
                    }
                    setRep {
                        set
                        rep
                        type
                    }
                    day
                    note
                }
                caption
            }
            planStats {
                muscleGroup
                percentage
                icon
            }
        }
    }
`;

// eslint-disable-next-line no-unused-vars
type ControllerFN = (planId: string | undefined, idUser: string | undefined) => {
  planByUserWithStats: IPlansCardWithStats;
  loading: boolean;
    error: ApolloError | undefined
};

export const useGetPlansWithStatsByUserIdAndPlanId: ControllerFN = (planId: string | undefined, idUser: string | undefined) => {
  const { data, loading, error } = useQuery(GET_PLAN_WITH_STATS_BY_USER_AND_PLAN, {
    variables: { idUser },
  });
  const planByUserWithStats = data?.plansByUserWithStats?.filter((plan: IPlansCardWithStats) => plan.plan._id === planId)[0];
  return { loading, planByUserWithStats, error };
};
