import React from 'react';
import {
  ArcElement, Chart as ChartJS, Legend, RadialLinearScale, Tooltip,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { PolarData } from './interfaces/Radar';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export default function PolarChart({ data } : { data: PolarData | Record<string, unknown>}) {
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'bottom' as const,
  //
  //       labels: {
  //         color: 'rgba(255, 255, 255, 1)',
  //         pointStyle: 'circle',
  //       },
  //     },
  //   },
  // };

  return (
  // @ts-ignore
    <PolarArea data={data} />
  );
}
