import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const DetailsBoxContainer = styled(Box)`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  flex-wrap: wrap;
`;

export const Scroller = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0.5rem 0;
  overflow: auto;
  gap: 1.5rem;
`;
