import React, { Dispatch, SetStateAction } from 'react';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import SendIcon from '@mui/icons-material/Send';
import Text from '../../../../../components/CustomTextFormatter/Text';
import ExerciseTrainActionButtons from '../ExerciseTrainActionButtons/ExerciseTrainActionButtons';
import IconButton from '../../../../../components/IconButton/IconButton';
import { iconStyle, TrainingIconsGroupContainer } from './TrainingIcons.style';

interface Props {
    exerciseCompleted: boolean
    recover: boolean
    setExpandExerciseList: Dispatch<SetStateAction<boolean>>
    onClickTimerIcon: () => void
    onClickStopTimer: () => void
    onClickSendLoads: () => void
    setOpenConfirmSkipDialog:Dispatch<SetStateAction<boolean>>
}

export default function TrainingIconsGroup({
  exerciseCompleted, recover, setExpandExerciseList, onClickTimerIcon, onClickStopTimer, onClickSendLoads, setOpenConfirmSkipDialog,
}: Props) {
  return (
    <TrainingIconsGroupContainer>
      {!exerciseCompleted && !recover && (
        <ExerciseTrainActionButtons
          setExpandExerciseList={setExpandExerciseList}
          onClickTimerIcon={onClickTimerIcon}
          setOpenConfirmSkipDialog={setOpenConfirmSkipDialog}
        />
      )}

      {recover && (
        <>
          <Text type="h1">Recupero!</Text>
          <IconButton onClick={onClickStopTimer}>
            <TimerOffIcon sx={iconStyle} />
          </IconButton>
        </>
      )}

      {exerciseCompleted && (
        <IconButton onClick={onClickSendLoads}>
          <SendIcon sx={iconStyle} />
        </IconButton>
      )}
    </TrainingIconsGroupContainer>
  );
}
