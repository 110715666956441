import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { IUser } from '../../../../common/interfaces/User';
import { useGetAthletesByPersonalTrainer } from '../../../../apollo/hooks/useGetAthletes';
import { AuthContext } from '../../../../context/auth';

type ControllerFN = () => {
    searching: string;
    onChange: (e: any) => void
    onChangeDate: (number: string) => void
    onClickSearchButton: () => void
    resetSearch: () => void
    userToDisplay: IUser[] | null
    startSearching: boolean
    loading: boolean
    selectedDay: string
    setStartSearching: Dispatch<SetStateAction<boolean>>
    apiError: string
    setApiError: Dispatch<SetStateAction<string>>
    openUserPopup: boolean,
    setOpenUserPopup: Dispatch<SetStateAction<boolean>>
    logout: () => void
};

const curr = new Date();

const PTHomePageController: ControllerFN = () => {
  const [apiError, setApiError] = useState<string>('');
  const [searching, setSearching] = useState<string>('');
  const [startSearching, setStartSearching] = useState<boolean>(false);
  const [openUserPopup, setOpenUserPopup] = useState<boolean>(false);
  const [userToDisplay, setUserToDisplay] = useState<IUser[]| null>(null);
  const [selectedDay, setSelectedDay] = useState<string>(curr.getUTCDate().toString());

  const context = useContext(AuthContext);

  const { athletes, loading, error } = useGetAthletesByPersonalTrainer();

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  const onChangeDate = (number: string) => {
    setSelectedDay(number);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
  };

  const onClickSearchButton = () => {
    setStartSearching(searching !== '');
    if (athletes) {
      setUserToDisplay(athletes.filter((user: IUser) => user?.name?.includes(searching)));
    }
  };

  const resetSearch = () => {
    setSearching('');
    setUserToDisplay([]);
    setStartSearching(false);
  };

  const logout = () => {
    context.logout();
  };

  return {
    apiError,
    setApiError,
    searching,
    onChange,
    resetSearch,
    userToDisplay,
    startSearching,
    onChangeDate,
    selectedDay,
    loading,
    setStartSearching,
    openUserPopup,
    setOpenUserPopup,
    logout,
    onClickSearchButton,
  };
};

export default PTHomePageController;
