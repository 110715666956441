import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  width: 100%;
  bottom: 0;
  border-radius: 2rem 2rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ExerciseInfoContainer = styled(Box)`
  display: flex; 
  justify-content: center;             
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

export const ExerciseCompletedContainer = styled(Box)`
  display: flex; 
  flex-direction: column;
  gap: 1rem;
`;

export const boldTextStyle = { fontWeight: 'bold' };
export const noteTextStyle = { fontWeight: 'bold', color: 'rgba(255,255,255,0.5)' };
