import React, { useContext } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { BackButtonContainer, iconStyle } from './BackButton.style';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { PageRenderingContext } from '../../../../context/pagerendering';

interface Props {
    indexPage: boolean, pt: boolean, specificPath?: string
}

export default function BackButton({ indexPage, pt, specificPath }:Props) {
  const navigate = useNavigate();
  const { HOME_PATH, HOME_PT_PATH } = PATH_CONSTANTS;
  // eslint-disable-next-line no-nested-ternary
  const navigateTo: string | number = specificPath || (indexPage ? pt ? HOME_PT_PATH : HOME_PATH : -1);
  const orientationContext = useContext(PageRenderingContext);
  return (
    <BackButtonContainer onClick={() => {
      orientationContext.changeOrientation('right');
      // @ts-ignore
      navigate(navigateTo);
    }}
    >
      <FontAwesomeIcon
        style={iconStyle}
        icon={faChevronLeft}
      />
    </BackButtonContainer>
  );
}
