import { ApolloError, gql, useQuery } from '@apollo/client';
import { IPhases } from '../../common/interfaces/TrainingPhases';

export const GET_TRAINING_PHASES_BY_ID = gql`
    query trainingPhaseById($idTrainingPhasesSet: ID!, $idTrainingPhase: ID!){
        trainingPhaseById(idTrainingPhasesSet: $idTrainingPhasesSet, idTrainingPhase: $idTrainingPhase) {
            _id,
            name,
            description,
            period,
            eta
        }
    }
`;

type ControllerFN = (idTrainingPhasesSet:string | undefined, idTrainingPhase:string | undefined) => {
    trainingPhaseById: IPhases;
    trainingPhaseLoading: boolean;
    error: ApolloError | undefined
};

export const useGetTrainingPhasesById: ControllerFN = (idTrainingPhasesSet, idTrainingPhase) => {
  const { data, loading, error } = useQuery(GET_TRAINING_PHASES_BY_ID, {
    variables: { idTrainingPhasesSet, idTrainingPhase },
  });

  const trainingPhaseById = data?.trainingPhaseById;
  const trainingPhaseLoading = loading;

  return { trainingPhaseLoading, trainingPhaseById, error };
};
