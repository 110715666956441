import React from 'react';
import { Scroller } from './DaySelectionCard.style';
import CustomCalendarIconLike from '../../../../../components/CustomCalendarIconLike/CustomCalendarIconLike';

interface IDaySelectionCard {
  reorderPlan: () => void
  weeklyTrainings: number[]
  setSelectedDay: (day: number) => void
  selectedDay: number
}

export default function DaySelectionCard({
  weeklyTrainings, setSelectedDay, selectedDay, reorderPlan,
} : IDaySelectionCard) {
  return (
    <Scroller>
      { weeklyTrainings.map((day) => (
        <CustomCalendarIconLike
          key={day}
          onClick={() => {
            reorderPlan();
            setSelectedDay(day);
          }}
          day={day.toString()}
          additionalStyle={{
            backgroundColor: selectedDay === day ? 'rgba(255,255,255,0.4)!important' : 'rgba(255,255,255,0.2)!important',
          }}
        />
      ))}
    </Scroller>
  );
}
