import { ApolloError, gql, useQuery } from '@apollo/client';
import { IExercise } from '../../common/interfaces/Exercise';

const GET_EXERCISES_BY_ATHLETE = gql`
    query exercisesByAthlete($ptId: ID!, $userId: ID!){
        exercisesByAthlete(ptId: $ptId, userId: $userId) {
            name
            _id
            muscleGroups{
                muscleGroup{
                    icon
                }
            }
        }
    }
`;

type ControllerFN = (userId: string, ptId: string) => {
    exercisesByAthlete: IExercise[];
    exerciseListLoading: boolean;
    error: ApolloError | undefined
};

export const useGetExerciseByAthlete: ControllerFN = (userId, ptId) => {
  const { data, loading, error } = useQuery(GET_EXERCISES_BY_ATHLETE, {
    variables: { ptId, userId },
  });
  const exercisesByAthlete = data?.exercisesByAthlete;
  const exerciseListLoading = loading;
  return { exerciseListLoading, exercisesByAthlete, error };
};
