import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ActionContent = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  color: #eee;
  user-select: none;
`;

export const ItemColumnCentered = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const selectExerciseCard = {
  width: '100%',
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
  overflow: 'auto',
  overflowX: 'hidden',
  height: '55vh',
  borderRadius: '0.5rem',
};

export const addedExerciseSelectionListItem = { height: '1rem', borderRadius: '1rem' };

export const deleteIconStyle = {
  color: '#F9F9F9',
};

export const deleteSwipeStyle = {
  background: 'linear-gradient(180deg, hsla(0, 86%, 66%, 1) 0%, hsla(0, 86%, 66%, 0.8) 100%)',
  height: '3rem',
  alignSelf: 'center',
  borderRadius: '0 1rem 1rem 0',
};

export const editSwipeStyle = {
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
  height: '3rem',
  alignSelf: 'center',
};

export const noExerciseFound = {
  margin: '1rem 0 1rem 0',
  fontStyle: 'italic',
};
