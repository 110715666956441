import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useNavigate } from 'react-router-dom';
import PlanCard from '../../../components/PlanCard/PlanCard';
import Layout from '../../../components/Layout/Layout';
import PtPlansController from './controller/ptPlans.controller';
import Pagebar from '../../../components/Pagebar/Pagebar';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { accordionStyle } from './PTPlans.style';
import Text from '../../../components/CustomTextFormatter/Text';
import { planNameStyle, StyledChip } from '../../../components/PlanCard/PlanCard.style';
import { PATH_CONSTANTS } from '../../../helper/constants';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import CustomButton from '../../../components/CustomButton/CustomButton';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import CustomSearchBar from '../../../components/SearchBar/SearchBar';

interface OwnProps {}

type Props = OwnProps;

const PTPlans: FunctionComponent<Props> = () => {
  const { userId } = useParams();

  const {
    plansToDisplay,
    loading,
    searching,
    onChange,
    resetSearch,
    apiError,
    setApiError,
    showDeleteConfirmPopup,
    setShowDeleteConfirmPopup,
    handleDeletePlan,
    setPlanToBeDeleted,
    onClickSearchButton,
  } = PtPlansController(userId);

  const navigate = useNavigate();

  const { ATHLETES_PT_PATH, EDIT_PLAN_PT_PATH } = PATH_CONSTANTS;

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} specificPath={`${ATHLETES_PT_PATH}/${userId}`} title="Tutti i piani" />

        <TitleBar additionalStyle={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
          <CustomSearchBar
            placeholder="Cerca piano"
            value={searching}
            onCancelSearch={resetSearch}
            onChange={onChange}
            onClick={onClickSearchButton}
            showCancelButton
          />
        </TitleBar>

        {plansToDisplay?.map((plan) => (
          <Accordion key={plan.plan._id} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TitleBar>
                <>
                  <Text type="h2" additionalStyle={planNameStyle}>
                    {plan.plan.name}
                  </Text>
                  {plan.plan.active && <StyledChip size="small" label="Attivo" />}
                </>
              </TitleBar>
            </AccordionSummary>
            <AccordionDetails>
              <PlanCard
                key={plan.plan._id}
                plan={plan}
                pt
                userId={userId}
                additionalCardStyle={{ background: 'rgba(255,255,255,0.3)' }}
              />
              <CustomButton
                additionalStyle={{ marginTop: '1rem' }}
                label="Modifica piano"
                onClick={() => navigate(`${EDIT_PLAN_PT_PATH}/${plan.plan._id}`)}
              />

              <CustomButton
                additionalStyle={{ marginTop: '1rem' }}
                label="Elimina piano"
                onClick={() => {
                  setPlanToBeDeleted(plan.plan._id);
                  setShowDeleteConfirmPopup(true);
                }}
              />
            </AccordionDetails>
          </Accordion>

        ))}
        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />

        <SlidingPopup
          handleAction={handleDeletePlan}
          open={showDeleteConfirmPopup}
          handleClose={() => setShowDeleteConfirmPopup(false)}
          actions
          negativeAction
          negativeActionLabel="No"
          actionLabel="Si"
          handleNegativeAction={() => setShowDeleteConfirmPopup(false)}
        >
          <>
            <Text type="body1">
              Elimina piano di allenamento
            </Text>
            <Text type="body2">
              Sei sicuro di voler eliminare questo piano?
            </Text>
          </>

        </SlidingPopup>
      </>

    </Layout>
  );
};

export default PTPlans;
