import React from 'react';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { ReactComponent as AbsIcon } from '../asset/icons/mg/abs.svg';
import { ReactComponent as BackIcon } from '../asset/icons/mg/back.svg';
import { ReactComponent as BicepsIcon } from '../asset/icons/mg/biceps.svg';
import { ReactComponent as CardioIcon } from '../asset/icons/mg/cardio.svg';
import { ReactComponent as ChestIcon } from '../asset/icons/mg/chest.svg';
import { ReactComponent as DorsalIcon } from '../asset/icons/mg/dorsal.svg';
import { ReactComponent as LegsIcon } from '../asset/icons/mg/legs.svg';
import { ReactComponent as TricepsIcon } from '../asset/icons/mg/triceps.svg';
import { MUSCLE_GROUPS_ICON } from '../helper/constants';

// eslint-disable-next-line no-unused-vars
type ControllerFN = (icon :string) => {
    selectedIcon: JSX.Element
};

const useGetMuscleGroupsIcons: ControllerFN = (icon :string) => {
  let selectedIcon = <FitnessCenterIcon sx={{ color: 'white' }} height={40} width={40} />;
  const {
    ABDOMINAL, BACK, CARDIO, CHEST, DORSAL, LEGS, TRICEPS, BICEPS,
  } = MUSCLE_GROUPS_ICON;
  switch (icon) {
    case DORSAL:
      selectedIcon = <DorsalIcon fill="white" height={30} width={30} />;
      break;
    case CARDIO:
      selectedIcon = <CardioIcon fill="white" height={30} width={30} />;
      break;
    case ABDOMINAL:
      selectedIcon = <AbsIcon fill="white" height={30} width={30} />;
      break;
    case BACK:
      selectedIcon = <BackIcon fill="white" height={30} width={30} />;
      break;
    case CHEST:
      selectedIcon = <ChestIcon fill="white" height={30} width={30} />;
      break;
    case LEGS:
      selectedIcon = <LegsIcon fill="white" height={30} width={30} />;
      break;
    case BICEPS:
      selectedIcon = <BicepsIcon fill="white" height={30} width={30} />;
      break;
    case TRICEPS:
      selectedIcon = <TricepsIcon fill="white" height={30} width={30} />;
      break;
    default:
      selectedIcon = <FitnessCenterIcon sx={{ color: 'white' }} height={40} width={40} />;
      break;
  }
  return { selectedIcon };
};

export default useGetMuscleGroupsIcons;
