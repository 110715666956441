import React, { FunctionComponent } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import {
  SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType,
} from 'react-swipeable-list';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import ListItem from '../../../components/ListItem/ListItem';
import Text from '../../../components/CustomTextFormatter/Text';
import PTMuscleGroupsController from './controller/PTMuscleGroupsPage.controller';
import { PATH_CONSTANTS } from '../../../helper/constants';
import {
  ActionContent,
  deleteIconStyle,
  deleteSwipeStyle,
  editSwipeStyle,
  IconContainer,
  ItemColumnCentered,
} from './PTMuscleGroups.style';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import { IMuscleGroup } from '../../../common/interfaces/MuscleGroup';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import { pageMainCard } from '../../../common/styles/commonComponents';
import CustomSearchBar from '../../../components/SearchBar/SearchBar';

interface OwnProps {}

type Props = OwnProps;

const PTMuscleGroups: FunctionComponent<Props> = () => {
  const {
    searching, onChange, resetSearch, muscleGroupsToDisplay,
    loading, apiError, setApiError, openDeletePopup, setOpenDeletePopup,
    onDeleteMuscleGroup, setMuscleGroupToBeHandled, onClickSearchButton,
  } = PTMuscleGroupsController();

  const navigate = useNavigate();

  const {
    MUSCLE_GROUPS_PT_PATH, ADD_MUSCLE_GROUP_PT_PATH, EDIT_MUSCLE_GROUP_PT_PATH,
  } = PATH_CONSTANTS;

  const trailingActions = (mg: IMuscleGroup) => (
    <TrailingActions>
      <SwipeAction onClick={() => navigate(`${EDIT_MUSCLE_GROUP_PT_PATH}/${mg._id}`)}>
        <ActionContent style={editSwipeStyle}>
          Edit
        </ActionContent>
      </SwipeAction>
      <SwipeAction
        onClick={() => {
          setMuscleGroupToBeHandled(mg);
          setOpenDeletePopup(true);
        }}
      >
        <ActionContent style={deleteSwipeStyle}>
          <ItemColumnCentered>
            <span className="icon">
              <DeleteOutlineIcon sx={deleteIconStyle} />
            </span>
          </ItemColumnCentered>
        </ActionContent>
      </SwipeAction>
    </TrailingActions>
  );

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar pt indexPage title="Gruppi muscolari" />

        <CustomSearchBar
          placeholder="Cerca gruppo muscolare"
          value={searching}
          onCancelSearch={resetSearch}
          onChange={onChange}
          onClick={onClickSearchButton}
          showCancelButton
        />

        <Card
          additionalStyle={pageMainCard}
        >
          <>
            <IconContainer
              onClick={() => navigate(`${ADD_MUSCLE_GROUP_PT_PATH}/`)}
            >
              <AddIcon style={{ color: 'white' }} />
            </IconContainer>
            <SwipeableList
              fullSwipe
              type={ListType.IOS}
            >
              {muscleGroupsToDisplay?.map((ex) => (
                <SwipeableListItem
                  key={ex._id}
                  trailingActions={trailingActions(ex)}
                >
                  <ListItem
                    key={ex._id}
                    avatarSource={ex?.icon || ''}
                    onClick={() => navigate(`${MUSCLE_GROUPS_PT_PATH}`)}
                    name={ex.name || 'Default'}
                  />
                </SwipeableListItem>
              ))}
            </SwipeableList>
            {muscleGroupsToDisplay?.length === 0 && (
            <Text
              additionalStyle={{ margin: '2rem' }}
              type="body1"
            >
              Non ci sono gruppi muscolari registrati. Puoi aggiungerne cliccando sul tasto + qui sopra.
            </Text>
            )}
          </>
        </Card>

        <SlidingPopup
          open={openDeletePopup}
          handleClose={() => onDeleteMuscleGroup()}
          actions
          negativeAction
          negativeActionLabel="No"
          actionLabel="Si"
          handleNegativeAction={() => setOpenDeletePopup(false)}
        >
          <>
            <Text type="body1">
              Elimina gruppo muscolare
            </Text>
            <Text type="body2">
              Sei sicuro di voler eliminare questo gruppo muscolare?
              Questo gruppo muscolare verrà eliminato da tutti gli esercizi in cui è utilizzato.
            </Text>
          </>

        </SlidingPopup>

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTMuscleGroups;
