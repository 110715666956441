import React from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ILineData } from './interfaces/Line';
import { ChartOpt } from '../../../common/interfaces/Chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default function LineChart({ data, options } : { data: ILineData | Record<string, unknown>, options?: ChartOpt }) {
  // @ts-ignore
  return <Line options={options} data={data} />;
}
