import { ApolloError, gql, useQuery } from '@apollo/client';
import { IMuscleGroup } from '../../common/interfaces/MuscleGroup';

const GET_MUSCLE_GROUP = gql`
    query muscleGroup($id: ID!){
        muscleGroup(id: $id) {
            name
            icon
        }
    }
`;

type ControllerFN = (muscleGroupId: string | undefined) => {
    muscleGroup: IMuscleGroup;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetMuscleGroup: ControllerFN = (muscleGroupId: string | undefined) => {
  const { data, loading, error } = useQuery(GET_MUSCLE_GROUP, {
    variables: { id: muscleGroupId },
  });
  const muscleGroup = data?.muscleGroup;
  return { loading, muscleGroup, error };
};
