import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ButtonContainerRow = styled(Box)`
  width: 100%;
  display: flex;
  gap: 1.5rem;
`;

export const shortcutsButton = {
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
  width: '50%',
  height: '7.5rem',
  borderRadius: '1rem!important',
};
