import React from 'react';
import { StyledButton } from './CustomButton.style';
import theme from '../../helper/theme';
import Text from '../CustomTextFormatter/Text';

interface Props {
  additionalStyle?: Record<string, unknown>,
  icon?: JSX.Element,
  label: string,
  onClick?: () => void
  disabled?: boolean
  type?: string
}

export default function CustomButton({
  additionalStyle, icon, label, onClick, disabled, type,
}: Props) {
  return (
    // @ts-ignore
    <StyledButton type={type || ''} sx={additionalStyle} theme={theme} variant="contained" endIcon={icon} onClick={onClick} disabled={disabled}>
      <Text type="body1">
        {label}
      </Text>
    </StyledButton>
  );
}
