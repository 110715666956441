import { ApolloError, gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { IMuscleGroup } from '../../common/interfaces/MuscleGroup';
import { AuthContext } from '../../context/auth';

export const GET_MUSCLE_GROUPS_BY_PT = gql`
    query muscleGroupsByPtId($ptId: ID!){
        muscleGroupsByPtId(ptId: $ptId) {
            _id,
            name,
            icon
        }
    }
`;

type ControllerFN = () => {
    muscleGroupsByPtId: IMuscleGroup[];
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetMuscleGroupsByPt: ControllerFN = () => {
  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  const { data, loading, error } = useQuery(GET_MUSCLE_GROUPS_BY_PT, {
    variables: { ptId: id },
  });
  const muscleGroupsByPtId = data?.muscleGroupsByPtId;
  return { loading, muscleGroupsByPtId, error };
};
