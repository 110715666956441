import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const muscleGroupInfoCard = {
  background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
  width: '100%',
  borderRadius: '1rem!important',
  height: '80vh',
};

export const IconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const iconButtonStyle = {
  backgroundColor: 'rgba(255,255,255,0.2)',
  height: '55px',
  width: '40px',
  color: ' white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1rem',
};
