import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { AuthContext } from '../../../../context/auth';
import { useGetTrainingPhasesByPtId } from '../../../../apollo/hooks/useGetTrainingPhasesByPtId';
import { ADD_TRAINING_PHASE } from '../../../../apollo/mutations/addTrainingPhase';
import { UPDATE_TRAINING_PHASE } from '../../../../apollo/mutations/updateTrainingPhase';
import { IFormFields } from '../../../../components/StateControlledForm/interfaces/Form';

type ControllerFN = () => {
  onSaveTrainingPhase: ()=>void,
  addTrainingPhaseForm: IFormFields[]
  onChangeEta: (value: number) => void
  formError: string
  setFormError: Dispatch<SetStateAction<string>>
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PTAddTrainingPhaseController: ControllerFN = () => {
  const { PHASES_PT_PATH } = PATH_CONSTANTS;

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [period, setPeriod] = useState<string>('');
  const [eta, setEta] = useState<number>(1);
  const [formError, setFormError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<string>('');

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const { trainingPhasesLoading, trainingPhases } = useGetTrainingPhasesByPtId(id);

  const [addTrainingPhase, { loading: addTrainingPhaseLoading, error: addTrainingPhaseError }] = useMutation(ADD_TRAINING_PHASE);
  const [updateTrainingPhase, { loading: updateTrainingPhaseLoading, error: updateTrainingPhaseError }] = useMutation(UPDATE_TRAINING_PHASE);

  useEffect(() => {
    if (addTrainingPhaseError) {
      setApiError(addTrainingPhaseError.message);
    } else if (updateTrainingPhaseError) {
      setApiError(updateTrainingPhaseError.message);
    } else {
      setApiError('');
    }
  }, [addTrainingPhaseError, updateTrainingPhaseError]);

  useEffect(() => {
    const calculateLoadingValue = updateTrainingPhaseLoading && addTrainingPhaseLoading && trainingPhasesLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addTrainingPhaseLoading, updateTrainingPhaseLoading]);

  const onChangeEta = (value: number) => {
    if (!Number.isNaN(value)) {
      setEta(value);
    }
  };

  const addTrainingPhaseForm: IFormFields[] = [
    {
      label: 'Nome',
      placeholder: 'Fase 1',
      type: 'text',
      value: name,
      fieldType: 'TextField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value),
    },
    {
      label: 'Descrizione',
      placeholder: 'Carico progressivo',
      type: 'text',
      value: description,
      fieldType: 'TextField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value),
    },
    {
      label: 'Periodo',
      placeholder: 'Competitivo',
      type: 'text',
      value: period,
      fieldType: 'TextField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPeriod(e.target.value),
    },
  ];

  const onSaveTrainingPhase = () => {
    if (name !== '' && period !== '' && description !== '') {
      const newPhase = {
        name,
        description,
        period,
        eta,
      };
      if (trainingPhases?.phases && trainingPhases?.phases?.length > 0) {
        const phases = [...trainingPhases.phases.map((tp) => ({
          name: tp.name,
          description: tp.description,
          period: tp.period,
          eta: tp.eta,
        })), newPhase];
        const phaseInput = {
          phases,
          pt: id,
        };
        updateTrainingPhase({
          variables: {
            phaseInput,
            idPhase: trainingPhases._id,
          },
        }).then(() => {
          navigate(`${PHASES_PT_PATH}/`);
        });
      } else {
        const phaseInput = {
          phases: [newPhase],
          pt: id,
        };
        addTrainingPhase({
          variables: {
            phaseInput,
          },
        }).then(() => {
          navigate(`${PHASES_PT_PATH}/`);
        });
      }
    } else {
      setFormError('You need to fill all the fields before continue');
    }
  };

  return {
    loading,
    setApiError,
    apiError,
    setFormError,
    formError,
    name,
    setName,
    onSaveTrainingPhase,
    addTrainingPhaseForm,
    onChangeEta,
  };
};

export default PTAddTrainingPhaseController;
