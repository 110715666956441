import { Dayjs } from 'dayjs';
import { IAthleteState } from '../interfaces/athlete.form';

type InfoAthleteAction =
    { type: 'setEmail'; payload: string }
    | { type: 'setPassword'; payload: string }
    | { type: 'setName'; payload: string }
    | { type: 'setAge'; payload: string }
    | { type: 'setStartDate'; payload: Dayjs | null }
    | { type: 'setErrorEmail'; payload: string }
    | { type: 'setErrorPassword'; payload: string }
    | { type: 'setErrorName'; payload: string }
    | { type: 'setErrorAge'; payload: string }
    | { type: 'setErrorStartDate'; payload: Dayjs | null }

export const infoAthleteReducer = (state: IAthleteState, action: InfoAthleteAction) => {
  switch (action.type) {
    case 'setEmail': {
      return {
        ...state,
        email: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setPassword': {
      return {
        ...state,
        password: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setName': {
      return {
        ...state,
        name: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setAge': {
      return {
        ...state,
        age: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setStartDate': {
      return {
        ...state,
        startDate: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setErrorEmail': {
      return {
        ...state,
        email: {
          ...state.email,
          error: action.payload,
        },
      };
    }
    case 'setErrorPassword': {
      return {
        ...state,
        password: {
          ...state.password,
          error: action.payload,
        },
      };
    }
    case 'setErrorName': {
      return {
        ...state,
        name: {
          ...state.name,
          error: action.payload,
        },
      };
    }
    case 'setErrorAge': {
      return {
        ...state,
        age: {
          ...state.age,
          error: action.payload,
        },
      };
    }
    case 'setErrorStartDate': {
      return {
        ...state,
        startDate: {
          ...state.startDate,
          error: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
