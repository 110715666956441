import { ApolloError, gql, useQuery } from '@apollo/client';
import { IPlansCardResponse } from '../../common/interfaces/Plans';

const GET_ACTIVE_PLAN = gql`
    query activePlan($idUser: ID!){
        activePlan(idUser: $idUser) {
            _id
            name
            startDate
            endDate
            active
            lastTrainingDate
            lastTrainingNote
            lastTrainingRating
            exercise {
                _id
                recovery
                exercise{
                    _id
                    name
                    howTo
                    muscleGroups{
                        _id
                        muscleGroup{
                            name
                            icon
                        }
                        percentage
                    }
                }
                loads{
                    date
                    set {
                        noSet
                        loadInKg
                    }
                }
                setRep {
                    set
                    rep
                    type
                }
                day
                note
            }
            phase
            owner {
                _id
            }
            days
            caption
            volumes {
                trainingSets
                volume
                muscleGroup {
                    _id
                }
            }
        }
    }
`;

type ControllerFN = (idUser: string | undefined) => {
  activePlan: IPlansCardResponse | undefined;
  loading: boolean;
  error: ApolloError | undefined
};

export const useGetActiveUserPlan: ControllerFN = (idUser: string | undefined) => {
  const { data, loading, error } = useQuery(GET_ACTIVE_PLAN, {
    variables: { idUser },
  });
  const activePlan = data?.activePlan;
  return { loading, activePlan, error };
};
