import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import ExerciseController from './controller/exercise.controller';
import ExerciseCard from '../../../components/ExerciseCard/ExerciseCard';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Text from '../../../components/CustomTextFormatter/Text';
import { accordionStyle } from './Exercise.style';

interface OwnProps {}

type Props = OwnProps;

const Exercise: FunctionComponent<Props> = () => {
  const { exerciseId } = useParams();
  const {
    exercise, loading, apiError, setApiError,
  } = ExerciseController(exerciseId);

  if (loading) return (<LoaderSpinner />);

  return (
    <Layout>
      <>
        {exercise && (
          <>
            <Pagebar indexPage={false} title={exercise?.name || 'Dettagli esercizio'} />

            <Accordion defaultChecked sx={accordionStyle}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <TitleBar>
                  <Text type="h2">
                    Gruppi Muscolari
                  </Text>
                </TitleBar>
              </AccordionSummary>
              <AccordionDetails>
                <ExerciseCard type="polar" exercise={exercise} />
              </AccordionDetails>
            </Accordion>

            <Accordion sx={accordionStyle}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <TitleBar>
                  <Text type="h2">
                    Istruzioni
                  </Text>
                </TitleBar>
              </AccordionSummary>
              <AccordionDetails>
                <ExerciseCard type="howTo" exercise={exercise} />
              </AccordionDetails>
            </Accordion>

            <Accordion sx={accordionStyle}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <TitleBar>
                  <Text type="h2">
                    Video
                  </Text>
                </TitleBar>
              </AccordionSummary>
              <AccordionDetails>
                <ExerciseCard type="video" exercise={exercise} />
              </AccordionDetails>
            </Accordion>

          </>
        )}
        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default Exercise;
