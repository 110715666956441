import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 10vh;
`;

export const FormContainer = styled(Box)`
  width: 70%;
`;

export const typographyStyle = { color: 'white', fontSize: '3rem' };
