import { styled } from '@mui/system';
import { Chip } from '@mui/material';
import { PALETTE } from '../../helper/constants';

const { WHITEISH } = PALETTE;

export const StyledChip = styled(Chip)`
  background-color: #F9F9F9;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.37;
  font-family: 'Montserrat', sans-serif;
  color: #2a333c;
  width: fit-content;
  `;

export const cardStyle = {
  boxShadow: '0.31rem 0.31rem 0.25rem rgba(0, 0, 0, 0.08)',
  borderRadius: '1rem!important',
  overflow: 'auto',
  background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
};

export const chartCard = {
  backgroundColor: 'transparent!important',
  alignSelf: 'flex-end',
  width: '100%',
  boxShadow: '0px 0px 0px 0px!important',
  display: 'flex',
  justifyContent: 'center',
};

export const cardContentStyle = {
  paddingBottom: '0!important',
  padding: 0,
  width: '100%',
};

export const planNameStyle = { flexGrow: 1 };
export const validityRangeStyle = { color: WHITEISH, zIndex: 1 };
export const planCaption = {
  color: WHITEISH, zIndex: 1, letterSpacing: '-1px',
};
