import React, { FunctionComponent } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import {
  SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType,
} from 'react-swipeable-list';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Layout from '../../../components/Layout/Layout';
import PTPhasesController from './controller/PTPhasesPage.controller';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import Text from '../../../components/CustomTextFormatter/Text';
import { PATH_CONSTANTS } from '../../../helper/constants';
import {
  ActionContent,
  AddIconContainer,
  deleteIconStyle,
  deleteSwipeStyle,
  editSwipeStyle,
  ItemColumnCentered,
} from './PTPhasesPage.style';
import PhaseItem from './component/PhaseItem/PhaseItem';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import { IPhases } from '../../../common/interfaces/TrainingPhases';
import { pageMainCard } from '../../../common/styles/commonComponents';
import CustomSearchBar from '../../../components/SearchBar/SearchBar';

interface OwnProps {}

type Props = OwnProps;

const PTPhases: FunctionComponent<Props> = () => {
  const {
    searching, onChange, resetSearch, trainingPhaseToDisplay, loading, apiError,
    setApiError, openDeletePopup,
    onClickSearchButton, setOpenDeletePopup, onDeleteTrainingPhase, setTrainingPhaseToBeHandled,
  } = PTPhasesController();

  const navigate = useNavigate();

  const {
    ADD_TRAINING_PHASE_PT_PATH, EDIT_TRAINING_PHASE_PT_PATH,
  } = PATH_CONSTANTS;

  const trailingActions = (phase: IPhases) => (
    <TrailingActions>
      <SwipeAction onClick={() => navigate(`${EDIT_TRAINING_PHASE_PT_PATH}/${phase._id}`)}>
        <ActionContent style={editSwipeStyle}>
          Edit
        </ActionContent>
      </SwipeAction>
      <SwipeAction
        onClick={() => {
          setTrainingPhaseToBeHandled(phase);
          setOpenDeletePopup(true);
        }}
      >
        <ActionContent style={deleteSwipeStyle}>
          <ItemColumnCentered>
            <span className="icon">
              <DeleteOutlineIcon sx={deleteIconStyle} />
            </span>
          </ItemColumnCentered>
        </ActionContent>
      </SwipeAction>
    </TrailingActions>
  );

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar pt indexPage title="Fasi di allenamento" />

        <CustomSearchBar
          placeholder="Cerca fase di allenamento"
          value={searching}
          onCancelSearch={resetSearch}
          onChange={onChange}
          onClick={onClickSearchButton}
          showCancelButton
        />

        <Card
          additionalStyle={pageMainCard}
        >
          <>
            <AddIconContainer
              onClick={() => navigate(`${ADD_TRAINING_PHASE_PT_PATH}/`)}
            >
              <AddIcon style={{ color: 'white' }} />
            </AddIconContainer>
            <SwipeableList
              fullSwipe
              type={ListType.IOS}
            >
              {trainingPhaseToDisplay?.map(({
                name, description, period, eta, _id,
              }) => (
                <SwipeableListItem
                  key={name}
                  trailingActions={trailingActions({
                    name, description, period, eta, _id,
                  })}
                >
                  <PhaseItem
                    key={name}
                    name={name}
                    description={description}
                    period={period}
                    eta={`${eta} sett.`}
                  />
                </SwipeableListItem>
              ))}
            </SwipeableList>

            {trainingPhaseToDisplay.length === 0 && (
            <Text
              additionalStyle={{ margin: '2rem' }}
              type="body1"
            >
              Non ci sono fasi di allenamento registrate. Puoi aggiungerne una cliccando sul tasto + qui sopra.
            </Text>
            )}
          </>
        </Card>

        <SlidingPopup
          open={openDeletePopup}
          handleClose={() => onDeleteTrainingPhase()}
          actions
          negativeAction
          negativeActionLabel="No"
          actionLabel="Si"
          handleNegativeAction={() => setOpenDeletePopup(false)}
        >
          <>
            <Text type="body1">
              Elimina fase di allenamento
            </Text>
            <Text type="body2">
              Sei sicuro di voler eliminare questa fase di allenamento?
              Tutti i piani registati con questa fase di allenamento verranno impostati a &quot;Nessuna fase&quot;.
            </Text>
          </>

        </SlidingPopup>

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTPhases;
