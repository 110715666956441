import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const buttonStyle = {
  borderRadius: '2rem',
  padding: '0.5rem',
  color: 'rgb(42, 51, 60)',
  width: '100%',
  textAlign: 'center',
};

export const ButtonContainer = styled(Box)`
  display: flex;
  gap: 1rem;
  width: 80%;
  position: absolute;
  bottom: 2rem;
`;
