import React from 'react';
import TitleBar from '../TitleBar/TitleBar';
import Text from '../CustomTextFormatter/Text';
import Card from '../Card/Card';
import {
  cardStyle, chartNameStyle, StatContainer, titleBarStyle,
} from './StatsCard.style';

export default function StatsCard({ children, statName, additionalCardStyle } : { children: JSX.Element,
    statName: string, additionalCardStyle?: Record<string, unknown>}) {
  return (
    <Card additionalStyle={{ ...cardStyle, ...additionalCardStyle }}>
      <StatContainer>
        <TitleBar additionalStyle={titleBarStyle}>
          <Text type="h2" additionalStyle={chartNameStyle}>
            {statName}
          </Text>
        </TitleBar>
        {children}
      </StatContainer>
    </Card>

  );
}
