import React, { FunctionComponent, useState } from 'react';
import { Box } from '@mui/material';
import Card from '../../../../../components/Card/Card';
import { IVolumes } from '../../../../../common/interfaces/Plans';
import Divider from '../../../../../components/Divider/Divider';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { IconContainer, titleBarStyle } from '../../PTEditPlan.style';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import ExerciseList, { ICirceSliderValues } from './components/ExerciseList/ExerciseList';
import DaySelectionCard from '../DaySelectionCard/DaySelectionCard';
import { pageMainCard } from '../../../../../common/styles/commonComponents';

interface OwnProps {
  onSetVolumes: (volumes: IVolumes[]) => void
  exercisesByDayByMuscleGroup: any
  handleChangePage: (direction: string) => void
  selectedDay: number
  reorderPlan: () => void
  onChangeSelectedDay: (day: number) => void
  weeklyTrainings: number[]
}

type Props = OwnProps;

const TrainingSets: FunctionComponent<Props> = ({
  exercisesByDayByMuscleGroup, onSetVolumes, handleChangePage, selectedDay,
  reorderPlan, onChangeSelectedDay, weeklyTrainings,
}) => {
  const [trainingSetsDraft, setTrainingSetsDraft] = useState<ICirceSliderValues[]>([]);

  const onClickConfirmTrainingSets = () => {
    const tsDraft = [...trainingSetsDraft];
    const obj: any = {};
    tsDraft.forEach(({ muscleGroup, value }) => {
      const { name: muscleGroupName } = muscleGroup;
      if (obj[muscleGroupName] !== undefined) {
        obj[muscleGroupName] = {
          ...obj[muscleGroupName],
          value: obj[muscleGroupName].value + value,
        };
      } else {
        obj[muscleGroupName] = {
          muscleGroup: muscleGroup._id,
          value,
        };
      }
    });
    const trainingSets: IVolumes[] = Object.keys(obj).map((mg) => ({
      muscleGroup: {
        _id: obj[mg].muscleGroup,
        name: mg,
      },
      trainingSets: obj[mg].value,
      volume: 0,
    }));
    onSetVolumes(trainingSets);
  };

  const onSelectTrainingSets = (values: ICirceSliderValues[]) => {
    const tsDraft = [...trainingSetsDraft];

    values.forEach((newTrainingSet) => {
      const exerciseExists = tsDraft.findIndex((ets) => ets.exercise === newTrainingSet.exercise);
      if (exerciseExists !== -1) {
        tsDraft[exerciseExists] = newTrainingSet;
      } else {
        tsDraft.push(newTrainingSet);
      }
    });
    setTrainingSetsDraft(tsDraft);
  };

  return (
    <Card
      additionalStyle={pageMainCard}
    >
      <>

        <TitleBar>
          <Text type="h2">Scegli serie allenanti</Text>
        </TitleBar>

        <Divider />

        <Box sx={{
          flexGrow: 1, height: '60vh', overflow: 'auto', display: 'flex', gap: '1rem', flexDirection: 'column',
        }}
        >
          <Card
            additionalStyle={{
              display: 'flex',
              width: '100%',
              backgroundColor: 'rgba(255,255,255,0.2)!important',
              borderRadius: '1rem',
              minHeight: '4rem',
              alignItems: 'center',
            }}
          >
            <DaySelectionCard
              weeklyTrainings={weeklyTrainings}
              setSelectedDay={onChangeSelectedDay}
              reorderPlan={reorderPlan}
              selectedDay={selectedDay}
            />
          </Card>

          {exercisesByDayByMuscleGroup && Object.keys(exercisesByDayByMuscleGroup)?.map((mg: string) => (

            <Box sx={{ width: '100%' }}>
              <TitleBar additionalStyle={titleBarStyle}>
                <Text type="h2">
                  {// @ts-ignore
                        mg || 'Undefined'
                    }
                </Text>
              </TitleBar>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ExerciseList
                  exerciseList={exercisesByDayByMuscleGroup[mg]}
                  onSelectTrainingSets={onSelectTrainingSets}
                />
              </Box>
            </Box>
          ))}

        </Box>

        <Divider />

        <IconContainer>
          <CustomButton
            onClick={() => handleChangePage('back')}
            label="Indietro"
          />
          <CustomButton
            onClick={() => {
              onClickConfirmTrainingSets();
            }}
            label="Avanti"
          />
        </IconContainer>

      </>

    </Card>
  );
};

export default TrainingSets;
