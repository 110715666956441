/* eslint-disable no-return-assign,no-unused-vars */
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import '../navbar.css';
import { clsx } from 'clsx';
import HomeIcon from '@mui/icons-material/Home';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStyles } from '../Navbar.style';
import { PATH_CONSTANTS } from '../../../helper/constants';
import { AuthContext } from '../../../context/auth';
import { ReactComponent as AthleteIcon } from '../../../asset/icons/athlete.svg';
import { ReactComponent as ExercisesIcon } from '../../../asset/icons/exercise.svg';
import { ReactComponent as MuscleGroupsIcon } from '../../../asset/icons/musclegroups.svg';
import { ReactComponent as PhasesIcon } from '../../../asset/icons/phases.svg';
import { ReactComponent as PlansIcon } from '../../../asset/icons/plans.svg';
import { ReactComponent as ReportsIcon } from '../../../asset/icons/reports.svg';

interface MenuItem{
  icon: JSX.Element
  style: any
  path: string
  id: number
  ref: any
}

export default function NavbarController() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number>(2);
  const [hideNavbar, setHideNavbar] = useState<boolean>(false);
  const menuRef = useRef();
  const menuBorder = useRef();
  const menuItem = useRef<any>([]);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split('/');
    if ((path[1] === 'training' && path[2])
        || path[1] === 'login' || path[1] === '') {
      setHideNavbar(true);
    } else {
      setHideNavbar(false);
    }
  }, [location.pathname]);

  function offsetMenuBorder(element: any) {
    const offsetActiveItem = element?.getBoundingClientRect();
    if (offsetActiveItem) {
      // @ts-ignore
      // eslint-disable-next-line max-len
      const left = `${Math.floor(offsetActiveItem.left - menuRef.current.offsetLeft - (menuBorder.current.offsetWidth - offsetActiveItem.width) / 2)}px`;
      // eslint-disable-next-line no-param-reassign
      if (menuBorder?.current) {
        // @ts-ignore
        menuBorder.current.style.transform = `translate3d(${left}, 0 , 0)`;
      }
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (menuItem.current && menuItem?.current[2] && selected === 2) {
      // @ts-ignore
      offsetMenuBorder(menuItem.current[2]);
    }
  }, [menuItem.current]);

  const onClickButton = (index: number, path: string) => {
    // @ts-ignore
    offsetMenuBorder(menuItem.current[index]);
    setSelected(index);
    navigate(path);
  };

  const {
    EXERCISES_PT_PATH, HOME_PT_PATH, ATHLETES_PT_PATH, MUSCLE_GROUPS_PT_PATH,
    HOME_PATH, PLANS_PATH, REPORTS_PATH, TRAINING_PATH, PROFILE_PATH, PHASES_PT_PATH,
  } = PATH_CONSTANTS;

  const classes = useStyles();

  const menuItemsPt = useMemo(() => [
    {
      id: 0,
      icon: <ExercisesIcon className={classes.customNavbarIcon} style={{ stroke: selected === 0 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 0,
      }),
      path: EXERCISES_PT_PATH,
      ref: menuItem.current[0],
    },
    {
      id: 1,
      icon: <MuscleGroupsIcon className={classes.customNavbarIcon} style={{ stroke: selected === 1 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 1,
      }),
      path: MUSCLE_GROUPS_PT_PATH,
      ref: menuItem.current[1],
    },
    {
      id: 2,
      icon: <HomeIcon className={classes.navbarIcon} style={{ stroke: selected === 2 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 2,
      }),
      path: HOME_PT_PATH,
      ref: menuItem.current[2],
    },
    {
      id: 3,
      icon: <AthleteIcon className={classes.customNavbarIcon} style={{ stroke: selected === 3 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 3,
      }),
      path: ATHLETES_PT_PATH,
      ref: menuItem.current[3],
    },
    {
      id: 4,
      icon: <PhasesIcon className={classes.customNavbarIcon} style={{ stroke: selected === 4 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 4,
      }),
      path: PHASES_PT_PATH,
      ref: menuItem.current[4],
    },
  ], [menuItem, selected]);

  const menuItemsAthlete = useMemo(() => [
    {
      id: 0,
      icon: <PlansIcon className={classes.customNavbarIcon} style={{ stroke: selected === 0 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 0,
      }),
      path: PLANS_PATH,
      ref: menuItem.current[0],
    },
    {
      id: 1,
      icon: <ReportsIcon className={classes.customNavbarIcon} style={{ stroke: selected === 1 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 1,
      }),
      path: REPORTS_PATH,
      ref: menuItem.current[1],
    },
    {
      id: 2,
      icon: <HomeIcon className={classes.navbarIcon} sx={{ stroke: selected === 2 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 2,
      }),
      path: HOME_PATH,
      ref: menuItem.current[2],
    },
    {
      id: 3,
      icon: <SportsKabaddiIcon className={classes.navbarIcon} style={{ stroke: selected === 3 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 3,
      }),
      path: TRAINING_PATH,
      ref: menuItem.current[3],
    },
    {
      id: 4,
      icon: <AccessibilityNewIcon className={classes.navbarIcon} style={{ stroke: selected === 4 ? 'white' : 'grey' }} />,
      style: clsx({
        [classes.menuItem]: true, // always applies
        [classes.menuItemActive]: selected === 4,
      }),
      path: PROFILE_PATH,
      ref: menuItem.current[4],
    },
  ], [menuItem, selected]);

  const [menuItems, setMenuItems] = useState<MenuItem[]>(menuItemsPt);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    let findIn: MenuItem[] = [];
    // @ts-ignore
    if (user?.role === 'PT') {
      findIn = menuItemsPt;
    } else {
      findIn = menuItemsAthlete;
    }
    const found = findIn.find((it) => it.path === location.pathname);
    if (found) {
      offsetMenuBorder(menuItem.current[found.id]);
      setSelected(found.id);
    }
  }, [location]);

  useEffect(() => {
    // @ts-ignore
    if (user?.role === 'PT') {
      setMenuItems(menuItemsPt);
    } else {
      setMenuItems(menuItemsAthlete);
    }
  }, [selected, user]);

  return {
    menuItems, menuRef, menuItem, onClickButton, menuBorder, hideNavbar,
  };
}
