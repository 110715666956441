import React, { FunctionComponent } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import {
  SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType,
} from 'react-swipeable-list';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Layout from '../../../components/Layout/Layout';
import PTAthletesController from './controller/PTAthletesPage.controller';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import ListItem from '../../../components/ListItem/ListItem';
import Text from '../../../components/CustomTextFormatter/Text';
import { PATH_CONSTANTS } from '../../../helper/constants';
import { AddIconContainer } from './PTAthletesPage.style';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import {
  ActionContent,
  deleteIconStyle,
  deleteSwipeStyle,
  editSwipeStyle,
  ItemColumnCentered,
} from '../PTMuscleGroupsPage/PTMuscleGroups.style';
import { IUser } from '../../../common/interfaces/User';
import { pageMainCard } from '../../../common/styles/commonComponents';
import CustomSearchBar from '../../../components/SearchBar/SearchBar';

interface OwnProps {}

type Props = OwnProps;

const PTAthletes: FunctionComponent<Props> = () => {
  const {
    searching, onChange, resetSearch, usersToDisplay, loading, apiError, setApiError,
    openDeletePopup, setOpenDeletePopup,
    onDeleteAthlete, setAthleteToBeHandled, onClickSearchButton,
  } = PTAthletesController();

  const {
    ATHLETES_PT_PATH, ADD_ATHLETE_PT_PATH, EDIT_ATHLETE_PT_PATH,
  } = PATH_CONSTANTS;

  const navigate = useNavigate();

  const trailingActions = (user: IUser) => (
    <TrailingActions>
      <SwipeAction onClick={() => navigate(`${EDIT_ATHLETE_PT_PATH}/${user._id}`)}>
        <ActionContent style={editSwipeStyle}>
          Edit
        </ActionContent>
      </SwipeAction>
      <SwipeAction
        onClick={() => {
          setAthleteToBeHandled(user);
          setOpenDeletePopup(true);
        }}
      >
        <ActionContent style={deleteSwipeStyle}>
          <ItemColumnCentered>
            <span className="icon">
              <DeleteOutlineIcon sx={deleteIconStyle} />
            </span>
          </ItemColumnCentered>
        </ActionContent>
      </SwipeAction>
    </TrailingActions>
  );

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar pt indexPage title="Atleti" />

        <CustomSearchBar
          placeholder="Cerca atleta"
          value={searching}
          onCancelSearch={resetSearch}
          onChange={onChange}
          onClick={onClickSearchButton}
          showCancelButton
        />

        <Card
          additionalStyle={pageMainCard}
        >
          <>
            <AddIconContainer
              onClick={() => navigate(`${ADD_ATHLETE_PT_PATH}/`)}
            >
              <AddIcon style={{ color: 'white' }} />
            </AddIconContainer>
            <SwipeableList
              fullSwipe
              type={ListType.IOS}
            >
              {usersToDisplay?.map((user) => (
                <SwipeableListItem
                  key={user._id}
                  trailingActions={trailingActions(user)}
                >
                  <ListItem
                    key={user._id}
                    onClick={() => navigate(`${ATHLETES_PT_PATH}/${user._id}`)}
                    name={user.name || 'Default'}
                    age={user.age}
                    plansNumber={user.plans?.length?.toString()}
                  />
                </SwipeableListItem>
              ))}
            </SwipeableList>
            {usersToDisplay?.length === 0 && (
            <Text
              additionalStyle={{ margin: '2rem' }}
              type="body1"
            >
              Non ci sono atleti registrati. Aggiungine uno cliccando sul tasto + qui sopra.
            </Text>
            )}
          </>
        </Card>

        <SlidingPopup
          open={openDeletePopup}
          handleClose={() => onDeleteAthlete()}
          actions
          negativeAction
          negativeActionLabel="No"
          actionLabel="Si"
          handleNegativeAction={() => setOpenDeletePopup(false)}
        >
          <>
            <Text type="body1">
              Elimina atleta
            </Text>
            <Text type="body2">
              Sei sicuro di voler eliminare questo atleta? Tutti i suoi piani e le sue statistiche verranno eliminati.
            </Text>
          </>

        </SlidingPopup>

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTAthletes;
