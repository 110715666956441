import * as React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/system';
import { useSwitch, UseSwitchParameters } from '@mui/base/SwitchUnstyled';
import { Box } from '@mui/material';
import Text from '../CustomTextFormatter/Text';

const orangeish = {
  500: '#fe5f55',
};

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#6F7E8C',
};

const BasicSwitchRoot = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 10px;
  background: ${theme.palette.mode === 'dark' ? grey[600] : grey[400]};
  border-radius: 10px;
  cursor: pointer;

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    background: ${orangeish[500]};
  }
  `,
);

const BasicSwitchInput = styled('input')`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const BasicSwitchThumb = styled('span')`
  display: block;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 200ms ease;

  &.Switch-focusVisible {
    background-color: #f9f9f9;
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.Switch-checked {
    left: 22px;
    top: 3px;
    background-color: #fff;
  }
`;

function BasicSwitch(props: UseSwitchParameters) {
  const {
    getInputProps, checked, disabled, focusVisible,
  } = useSwitch(props);

  const stateClasses = {
    'Switch-checked': checked,
    'Switch-disabled': disabled,
    'Switch-focusVisible': focusVisible,
  };

  return (
    <BasicSwitchRoot className={clsx(stateClasses)}>
      <BasicSwitchThumb className={clsx(stateClasses)} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <BasicSwitchInput {...getInputProps()} aria-label="Switch" />
    </BasicSwitchRoot>
  );
}

export default function SwitchButton({
  onChange, checked, labelChecked, labelUnchecked, additionalStyle,
} : {onChange: () => void, checked: boolean, labelChecked: string, labelUnchecked: string, additionalStyle?: Record<string, unknown>}) {
  return (
    <Box style={
        {
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', ...additionalStyle,
        }
    }
    >
      <BasicSwitch checked={checked} onChange={onChange} />
      <Text type="body1">
        {checked ? labelChecked : labelUnchecked}
      </Text>
    </Box>
  );
}
