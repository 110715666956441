import {
  Dispatch, SetStateAction, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  IAverageLoadPerMuscleGroupStatsResponse,
  IMuscleGroupPercentageInPlanStatsResponse,
} from '../../../../common/interfaces/Stats';
import { useGetAverageLoadPerMuscleGroup } from '../../../../apollo/hooks/useGetAverageLoadPerMuscleGroup';
import { useGetMuscleGroupPercentageInPlans } from '../../../../apollo/hooks/useGetMuscleGroupPercentageInPlans';
import { DoughnutData } from '../../../../components/Charts/Doughnut/interfaces/Doughnut';
import { useGetLoggedUserMacronutrients } from '../../../../apollo/hooks/useGetLoggedUserMacronutrients';
import { IMacronutrients } from '../../../../common/interfaces/User';
import { AuthContext } from '../../../../context/auth';

type ControllerFN = () => {
  averageLoadsPerMuscleGroupsStats: IAverageLoadPerMuscleGroupStatsResponse[] | undefined,
  data: DoughnutData | Record<string, unknown>,
  setPeriod: Dispatch<SetStateAction<number>>
  period: number
  macronutrients: IMacronutrients | undefined
  statsLoading: boolean
  apiStatsError: string
  setApiStatsError: Dispatch<SetStateAction<string>>
};

const StatsController: ControllerFN = () => {
  const [period, setPeriod] = useState<number>(0);
  const [statsLoading, setLoading] = useState<boolean>(true);
  const [apiStatsError, setApiStatsError] = useState<string>('');

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const {
    averageLoadsPerMuscleGroupsStats, loading: averageLoadsPerMuscleGroupStatsLoading,
    error: averageLoadsPerMuscleGroupStatsError,
  } = useGetAverageLoadPerMuscleGroup(id);
  const {
    muscleGroupPercentageInPlansStats, loading: muscleGroupPercentageInPlansStatsLoading,
    error: muscleGroupPercentageInPlansStatsError,
  } = useGetMuscleGroupPercentageInPlans(id, period);
  const { macronutrients, loading: macronutrientsLoading, error: macronutrientsError } = useGetLoggedUserMacronutrients();

  useEffect(() => {
    if (averageLoadsPerMuscleGroupStatsError) {
      setApiStatsError(averageLoadsPerMuscleGroupStatsError.message);
    } else if (muscleGroupPercentageInPlansStatsError) {
      setApiStatsError(muscleGroupPercentageInPlansStatsError.message);
    } else if (macronutrientsError) {
      setApiStatsError(macronutrientsError.message);
    } else {
      setApiStatsError('');
    }
  }, [macronutrientsError, muscleGroupPercentageInPlansStatsError, averageLoadsPerMuscleGroupStatsError]);

  useEffect(() => {
    const calculateLoadingValue = muscleGroupPercentageInPlansStatsLoading && macronutrientsLoading && averageLoadsPerMuscleGroupStatsLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [muscleGroupPercentageInPlansStatsLoading, macronutrientsLoading, averageLoadsPerMuscleGroupStatsLoading]);

  const data = useMemo(() => ({
    labels: muscleGroupPercentageInPlansStats?.map((mg: IMuscleGroupPercentageInPlanStatsResponse) => mg.muscleGroup),
    datasets: [
      {
        label: 'Muscle Group Percentage',
        data: muscleGroupPercentageInPlansStats?.map((mg: IMuscleGroupPercentageInPlanStatsResponse) => mg.percentage),
        backgroundColor: [
          'rgb(113, 26, 117)',
          'rgb(241, 0, 134)',
          'rgb(119, 67, 96)',
          'rgb(178, 80, 104)',
          'rgb(231, 171, 121)',
          'rgb(193, 71, 233)',
          'rgb(229, 184, 244)',
        ],
        borderWidth: 0,
      },
    ],
  }), [muscleGroupPercentageInPlansStats]);

  return {
    averageLoadsPerMuscleGroupsStats, data, setPeriod, period, macronutrients, statsLoading, setApiStatsError, apiStatsError,
  };
};

export default StatsController;
