import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as Loader } from '../../asset/icons/loader1.svg';
import Backdrop from '../Backdrop/Backdrop';
import '../../index.css';

export default function LoaderSpinner() {
  return (
    <Backdrop additionalStyle={{
      background:
          'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(54,54,54,1) 28%, rgba(38,38,38,1) 64%, rgba(29,29,29,1) 85%, rgba(22,22,22,1) 100%)',
    }}
    >
      <>
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center',
        }}
        >
          <Skeleton variant="rounded" width="90vw" height="5vh" />
          <Skeleton variant="rounded" width="90vw" height="8vh" />
          <Skeleton sx={{ alignSelf: 'flex-start' }} variant="circular" width="5vh" height="5vh" />
          <Skeleton variant="rounded" width="90vw" height="20vh" />
          <Skeleton variant="rounded" width="90vw" height="5vh" />
          <Skeleton variant="rounded" width="90vw" height="8vh" />
          <Skeleton sx={{ alignSelf: 'flex-start' }} variant="circular" width="5vh" height="5vh" />
          <Skeleton variant="rounded" width="90vw" height="18vh" />

        </Box>
        <Box sx={{
          width: '100%', position: 'absolute', display: 'flex', flexDirection: 'column', gap: '3rem', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Loader style={{ width: '15rem', height: '10rem' }} />
          <LinearProgress sx={{ width: '50%', height: '1rem', borderRadius: '1rem' }} />
        </Box>

      </>
    </Backdrop>
  );
}
