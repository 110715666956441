import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeBar from '../../../components/HomeBar/HomeBar';
import PTHomePageController from './controller/PTHomePage.controller';
import Layout from '../../../components/Layout/Layout';
import '../../common/Search.css';
import Calendar from '../../../components/Calendar/Calendar';
import ListItem from '../../../components/ListItem/ListItem';
import PTHomePageShortcuts from './components/PTHomePageShortcuts/PTHomePageShortcuts';
import PTHomePageTasks from './components/PTHomePageTasks/PTHomePageTasks';
import { List } from './PTHomePage.style';
import { PATH_CONSTANTS } from '../../../helper/constants';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import CustomButton from '../../../components/CustomButton/CustomButton';
import CustomSearchBar from '../../../components/SearchBar/SearchBar';

interface OwnProps {}

type Props = OwnProps;

const PTHomePage: FunctionComponent<Props> = () => {
  const {
    apiError, setApiError,
    searching, onChange,
    resetSearch, userToDisplay,
    startSearching, onChangeDate,
    selectedDay, loading,
    setStartSearching,
    openUserPopup, setOpenUserPopup,
    logout, onClickSearchButton,
  } = PTHomePageController();

  const navigate = useNavigate();
  const {
    ATHLETES_PT_PATH,
  } = PATH_CONSTANTS;

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <HomeBar onClickOnAvatar={() => setOpenUserPopup(true)} />
        <CustomSearchBar
          placeholder="Cerca atleta"
          value={searching}
          onCancelSearch={resetSearch}
          onChange={onChange}
          onClick={onClickSearchButton}
          showCancelButton={false}
        />

        <Calendar selectedDay={selectedDay} onClick={onChangeDate} />

        <PTHomePageShortcuts />

        <PTHomePageTasks />

        <SlidingPopup
          open={startSearching}
          handleClose={() => {
            resetSearch();
            setStartSearching(false);
          }}
          additionalStyle={{ height: '60vh' }}
        >
          <List>
            {userToDisplay?.map((user) => (
              <ListItem onClick={() => navigate(`${ATHLETES_PT_PATH}/${user._id}`)} name={user.name || 'Default'} age={user.age} plansNumber="2" />
            ))}
          </List>
        </SlidingPopup>

        <SlidingPopup
          open={openUserPopup}
          handleClose={() => setOpenUserPopup(false)}
          additionalStyle={{ height: '8vh' }}
        >
          <CustomButton additionalStyle={{ textAlign: 'center' }} label="Logout" onClick={logout} />
        </SlidingPopup>

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTHomePage;
