import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Box } from '@mui/material';

interface Props {
  selected: number
  onClick: Dispatch<SetStateAction<number>>
}

export default function CustomRating({ selected, onClick } : Props) {
  const customIcons: {
    [index: string]: React.ReactElement;
  } = {
    1:
  <SentimentVeryDissatisfiedIcon
    onClick={() => onClick(1)}
    sx={{ width: '2.8rem', height: '2.8rem', color: selected === 1 ? '#F9F9F9' : 'rgba(255,255,255,0.3)' }}
  />,
    2:
  <SentimentSatisfiedIcon
    onClick={() => onClick(2)}
    sx={{ width: '2.8rem', height: '2.8rem', color: selected === 2 ? '#F9F9F9' : 'rgba(255,255,255,0.3)' }}
  />,
    3:
  <SentimentVerySatisfiedIcon
    onClick={() => onClick(3)}
    sx={{ width: '2.8rem', height: '2.8rem', color: selected === 3 ? '#F9F9F9' : 'rgba(255,255,255,0.3)' }}
  />,
  };

  return (
    <Box sx={{
      display: 'flex', gap: '0.8rem', marginTop: '1rem', marginBottom: '0.5rem',
    }}
    >
      {Object.keys(customIcons).map((key) => customIcons[key])}
    </Box>
  );
}
