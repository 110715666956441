import React from 'react';
import { Grid } from '@mui/material';
import HorizontalSection from '../../../../../components/HorizontalSection/HorizontalSection';
import StatsCard from '../../../../../components/StatsCard/StatsCard';
import { ILineData } from '../../../../../components/Charts/Line/interfaces/Line';
import BarChart from '../../../../../components/Charts/Bar/BarChart';
import LineChart from '../../../../../components/Charts/Line/LineChart';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { IUser } from '../../../../../common/interfaces/User';
import { BarData } from '../../../../../components/Charts/Bar/interfaces/Bar';
import { ChartOpt } from '../../../../../common/interfaces/Chart';

interface Props {
  dataMeasures: BarData | Record<string, unknown>
  dataWeight: ILineData | Record<string, unknown>
  maximal: IUser
  dataWeightOpt: ChartOpt
  dataMeasuresOpt: ChartOpt
}

export default function AthleteStats({
  dataWeight, dataWeightOpt, dataMeasures, dataMeasuresOpt, maximal,
}: Props) {
  return (
    <HorizontalSection title="Tu" additionalStyle={{ flexGrow: 1 }}>
      <>
        <StatsCard statName="Peso">
          <LineChart options={dataWeightOpt} data={dataWeight} />
        </StatsCard>

        <StatsCard statName="Misure">
          <BarChart options={dataMeasuresOpt} data={dataMeasures} />
        </StatsCard>

        <StatsCard statName="Massimali">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DetailsBox label="Panca piana">
                <Text type="body1">{`${maximal?.maximal ? `${maximal?.maximal?.benchPress.toString()}kg` : 'N/D'}` || 'N/D'}</Text>
              </DetailsBox>
            </Grid>
            <Grid item xs={6}>
              <DetailsBox label="Stacco da terra">
                <Text type="body1">{`${maximal?.maximal ? `${maximal?.maximal?.deadLift.toString()}kg` : 'N/D'}` || 'N/D'}</Text>
              </DetailsBox>
            </Grid>
            <Grid item xs={6}>
              <DetailsBox label="Squat">
                <Text type="body1">{`${maximal?.maximal ? `${maximal?.maximal?.squat.toString()}kg` : 'N/D'}` || 'N/D'}</Text>
              </DetailsBox>
            </Grid>
            <Grid item xs={6}>
              <DetailsBox label="Military Press">
                <Text type="body1">{`${maximal?.maximal ? `${maximal?.maximal?.militaryPress.toString()}kg` : 'N/D'}` || 'N/D'}</Text>
              </DetailsBox>
            </Grid>
          </Grid>
        </StatsCard>
      </>
    </HorizontalSection>
  );
}
