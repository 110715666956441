import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import {
  Badge, Box, Grid, IconButton, Slide,
} from '@mui/material';
import '../../../../common/Search.css';
import { BsFilter } from 'react-icons/bs';
import { Fade } from 'react-awesome-reveal';
import { IExercise, IExerciseInPlan } from '../../../../../common/interfaces/Exercise';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import useGetMuscleGroupIcons from '../../../../../hooks/useGetMuscleGroupIcons';
import SlidingPopup from '../../../../../components/SlidingPopup/SlidingPopup';
import { IMuscleGroup } from '../../../../../common/interfaces/MuscleGroup';
import CustomSearchBar from '../../../../../components/SearchBar/SearchBar';

interface IAddExerciseToPlan {
    exerciseToDisplay: IExercise[] | any,
    onAddExerciseToPlan: (ex: IExercise) => void
    open: boolean
    searching: string
    onChange: (e: any) => void
    onClickSearchButton: () => void
    resetSearch: () => void
    muscleGroupsList: IMuscleGroup[]
    selectedMuscleGroup: string | null,
    onSelectMuscleGroup: (newMuscleGroup: string) => void,
    exercisesByDayByMuscleGroup: Record<string, IExerciseInPlan[]>
}

export default function AddExerciseToPlan({
  exerciseToDisplay, onAddExerciseToPlan, open, searching, onChange,
  resetSearch, onSelectMuscleGroup, selectedMuscleGroup, muscleGroupsList,
  exercisesByDayByMuscleGroup, onClickSearchButton,
}: IAddExerciseToPlan) {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  return (
    <>

      <Slide direction="right" in={open} mountOnEnter unmountOnExit>

        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: '2rem',
        }}
        >
          <Box sx={{
            display: 'flex', justifyContent: 'space-around', alignItems: 'center',
          }}
          >
            <CustomSearchBar
              placeholder="Cerca esercizio"
              value={searching}
              onCancelSearch={resetSearch}
              onChange={onChange}
              onClick={onClickSearchButton}
              showCancelButton
            />

            <IconButton onClick={() => setOpenFilters(true)}>
              <Badge color={selectedMuscleGroup === '-1' ? 'default' : 'primary'} variant="dot">
                <BsFilter
                  color="#f9f9f9"
                  size="2rem"
                />
              </Badge>
            </IconButton>
          </Box>

          <Fade delay={100} cascade>
            <Grid container spacing={2}>
              {exerciseToDisplay?.map((ex: IExercise) => {
                // @ts-ignore
                const disablePointer = exercisesByDayByMuscleGroup[ex.muscleGroups[0].muscleGroup.name]
                  ?.findIndex((alreadyAddedExercise: IExerciseInPlan) => alreadyAddedExercise.exercise._id === ex._id) !== -1
                    // @ts-ignore
                    && exercisesByDayByMuscleGroup[ex.muscleGroups[0].muscleGroup.name]
                      ?.findIndex((alreadyAddedExercise: IExerciseInPlan) => alreadyAddedExercise.exercise._id === ex._id) !== undefined;

                return (
                  <Grid key={ex._id} item xs={4}>
                    <DetailsBox
                      label={ex.name}
                      onClick={() => onAddExerciseToPlan(ex)}
                      containerAdditionalStyle={{
                        pointerEvents: disablePointer
                          ? 'none' : 'auto',
                      }}
                      additionalStyle={{
                        background: disablePointer
                          ? 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(89,89,89,1) 100%)' : 'transparent',
                      }}
                      additionalLabelStyle={{
                        color: disablePointer ? 'rgba(255, 255, 255, 0.3)' : '#f9f9f9',
                      }}
                    >
                      <Avatar sx={{ background: 'transparent' }}>
                        {
                // @ts-ignore
                useGetMuscleGroupIcons(ex.muscleGroups[0].muscleGroup?.icon || '').selectedIcon
            }
                      </Avatar>
                    </DetailsBox>
                  </Grid>
                );
              })}
            </Grid>
          </Fade>
        </Box>

      </Slide>

      <SlidingPopup
        open={openFilters}
        handleClose={() => setOpenFilters(false)}
        actions
        actionLabel="Fatto"
        additionalStyle={
            {
              width: '60%',
              position: 'relative',
              background: '#1B1833',
              padding: '1.5rem 1.8rem 1.5rem 1.8rem',
              borderRadius: '1rem 1rem 1rem 1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 0,
              gap: '2rem',
            }
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DetailsBox label="All" onClick={() => onSelectMuscleGroup('-1' || '')}>
              <Avatar sx={{
                background: selectedMuscleGroup === '-1'
                  ? 'rgba(255, 255, 255, 0.3)!important' : 'transparent',
                fill: 'white',
              }}
              >
                {
                // @ts-ignore
                useGetMuscleGroupIcons('').selectedIcon
              }
              </Avatar>
            </DetailsBox>
          </Grid>

          {muscleGroupsList?.map((muscleGroup) => (
            <Grid key={muscleGroup?._id} item xs={4}>
              <DetailsBox label={muscleGroup.name} onClick={() => onSelectMuscleGroup(muscleGroup?._id || '')}>
                <Avatar sx={{
                  background: selectedMuscleGroup === muscleGroup._id
                    ? 'rgba(255, 255, 255, 0.3)!important' : 'transparent',
                  fill: 'white',
                }}
                >
                  {
                    // @ts-ignore
                    useGetMuscleGroupIcons(muscleGroup.icon || '').selectedIcon
                }
                </Avatar>
              </DetailsBox>
            </Grid>
          ))}

        </Grid>
      </SlidingPopup>
    </>

  );
}
