import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const List = styled(Box)`
  width: 100%;
`;

export const searchBarStyle = {
  height: '3.5rem', borderRadius: '1.5rem', width: '100%', background: 'transparent', border: '0.01rem solid rgba(255, 255, 255, 0.1)',
};

export const searchBarIconStyle = {
  color: '#F9F9F9',
};
