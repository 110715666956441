import { ApolloError, gql, useQuery } from '@apollo/client';
import { IUser } from '../../common/interfaces/User';

const GET_USER_STATS = gql`
    query user($id: ID!){
        user(id: $id) {
            weight{
                date
                weight
            }
            measures{
                date
                bmi
                bust
                waist
                hips
                thigh
            }
            maximal{
                benchPress
                militaryPress
                squat
                deadLift
            }
        }
    }
`;

type ControllerFN = (userId: string | undefined) => {
    userStats: IUser;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetUserStats: ControllerFN = (userId: string | undefined) => {
  const { data, loading, error } = useQuery(GET_USER_STATS, {
    variables: { id: userId },
  });
  const userStats = data?.user;
  return { loading, userStats, error };
};
