import React, { FunctionComponent } from 'react';
import { Fade } from 'react-awesome-reveal';
import Form from '../../../components/Form/Form';
import Layout from '../../../components/Layout/Layout';
import LoginController from './controller/Login.controller';
import Text from '../../../components/CustomTextFormatter/Text';
import { typographyStyle } from '../../../components/SplashScreen/SplashScreen.style';
import { FormContainer, LogoContainer } from './Login.style';
import { PALETTE } from '../../../helper/constants';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import { ReactComponent as Loader } from '../../../asset/icons/loaderOpacity.svg';

interface OwnProps {}

type Props = OwnProps;

const Login: FunctionComponent<Props> = () => {
  const {
    formik, formField, loading, apiError, setApiError,
  } = LoginController();

  const { ORANGEISH } = PALETTE;

  if (loading) return <LoaderSpinner />;

  return (
    <Layout additionalStyle={{ backgroundColor: ORANGEISH, justifyContent: 'center', height: '100vh' }}>
      <>
        <LogoContainer>
          <Text type="h1" additionalStyle={typographyStyle}>Coach</Text>
          <Text type="h1" additionalStyle={typographyStyle}>Workout</Text>
        </LogoContainer>
        <Fade>
          <Loader style={{
            width: '13rem', height: '13rem',
          }}
          />
        </Fade>
        <FormContainer>
          <Form
            additionalStyle={{ input: { color: 'white!important' } }}
            additionalInputStyle={{
              style: { color: '#fff!important' },
            }}
            formFields={formField}
            formik={formik}
            buttonLabel="Login"
          />
        </FormContainer>

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default Login;
