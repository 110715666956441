import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { PALETTE } from '../../helper/constants';

const { WHITEISH } = PALETTE;

export const cardStyle = {
  boxShadow: '0.31rem 0.31rem 0.25rem rgba(0, 0, 0, 0.08)',
  borderRadius: '0.5rem',
  marginRight: '1.5rem',
  width: '18.75rem',
  minWidth: '18.75rem',
  maxWidth: '18.75rem',
  overflow: 'auto',
  // background: 'linear-gradient(180deg, hsla(4, 99%, 66%, 1) 0%, hsla(4, 98%, 75%, 1) 65%)',
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
};

export const titleBarStyle = {
  backgroundColor: 'transparent',
};

export const StatContainer = styled(Box)`
  height: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  `;

export const chartNameStyle = { color: WHITEISH, flexGrow: 1, letterSpacing: '-1.5px' };
