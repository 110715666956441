import React from 'react';
import BackButton from '../Pagebar/components/BackButton/BackButton';
import DetailsBox from '../DetailsBox/DetailsBox';
import { detailsBoxStyle } from './BoxedBackButton.style';

interface Props {
    indexPage: boolean,
    additionalBoxStyle? : Record<string, unknown>
}

export default function BoxedBackButton({
  indexPage, additionalBoxStyle,
}: Props) {
  return (
    <DetailsBox
      label=""
      additionalStyle={{
        ...detailsBoxStyle,
        ...additionalBoxStyle,
      }}
    >
      <BackButton pt={false} indexPage={indexPage} />
    </DetailsBox>
  );
}
