import React, { Dispatch, SetStateAction } from 'react';
import { Grid } from '@mui/material';
import HorizontalSection from '../../../../../components/HorizontalSection/HorizontalSection';
import StatsCard from '../../../../../components/StatsCard/StatsCard';
import { DoughnutChart } from '../../../../../components/Charts/Doughnut/DoughnutChart';
import PeriodSelectionButtons from '../../../../../components/PeriodSelectionButtons/PeriodSelectionButtons';
import { AverageLoadsContainer } from '../../PTReports.style';
import StatsPlanExerciseCard
  from '../../../../../components/StatsCard/components/StatsPlanExerciseCard/StatsPlanExerciseCard';
import { IAverageLoadPerMuscleGroupStatsResponse } from '../../../../../common/interfaces/Stats';
import { DoughnutData } from '../../../../../components/Charts/Doughnut/interfaces/Doughnut';
import { IPlansCardResponse, IVolumes } from '../../../../../common/interfaces/Plans';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import Text from '../../../../../components/CustomTextFormatter/Text';

interface Props {
    data: DoughnutData | Record<string, unknown>
    period: number
    setPeriod: Dispatch<SetStateAction<number>>
    averageLoadPerMuscleGroup: IAverageLoadPerMuscleGroupStatsResponse[] | undefined
    activePlanVolumes: IPlansCardResponse[] | any;
}

export default function PlanStats({
  data, period, setPeriod, averageLoadPerMuscleGroup, activePlanVolumes,
}: Props) {
  return (
    <HorizontalSection title="Piano" additionalStyle={{ flexGrow: 1 }}>
      <>
        <StatsCard statName="Gruppi muscolari focali">
          <>
            {// @ts-ignore
                data?.labels?.length > 0 && (
                <>
                  <DoughnutChart data={data} />
                  <PeriodSelectionButtons period={period} setPeriod={setPeriod} />
                </>
                )
            }
            {// @ts-ignore
                !data?.labels?.length > 0 && (
                <Text type="body1">
                  Non è presente alcun piano attivo.
                </Text>
                )
            }
          </>
        </StatsCard>

        <StatsCard statName="Carichi medi">
          <AverageLoadsContainer>
            {averageLoadPerMuscleGroup && averageLoadPerMuscleGroup.length > 0 && averageLoadPerMuscleGroup?.map((stat) => (
              <StatsPlanExerciseCard muscleGroup={stat} />
            ))}
            {(!averageLoadPerMuscleGroup || averageLoadPerMuscleGroup?.length <= 0)
                && <Text type="body1">Non è stato ancora caricato alcun carico.</Text>}
          </AverageLoadsContainer>
        </StatsCard>

        <StatsCard statName="Volumi">
          <>
            {activePlanVolumes && activePlanVolumes.volumes.length > 0 && (
            <Grid container spacing={2}>
              {activePlanVolumes?.volumes?.map((vol: IVolumes) => (
                <Grid item xs={6}>
                  <DetailsBox label={vol.muscleGroup?.name}>
                    <Text type="body1">{vol.volume?.toString()}</Text>
                  </DetailsBox>
                </Grid>
              ))}
            </Grid>
            )}

            {(!activePlanVolumes || activePlanVolumes.volumes.length <= 0)
                && <Text type="body1">Non è presente alcun piano attivo.</Text>}
          </>
        </StatsCard>
      </>
    </HorizontalSection>
  );
}
