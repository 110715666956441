import React from 'react';
import { IconButton as IconButtonMui } from '@mui/material';

interface Props {
    additionalStyle?: any,
    children: JSX.Element,
    onClick?: () => void
}

export default function IconButton({
  additionalStyle, onClick, children,
}: Props) {
  return (
    <IconButtonMui sx={additionalStyle} onClick={onClick}>
      {children}
    </IconButtonMui>
  );
}
