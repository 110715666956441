import React from 'react';
import { Box } from '@mui/material';
import CustomButton from '../CustomButton/CustomButton';
import { buttonStyle } from './PeriodSelectionButtons.styles';

export default function PeriodSelectionButtons({ period, setPeriod } : { period: number, setPeriod: any}) {
  return (
    <Box style={{ display: 'flex', gap: '1rem' }}>
      <CustomButton
        additionalStyle={{
          backgroundColor: period === 0 ? 'rgba(249, 249, 249, 0.5)' : 'rgba(249, 249, 249, 0.2)',
          fontWeight: period === 0 ? 'bold' : '600',
          ...buttonStyle,
        }}
        onClick={() => setPeriod(0)}
        label="Attivo"
      />
      <CustomButton
        additionalStyle={{
          backgroundColor: period === 3 ? 'rgba(249, 249, 249, 0.5)' : 'rgba(249, 249, 249, 0.2)',
          fontWeight: period === 3 ? 'bold' : '600',
          ...buttonStyle,
        }}
        onClick={() => setPeriod(3)}
        label="Last 3"
      />
      <CustomButton
        additionalStyle={{
          backgroundColor: period === -1 ? 'rgba(249, 249, 249, 0.5)' : 'rgba(249, 249, 249, 0.2)',
          fontWeight: period === -1 ? 'bold' : '600',
          ...buttonStyle,
        }}
        onClick={() => setPeriod(-1)}
        label="All"
      />
    </Box>
  );
}
