/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slide from '@mui/material/Slide';
import { Box, Dialog } from '@mui/material';
import CustomButton from '../CustomButton/CustomButton';

interface Props {
    children: JSX.Element | Element,
    additionalStyle?: Record<string, unknown>
    open: boolean
    handleClose: () => void
    actions?: boolean
    negativeAction?: boolean
    actionLabel?: string
    negativeActionLabel?: string
    handleAction? : () => void
    handleNegativeAction? : () => void
}

// @ts-ignore
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SlidingPopup({
  children, additionalStyle, open, handleClose, actions, negativeActionLabel, actionLabel, handleAction, negativeAction, handleNegativeAction,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          width: 'calc(100% - 3.6rem)',
          bottom: 0,
          position: 'fixed',
          // background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
          background: 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(54,54,54,1) 28%, '
              + 'rgba(38,38,38,1) 64%, rgba(29,29,29,1) 85%, rgba(22,22,22,1) 100%)',
          border: '0.01rem solid rgba(255, 255, 255, 0.1)',
          padding: '1.5rem 1.8rem 1.5rem 1.8rem',
          borderRadius: '1rem 1rem 0 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 0,
          ...additionalStyle,
        },
      }}
    >
      {/* @ts-ignore */}
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%',
      }}
      >
        {children}
        {actions && (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          {negativeAction && (
          <Box sx={{ width: '100%' }}>
            <CustomButton
              additionalStyle={{ width: '100%', textAlign: 'center' }}
              label={negativeActionLabel || 'No'}
              onClick={handleNegativeAction || handleClose}
            />
          </Box>
          )}
          <Box sx={{ width: '100%' }}>
            <CustomButton
              additionalStyle={{ width: '100%', textAlign: 'center' }}
              label={actionLabel || 'Ok!'}
              onClick={handleAction || handleClose}
            />
          </Box>
        </Box>
        )}
      </Box>
    </Dialog>
  );
}
