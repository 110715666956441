import React from 'react';
import Avatar from '@mui/material/Avatar';
import {
  Box, Grid, ListItemAvatar, ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import RepsChip from '../RepsChip/RepsChip';
import { ListItemContainer, listItemStyle, listItemTextStyle } from './UserExerciseList.style';
import { PATH_CONSTANTS } from '../../helper/constants';
import useGetMuscleGroupIcons from '../../hooks/useGetMuscleGroupIcons';
import { IExerciseInPlan } from '../../common/interfaces/Exercise';
import { iconStyle } from '../../common/styles/svgIcons.style';
import { IMuscleGroupPercentageInPlanStatsResponse } from '../../common/interfaces/Stats';
import { List } from '../../pages/pt/PTHomePage/PTHomePage.style';

interface Props {
  planId: string,
  exerciseList:IExerciseInPlan[],
  muscleGroup: string,
  training: boolean,
  onPlayClick?: (id: string) => void,
  icons?: IMuscleGroupPercentageInPlanStatsResponse[]
  pt?: boolean
}

export default function UserExerciseList({
  planId, exerciseList, muscleGroup, training, onPlayClick, icons, pt = false,
} : Props) {
  const iconPlaceholder = icons?.find((i) => i.muscleGroup === muscleGroup)?.icon || '';
  const { selectedIcon } = useGetMuscleGroupIcons(iconPlaceholder);
  const { PLANS_PATH, EXERCISES_PT_PATH } = PATH_CONSTANTS;
  const navigate = useNavigate();
  return (
    <List>
      {exerciseList?.map((ex) => (
        <ListItemContainer key={ex.exercise._id} style={listItemStyle}>
          <Grid container spacing={2}>
            {!training && (
            <Grid item xs={6}>
              <ListItemAvatar sx={iconStyle}>
                <Avatar sx={{ background: 'rgba(255, 255, 255, 0.2)' }}>
                  {selectedIcon}
                </Avatar>
              </ListItemAvatar>
            </Grid>
            )}

            {training && (
            <Grid item xs={6}>
              {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
              <ListItemAvatar onClick={onPlayClick ? () => onPlayClick(ex?._id || 'N/D') : () => {
              }}
              >
                <Avatar sx={{ background: 'rgba(255, 255, 255, 0.2)' }}>
                  <PlayCircleFilledWhiteIcon />
                </Avatar>
              </ListItemAvatar>
            </Grid>
            )}

            <Grid item xs={6}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                onClick={!pt ? () => navigate(`${PLANS_PATH}/${planId}/${ex.exercise._id}`)
                  : () => navigate(`${EXERCISES_PT_PATH}/${ex.exercise._id}`)}
              >
                <ListItemText
                  sx={listItemTextStyle}
                  primary={ex.exercise.name}
                  secondary={ex.note}
                />
                <RepsChip exerciseReps={ex.setRep} />
              </Box>
            </Grid>
          </Grid>
        </ListItemContainer>
      ))}
    </List>
  );
}
