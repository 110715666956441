import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const phasesCard = {
  background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
  width: '100%',
  flexGrow: 1,
  borderRadius: '1rem!important',
  // maxHeight: '70vh',
  overflow: 'auto',
};

export const phasesCardContent = { display: 'flex', flexDirection: 'column' };

export const AddIconContainer = styled(Box)`
  height: 2.5rem;
  width: 2.5rem;
  background-color: rgba(255,255,255,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
  align-self: flex-end;
`;

export const ItemColumnCentered = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ActionContent = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  color: #eee;
  user-select: none;
`;

export const deleteIconStyle = {
  color: '#F9F9F9',
};

export const deleteSwipeStyle = {
  background: 'linear-gradient(180deg, hsla(0, 86%, 66%, 1) 0%, hsla(0, 86%, 66%, 0.8) 100%)',
  height: '5.5rem',
  alignSelf: 'center',
  borderRadius: '0 1rem 1rem 0',
};

export const editSwipeStyle = {
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
  height: '5.5rem',
  alignSelf: 'center',
};
