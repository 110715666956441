import React from 'react';
import { DividerBox } from './Divider.style';

interface Props {
    additionalStyle? : Record<string, unknown>
}

export default function Divider({ additionalStyle }: Props) {
  return (
    <DividerBox sx={additionalStyle} />
  );
}
