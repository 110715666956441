export interface IPhases {
    name: string,
    description: string,
    period: string,
    eta: number,
    _id?: string
}

export interface ITrainingPhase {
    _id?: string
    phases?: [IPhases]
    pt?: string
}

// function to check if an object fits the interface
export function isPhases(object: unknown): object is IPhases {
  return Object.prototype.hasOwnProperty.call(object, 'name')
        && Object.prototype.hasOwnProperty.call(object, 'description')
        && Object.prototype.hasOwnProperty.call(object, 'period')
        && Object.prototype.hasOwnProperty.call(object, 'eta');
}
