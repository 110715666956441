/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CssPicker } from './DatePicker.style';

export default function CustomDatePicker({ value, handleChange, label } :
{label: string, value: Dayjs | null, handleChange: (newValue: Dayjs | null) => void}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={label}
        inputFormat="DD/MM/YYYY"
        value={value}
        onChange={handleChange}
        renderInput={(params: any) => (
          <CssPicker
            sx={{
              label: { color: '#F9F9F9!important' },
            }}
            {...params}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>

  );
}
