import { ApolloError, gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth';
import { IExercise } from '../../common/interfaces/Exercise';

export const GET_EXERCISES_BY_PT = gql`
    query exercisesByPtId($ptId: ID!){
        exercisesByPtId(ptId: $ptId) {
            _id,
            name
            muscleGroups {
                muscleGroup {
                    _id
                    name
                    icon
                }
            }
        }
    }
`;

type ControllerFN = () => {
    exercisesByPtId: IExercise[];
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetExercisesByPt: ControllerFN = () => {
  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  const { data, loading, error } = useQuery(GET_EXERCISES_BY_PT, {
    variables: { ptId: id },
  });
  const exercisesByPtId = data?.exercisesByPtId;
  return { loading, exercisesByPtId, error };
};
