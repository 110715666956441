import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ButtonContainer = styled(Box)`
  gap: 1rem;
  display: flex;
`;

export const DayTextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const iconButtonStyle = {
  backgroundColor: 'rgba(255,255,255,0.2)',
  height: '60px',
  width: '60px',
  color: ' white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1rem',
};

export const titleBarStyle = { margin: 0, justifyContent: 'flex-end' };

export const sessionButton = {
  borderRadius: '2rem',
  padding: '0.5rem',
  color: '#F9F9F9',
};

export const muscleGroupSection = {
  color: '#F9F9F9',
};

export const cardStyle = {
  boxShadow: '0.31rem 0.31rem 0.25rem rgba(0, 0, 0, 0.08)',
  borderRadius: '1rem!important',
  overflow: 'auto',
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
  width: '95%',
  height: '80vh',
};
