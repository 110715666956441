import React, { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import Text from '../../../components/CustomTextFormatter/Text';
import PTAthleteShortcuts from './components/PTAthleteShortcuts/PTAthleteShortcuts';
import TitleBar from '../../../components/TitleBar/TitleBar';
import PTAthleteController from './controller/PTAthletePage.controller';
import ImageCard from '../../../components/ImageCard/ImageCard';
import { athleteCard } from './PTAthlete.style';
import { PATH_CONSTANTS } from '../../../helper/constants';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';

interface OwnProps {}

type Props = OwnProps;

const PTAthlete: FunctionComponent<Props> = () => {
  const { id } = useParams();
  const {
    userInfo, loading, apiError, setApiError,
  } = PTAthleteController(id);

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }
  const { ATHLETES_PT_PATH } = PATH_CONSTANTS;

  return (
    <Layout>
      <>
        <Pagebar pt specificPath={ATHLETES_PT_PATH} indexPage={false} title="Atleta" />

        <Card
          additionalStyle={athleteCard}
          cardContentStyle={{ gap: '1rem' }}
        >
          <>
            <TitleBar><Text type="h2">{userInfo?.name || 'ND'}</Text></TitleBar>
            <ImageCard imageSrc="https://www.rainews.it/dl/img/2022/02/18/1645201577823_GettyImages.jpg" />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Text type="body2">Età</Text>
                <Text type="body1">{userInfo?.age || 'ND'}</Text>
              </Grid>
              <Grid item xs={6}>
                <Text type="body2">Piani</Text>
                <Text type="body1">{userInfo?.plans?.length.toString() || 'ND'}</Text>
              </Grid>
              <Grid item xs={6}>
                <Text type="body2">Email</Text>
                <Text additionalStyle={{ wordBreak: 'break-word' }} type="body1">{userInfo?.email || 'ND'}</Text>
              </Grid>
              <Grid item xs={6}>
                <Text type="body2">Data di inizio</Text>
                <Text type="body1">ND</Text>
              </Grid>
            </Grid>
          </>
        </Card>
        <PTAthleteShortcuts userId={userInfo?._id || ''} />

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTAthlete;
