import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const SplashBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'absolute',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '2rem',
}));

export const typographyStyle = { color: 'white', fontSize: '3rem', lineHeight: '1.12' };
