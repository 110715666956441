import React from 'react';
import Text from '../CustomTextFormatter/Text';
import { IExercise } from '../../common/interfaces/Exercise';
import { ContentWrapper } from './ExerciseCard.style';
import ExerciseCardController from './controller/ExerciseCard.controller';
import PolarChart from '../Charts/Radar/PolarChart';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';

export default function ExerciseCard({ exercise, type } : { exercise: IExercise | null, type: string }) {
  const {
    data,
  } = ExerciseCardController(exercise, type);

  return (
    <ContentWrapper>
      {data && type === 'polar' && <PolarChart data={data} />}
      {type === 'howTo' && <Text type="body1">{exercise?.howTo || 'Non ci sono altri consigli specificati.'}</Text>}
      {type === 'video' && exercise?.video && <YoutubeEmbed embedId={exercise?.video?.split('=')[1]} />}
    </ContentWrapper>

  );
}
