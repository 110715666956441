import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { textStyle } from './TrainingCompleted.style';
import CustomRating from '../CustomRating/CustomRating';
import StateControlledForm from '../../../../../components/StateControlledForm/StateControlledForm';

interface Props {
    selectedRating: number
    setSelectedRating: Dispatch<SetStateAction<number>>
    trainingNote: string
    setTrainingNote: Dispatch<SetStateAction<string>>
}

export default function TrainingCompleted({
  selectedRating, setSelectedRating, trainingNote, setTrainingNote,
}: Props) {
  const formField = [
    {
      type: 'text',
      fieldType: 'TextField',
      placeholder: '',
      value: trainingNote,
      label: 'Training Note',
      // @ts-ignore
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setTrainingNote(e.target.value),
    },
  ];

  return (
    <>
      <Text additionalStyle={textStyle} type="h2">Congratulazioni! Allenamento completato</Text>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Text type="body1">Valuta il tuo allenamento</Text>
        <CustomRating selected={selectedRating} onClick={setSelectedRating} />
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Text type="body1">Vuoi annotarti qualcosa per la prossima volta?</Text>
        <StateControlledForm formFields={formField} />
      </Box>
    </>
  );
}
