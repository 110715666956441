import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Text from '../CustomTextFormatter/Text';

export default function ImageCard({ imageSrc } : {imageSrc?: string}) {
  return (
    <Card sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.3)', minHeight: '15rem', height: '15rem', width: '15rem', minWidth: '15rem', alignSelf: 'center',
    }}
    >
      {imageSrc && (
      <CardMedia
        component="img"
        sx={{
          height: '15rem',
        }}
        image={imageSrc}
        alt="User"
      />
      )}
      {!imageSrc && (
      <Text type="h1">
        No avatar uploaded
      </Text>
      )}
    </Card>
  );
}
