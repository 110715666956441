import React from 'react';
import { Box, Grid } from '@mui/material';
import Text from '../../../../../../components/CustomTextFormatter/Text';

interface IViewSelector {
    boxPosition: string,
    handleClick: (position: string) => void
    gridItemRef: any
}

export default function ViewSelector({ boxPosition, handleClick, gridItemRef } : IViewSelector) {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '5vh',
      minHeight: '5vh',
      background: 'rgba(255, 255, 255, 0.01)',
      border: '0.01rem solid rgba(255, 255, 255, 0.1)',
      borderRadius: '0.5rem',
      position: 'relative',
    }}
    >
      <Grid
        container
        spacing={0}
      >
        <Box sx={{
          position: 'absolute',
          background: 'linear-gradient(145deg, rgba(67,67,67,0.5) 0%, rgba(89,89,89,0.5) 100%)',
          width: '50%',
          height: '80%',
          top: '0.3rem',
          borderRadius: '0.2rem',
          transition: 'all 0.4s ease-in-out',
          transform: boxPosition,
        }}
        />
        <Grid ref={gridItemRef} item xs={6} onClick={() => handleClick('left')}>
          <Text type="body1" additionalStyle={{ textAlign: 'center' }}>
            Exercise List
          </Text>
        </Grid>
        <Grid item xs={6} onClick={() => handleClick('right')}>
          <Text type="body1" additionalStyle={{ textAlign: 'center' }}>
            Preview
          </Text>
        </Grid>
      </Grid>
    </Box>
  );
}
