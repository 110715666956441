import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { IExercise } from '../../../../common/interfaces/Exercise';
import { useGetExerciseById } from '../../../../apollo/hooks/useGetExerciseById';

type ControllerFN = (exerciseId : string | undefined) => {
  exercise: IExercise | undefined
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const ExerciseController: ControllerFN = (exerciseId : string | undefined) => {
  const [apiError, setApiError] = useState<string>('');

  const { exercise, loading, error } = useGetExerciseById(exerciseId);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  return {
    exercise, loading, apiError, setApiError,
  };
};

export default ExerciseController;
