import React from 'react';
import { Box, Grid } from '@mui/material';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { StyledChip } from '../../../../../components/PlanCard/PlanCard.style';

export default function PhaseItem({
  description, name, period, eta, additionalStyle,
} : { description: string, name: string, period?: string, eta?: string, additionalStyle?:any }) {
  return (
    <Box
      sx={{
        background: 'transparent',
        borderBottom: '0.01rem solid rgba(255, 255, 255, 0.1)',
        width: 'calc(100% - 2rem)',
        height: '3.5rem',
        padding: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        margin: '0.5rem 0 0.5rem 0',
        ...additionalStyle,
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between" sx={{ marginTop: '2rem' }}>
        <Grid item xs={4}>
          <StyledChip size="small" label={name} />
        </Grid>

        <Grid item xs={3}>
          <Box>
            <Text type="body2">
              { period || 'ND' }
            </Text>
            <Text type="subtitle2" additionalStyle={{ color: 'white', flexGrow: 1 }}>{description}</Text>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Text type="body2" additionalStyle={{ textAlign: 'right' }}>
            {eta || 'N/D'}
          </Text>
        </Grid>
      </Grid>
    </Box>

  );
}
