import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const List = styled(Box)`
  height: 40%;
  width: calc(100% - 3.6rem);
  bottom: 0;
  position: fixed;
  background: linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%);
  padding: 1.5rem 1.8rem 1.5rem 1.8rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  gap: 3rem;
`;

export const ExerciseList = styled(Box)`
  height: 15rem; 
  max-height: 15rem; 
  overflow: auto;
`;

export const statsCardStyle = {
  width: '100%!important',
  minWidth: '100%!important',
  maxWidth: '100%!important',
};
