import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useGetActiveUserPlan } from '../../../../apollo/hooks/useGetActiveUserPlan';
import { IPlansCardResponse } from '../../../../common/interfaces/Plans';
import { AuthContext } from '../../../../context/auth';

type ControllerFN = () => {
    activePlan: IPlansCardResponse[] | any
    loading: boolean
    apiPlansError: string
    setApiPlansError: Dispatch<SetStateAction<string>>
};

const PlansController: ControllerFN = () => {
  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  const { activePlan, loading: activePlanLoading, error } = useGetActiveUserPlan(id);

  const [loading, setLoading] = useState<boolean>(true);
  const [apiPlansError, setApiPlansError] = useState<string>('');

  useEffect(() => {
    if (error) {
      setApiPlansError(error.message);
    } else {
      setApiPlansError('');
    }
  }, [error]);

  useEffect(() => {
    const calculateLoadingValue = activePlanLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [activePlanLoading]);

  return {
    activePlan, loading, apiPlansError, setApiPlansError,
  };
};

export default PlansController;
