import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IPlansCardWithStats } from '../../../../common/interfaces/Plans';
import { GET_PLANS_WITH_STATS_BY_USER } from '../../../../apollo/hooks/useGetPlansWithStatsByUserId';
import { DELETE_PLAN } from '../../../../apollo/mutations/deletePlan';

type ControllerFN = (idUser: string | undefined) => {
  plansToDisplay: IPlansCardWithStats[] | null;
  loading: boolean;
  searching: string;
  apiError: string;
  onChange: (e: any) => void
  resetSearch: () => void
  onClickSearchButton: () => void
  setApiError: Dispatch<SetStateAction<string>>
  showDeleteConfirmPopup: boolean
  setShowDeleteConfirmPopup: Dispatch<SetStateAction<boolean>>
  setPlanToBeDeleted: Dispatch<SetStateAction<string | null>>
  handleDeletePlan: () => void
};

const PtPlansController: ControllerFN = (idUser: string | undefined) => {
  const [searching, setSearching] = useState<string>('');
  const [plansToDisplay, setPlansToDisplay] = useState<IPlansCardWithStats[]| null>(null);
  const [apiError, setApiError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState<boolean>(false);
  const [plansByUserWithStats, setPlansByUserWithStats] = useState<IPlansCardWithStats[]>([]);

  const [deletePlan, { loading: deletePlanLoading, error: deletePlanError }] = useMutation(DELETE_PLAN);
  const [planToBeDeleted, setPlanToBeDeleted] = useState<string | null>(null);
  // const { plansByUserWithStats, loading: plansByUserWithStatsLoading, error: plansByUserWithStatsError } = useGetPlansWithStatsByUserId(idUser);

  const [getPlansWithStatsByUserId,
    { loading: plansByUserWithStatsLoading, error: plansByUserWithStatsError }] = useLazyQuery(GET_PLANS_WITH_STATS_BY_USER, {
    variables: {
      idUser,
    },
  });

  const getData = () => {
    getPlansWithStatsByUserId().then((res) => {
      setPlansByUserWithStats(res.data?.plansByUserWithStats
        .sort((a: IPlansCardWithStats, b: IPlansCardWithStats) => Number(b.plan.active) - Number(a.plan.active)));
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (plansByUserWithStatsError) {
      setApiError(plansByUserWithStatsError.message);
    } else if (deletePlanError) {
      setApiError(deletePlanError.message);
    } else {
      setApiError('');
    }
  }, [plansByUserWithStatsError, deletePlanError]);

  useEffect(() => {
    const calculateLoadingValue = plansByUserWithStatsLoading && deletePlanLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [deletePlanLoading, plansByUserWithStatsLoading]);

  useEffect(() => {
    if (plansByUserWithStats) {
      setPlansToDisplay(plansByUserWithStats);
    }
  }, [plansByUserWithStats]);

  const handleDeletePlan = () => {
    if (planToBeDeleted) {
      deletePlan({
        variables: {
          idPlan: planToBeDeleted,
        },
      }).then(() => {
        setShowDeleteConfirmPopup(false);
        setPlanToBeDeleted(null);
        getData();
      });
    }
  };

  const resetSearch = () => {
    setSearching('');
    setPlansToDisplay(plansByUserWithStats);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (plansByUserWithStats) {
      setPlansToDisplay([...plansByUserWithStats.filter((plan) => plan?.plan?.name?.includes(newValue))]);
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (plansByUserWithStats) {
      setPlansToDisplay([...plansByUserWithStats.filter((plan) => plan?.plan?.name?.includes(searching))]);
    }
  };

  return {
    loading,
    searching,
    onChange,
    plansToDisplay,
    resetSearch,
    apiError,
    setApiError,
    showDeleteConfirmPopup,
    setShowDeleteConfirmPopup,
    handleDeletePlan,
    setPlanToBeDeleted,
    onClickSearchButton,
  };
};

export default PtPlansController;
