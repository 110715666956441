import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { AuthContext } from '../../../../context/auth';
import { useGetMuscleGroup } from '../../../../apollo/hooks/useGetMuscleGroup';
import { UPDATE_MUSCLE_GROUP } from '../../../../apollo/mutations/updateMuscleGroup';

type ControllerFN = (muscleGroupId: string | undefined) => {
  name: string,
  setName: Dispatch<SetStateAction<string>>,
  onEditMuscleGroup: ()=>void
  setIcon: Dispatch<SetStateAction<string>>,
  icon: string
  formError: string
  setFormError: Dispatch<SetStateAction<string>>,
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PTAddMuscleGroupsController: ControllerFN = (muscleGroupId) => {
  const [name, setName] = useState<string>('');
  const [icon, setIcon] = useState<string>('no');
  const [formError, setFormError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<string>('');

  const { muscleGroup, loading: loadingMgById, error: errorMgById } = useGetMuscleGroup(muscleGroupId);

  const navigate = useNavigate();

  useEffect(() => {
    if (muscleGroup) {
      setName(muscleGroup.name || '');
      setIcon(muscleGroup.icon || '');
    }
  }, [muscleGroup]);

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const [updateMuscleGroup, { loading: updateMuscleGroupLoading, error }] = useMutation(UPDATE_MUSCLE_GROUP);

  useEffect(() => {
    if (updateMuscleGroupLoading || loadingMgById) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateMuscleGroupLoading, loadingMgById]);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else if (errorMgById) {
      setApiError(errorMgById.message);
    } else {
      setApiError('');
    }
  }, [error]);

  const { MUSCLE_GROUPS_PT_PATH } = PATH_CONSTANTS;

  const onEditMuscleGroup = () => {
    if (name !== '') {
      const muscleGroupInput = {
        name,
        icon,
        pt: id,
      };
      updateMuscleGroup({
        variables: {
          id: muscleGroupId,
          muscleGroupInput,
        },
      }).then(() => {
        navigate(`${MUSCLE_GROUPS_PT_PATH}/`);
      });
    } else {
      setFormError('You must insert a name');
    }
  };

  return {
    name,
    setName,
    onEditMuscleGroup,
    setIcon,
    icon,
    formError,
    setFormError,
    loading,
    setApiError,
    apiError,
  };
};

export default PTAddMuscleGroupsController;
