import { ApolloError, gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { IMacronutrients } from '../../common/interfaces/User';
import { AuthContext } from '../../context/auth';

const GET_MACRONUTRIENTS = gql`
    query user($id: ID!){
        user(id: $id) {
            macronutrients{
                kcals
                proteins
                carbs
                fats
            }
        }
    }
`;

type ControllerFN = () => {
    macronutrients: IMacronutrients | undefined;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetLoggedUserMacronutrients: ControllerFN = () => {
  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  const { data, loading, error } = useQuery(GET_MACRONUTRIENTS, {
    variables: { id },
  });
  const macronutrients = data?.user?.macronutrients;
  return { loading, macronutrients, error };
};
