import React, { FunctionComponent } from 'react';
import SlidingPopup from '../SlidingPopup/SlidingPopup';
import Text from '../CustomTextFormatter/Text';

interface OwnProps {
    open: boolean
    handleClose: () => void
    title: string
    caption: string
    actions: boolean
}

type Props = OwnProps;

const ErrorPopup: FunctionComponent<Props> = ({
  open, handleClose, title, caption, actions,
} : Props) => (
  <SlidingPopup
    open={open}
    handleClose={handleClose}
    actions={actions || false}
    additionalStyle={
                {
                  width: '60%',
                  position: 'relative',
                  background: '#1B1833',
                  padding: '1.5rem 1.8rem 1.5rem 1.8rem',
                  borderRadius: '1rem 1rem 1rem 1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: 0,
                  gap: '2rem',
                }
            }
  >
    <>
      <Text type="body1">{title}</Text>
      <Text type="body2">{caption}</Text>
    </>
  </SlidingPopup>
);

export default ErrorPopup;
