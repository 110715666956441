import { ApolloError, gql, useQuery } from '@apollo/client';
import { IPlansCardResponse } from '../../common/interfaces/Plans';

const GET_PLAN = gql`
    query plan($id: ID!){
        plan(id: $id) {
            _id
            name
            startDate
            endDate
            active
            lastTrainingDate
            lastTrainingNote
            lastTrainingRating
            exercise {
                _id
                recovery
                exercise{
                    _id
                    name
                    howTo
                    muscleGroups{
                        _id
                        muscleGroup{
                            name
                            icon
                            _id
                        }
                        percentage
                    }
                }
                loads{
                    date
                    set {
                        noSet
                        loadInKg
                    }
                }
                setRep {
                    set
                    rep
                    type
                }
                day
                note
            }
            phase
            owner {
                _id
            }
            days
            caption
            volumes {
                trainingSets
                volume
                muscleGroup {
                    _id
                }
            }
        }
    }
`;

type ControllerFN = (id: string | undefined) => {
    planById: IPlansCardResponse;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetPlan: ControllerFN = (id: string | undefined) => {
  const { data, loading, error } = useQuery(GET_PLAN, {
    variables: { id: id || '' },
  });

  const planById = data?.plan;
  return { loading, planById, error };
};
