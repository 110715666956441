import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';

export const CssTextField = styled(TextField)`
  & label.Mui-focused {
    color: white!important; 
  },
  & .MuiInputLabel-root {
    color: white!important;
  },
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: rgba(255,255,255,0.2)!important;
    },
    & textarea {
     color: rgba(255,255,255,1)!important;
    },
    &:hover fieldset {
      border-color: rgba(255,255,255,0.2)!important;
    },
    &.Mui-focused fieldset {
      border-color: rgba(255,255,255,0.2)!important;
  },
  },
  input: {
    color: white!important
  },
  textarea: {
    color: white!important
  },
  label: {
    color: white!important
  },
`;

export const CssSelect = styled(Select)`
  & label.Mui-focused {
    color: white!important 
  },
  & .MuiInputLabel-root {
    color: white!important
  },
  & .MuiSvgIcon-root {
    color: white!important
  },
  & .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255,255,255,0.2)!important;
  },
  input: {
    color: white!important
  },
  textarea: {
    color: white!important
  },
  label: {
    color: white!important
  },
`;
