/* eslint-disable consistent-return,array-callback-return */
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Text from '../../../components/CustomTextFormatter/Text';
import CustomButton from '../../../components/CustomButton/CustomButton';
import 'react-swipeable-list/dist/styles.css';
import { IconContainer } from './PTAddPlan.style';
import PTAddPlanController from './controller/PTAddPlan.controller';
import PlanInfoForm from './components/PlanInfoForm/PlanInfoForm';
import DaySelectionCard from './components/DaySelectionCard/DaySelectionCard';
import AddingExerciseDetails from './components/AddingExerciseDetails/AddingExerciseDetails';
import Divider from '../../../components/Divider/Divider';
import ViewVolumes from './components/ViewVolumes/ViewVolumes';
import TrainingSets from './components/TrainingSets/TrainingSets';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import ChooseExercise from './components/ChooseExercise/ChooseExercise';
import { pageMainCard } from '../../../common/styles/commonComponents';

interface OwnProps {}

type Props = OwnProps;

const PTAddPlan: FunctionComponent<Props> = () => {
  const { userId } = useParams();

  const {
    onAddWeeklySession,
    weeklyTrainings,
    selectedDay,
    onChangeSelectedDay,
    searching,
    onChange,
    resetSearch,
    selectedMuscleGroup,
    onSelectMuscleGroup,
    page,
    exerciseToDisplay,
    onAddExerciseToPlan,
    onDeleteExerciseFromPlan,
    onEditExerciseInPlan,
    onSaveExerciseInPlan,
    openAddExercisePopup,
    selectedExercise,
    muscleGroupsList,
    loading,
    onAddPlan,
    phaseList,
    planInput,
    onGoToSelectTrainingSets,
    onAddSupersetToExercisesInPlan,
    finalizeError,
    setFinalizeError,
    viewVolumesError,
    setViewVolumesError,
    editExerciseInPlanMode,
    handleClosePopup,
    onSubmitPlanInfo,
    planInfo,
    exerciseDetails,
    apiError,
    setApiError,
    exercisesByDayByMuscleGroup,
    setExerciseByDayByMuscleGroup,
    muscleGroupsOrder,
    setMuscleGroupsOrder,
    onClickEditExerciseToPlan,
    onSetVolumes,
    handleChangePage,
    reorderPlan,
    onClickSearchButton,
  } = PTAddPlanController(userId);

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} pt title="Crea piano" />

        {page === 1 && (
          <Card
            additionalStyle={pageMainCard}
          >
            <PlanInfoForm
              planInfo={planInfo}
              onAddWeeklySession={onAddWeeklySession}
              phaseList={phaseList}
              weeklyTrainingNumber={weeklyTrainings.length}
              onSubmitPlanInfo={onSubmitPlanInfo}
            />
          </Card>
        )}

        {page === 2 && (
          <Card
            additionalStyle={pageMainCard}
          >
            <>
              <TitleBar>
                <Text type="h2">Aggiungi esercizi</Text>
              </TitleBar>

              <Divider />

              <Box sx={{
                flexGrow: 1, height: '60vh', display: 'flex', gap: '1rem', flexDirection: 'column',
              }}
              >
                <Card
                  additionalStyle={{
                    ...pageMainCard,
                    flexGrow: 0,
                    display: 'flex',
                    minHeight: '4rem',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <DaySelectionCard
                    weeklyTrainings={weeklyTrainings}
                    setSelectedDay={onChangeSelectedDay}
                    reorderPlan={reorderPlan}
                    selectedDay={selectedDay}
                  />
                </Card>

                <ChooseExercise
                  muscleGroupsOrder={muscleGroupsOrder}
                  setMuscleGroupsOrder={setMuscleGroupsOrder}
                  searching={searching}
                  onChange={onChange}
                  resetSearch={resetSearch}
                  muscleGroupsList={muscleGroupsList}
                  selectedMuscleGroup={selectedMuscleGroup}
                  onSelectMuscleGroup={onSelectMuscleGroup}
                  onAddExerciseToPlan={onAddExerciseToPlan}
                  exerciseToDisplay={exerciseToDisplay}
                  onAddSupersetToExercisesInPlan={onAddSupersetToExercisesInPlan}
                  exercisesByDayByMuscleGroup={exercisesByDayByMuscleGroup}
                  setExerciseByDayByMuscleGroup={setExerciseByDayByMuscleGroup}
                  onClickEditExerciseToPlan={onClickEditExerciseToPlan}
                  onDeleteExerciseFromPlan={onDeleteExerciseFromPlan}
                  onClickSearchButton={onClickSearchButton}
                />

              </Box>

              <Divider />

              <IconContainer>
                <CustomButton
                  onClick={() => {
                    handleChangePage('back');
                  }}
                  label="Indietro"
                />
                <CustomButton
                  onClick={() => {
                    reorderPlan();
                    onGoToSelectTrainingSets();
                    handleChangePage('forward');
                  }}
                  label="Avanti"
                />
              </IconContainer>

            </>
          </Card>
        )}

        {page === 3 && (
          <TrainingSets
            exercisesByDayByMuscleGroup={exercisesByDayByMuscleGroup}
            onSetVolumes={onSetVolumes}
            handleChangePage={handleChangePage}
            weeklyTrainings={weeklyTrainings}
            onChangeSelectedDay={onChangeSelectedDay}
            reorderPlan={reorderPlan}
            selectedDay={selectedDay}
          />
        )}

        {page === 4 && (
          <ViewVolumes
            plan={planInput}
            handleChangePage={handleChangePage}
            onAddPlan={onAddPlan}
          />
        )}

        <AddingExerciseDetails
          openPopup={openAddExercisePopup && !!selectedExercise}
          handleClosePopup={handleClosePopup}
          exerciseDetails={exerciseDetails}
          selectedExercise={selectedExercise}
          onSaveExerciseInPlan={onSaveExerciseInPlan}
          editExerciseInPlanMode={editExerciseInPlanMode}
          onEditExerciseInPlan={onEditExerciseInPlan}
        />

        <SlidingPopup
          open={finalizeError || viewVolumesError}
          handleClose={finalizeError ? () => setFinalizeError(false) : () => setViewVolumesError(false)}
          actions
          additionalStyle={
          {
            width: '60%',
            position: 'relative',
            background: '#1B1833',
            padding: '1.5rem 1.8rem 1.5rem 1.8rem',
            borderRadius: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 0,
            gap: '2rem',
          }
        }
        >
          <>
            {viewVolumesError && (
            <>
              <Text type="h2">Aspetta!</Text>
              <Text type="body1">Devi prima scegliere delle serie allenanti</Text>
            </>
            )}
            {finalizeError && (
            <>
              <Text type="h2">Aspetta!</Text>
              <Text type="body1">Devi scegliere almeno un esercizio per ogni workout</Text>
            </>
            )}
          </>
        </SlidingPopup>

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTAddPlan;
