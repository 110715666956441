/* eslint-disable operator-linebreak */
import React, {
  ChangeEvent, FunctionComponent, useEffect, useReducer,
} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Text from '../../../components/CustomTextFormatter/Text';
import CustomSlider from '../../../components/CustomSlider/CustomSlider';
import EditAthleteController from './controller/EditAthlete.controller';
import {
  infoCardStyle, macroCardStyle, PickersContainer, sliderLabelStyle, sliderStyle,
} from './PTEditAthlete.style';
import { infoAthleteReducer } from './controller/reducer';
import StateControlledForm from '../../../components/StateControlledForm/StateControlledForm';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Divider from '../../../components/Divider/Divider';

interface OwnProps {}

type Props = OwnProps;

const PTEditAthlete: FunctionComponent<Props> = () => {
  const { athleteId } = useParams();

  const {
    measuresAccordionSliders,
    macroAccordionSliders,
    weightAccordionSliders,
    setFats,
    setCarbs,
    setProteins,
    setKcals, athleteInfo, onSubmitAthlete, userById,
    maximalAccordionSliders,
  } = EditAthleteController(athleteId);

  const [{
    email, password, name, age, startDate,
  }, dispatch] =
      // @ts-ignore
      useReducer(
        infoAthleteReducer,
        athleteInfo,
      );

  // need to be here to call dispatch
  useEffect(() => {
    if (userById) {
      setKcals(userById.macronutrients?.kcals || 0);
      setProteins(userById.macronutrients?.proteins || 0);
      setCarbs(userById.macronutrients?.carbs || 0);
      setFats(userById.macronutrients?.fats || 0);
      // @ts-ignore
      dispatch({ type: 'setEmail', payload: userById.email });
      // @ts-ignore
      dispatch({ type: 'setName', payload: userById.name });
      // @ts-ignore
      dispatch({ type: 'setAge', payload: userById.age });
      // @ts-ignore
      dispatch({ type: 'setStartDate', payload: dayjs(userById.startDate) });
    }
  }, [userById]);

  const onClickSubmitButton = () => {
    let errors = false;
    const mailValidation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!mailValidation.test(email.value) || email.value === '') {
      // @ts-ignore
      dispatch({ type: 'setErrorEmail', payload: 'This email is not valid' });
      errors = true;
    }
    if (name.value === '') {
      // @ts-ignore
      dispatch({ type: 'setErrorName', payload: 'You must choose a name' });
      errors = true;
    }
    if (Number.isNaN(age.value) || age.value === '' || age.value < 1 || age.value > 100) {
      // @ts-ignore
      dispatch({ type: 'setErrorAge', payload: 'Age must be a number between 1 and 100' });
      errors = true;
    }
    if (!errors) {
      onSubmitAthlete({
        email, password, name, age, startDate,
      });
    }
  };

  const formField = [
    {
      type: 'email',
      fieldType: 'TextField',
      placeholder: 'mariobianchi@gmail.com',
      value: email.value,
      label: 'Email',
      // @ts-ignore
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { dispatch({ type: 'setEmail', payload: e.target.value }); },
      error: email.error !== '',
      helperText: email.error,
    },
    {
      type: 'text',
      fieldType: 'TextField',
      placeholder: 'Mario Bianchi',
      value: name.value,
      label: 'Name',
      // @ts-ignore
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { dispatch({ type: 'setName', payload: e.target.value }); },
      error: name.error !== '',
      helperText: name.error,
    },
    {
      type: 'text',
      fieldType: 'TextField',
      placeholder: '25',
      value: age.value,
      label: 'Age',
      // @ts-ignore
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { dispatch({ type: 'setAge', payload: e.target.value }); },
      error: age.error !== '',
      helperText: age.error,
    },
  ];

  const dateFields = [
    {
      fieldType: 'DatePicker',
      label: 'Start Date',
      dateValue: startDate.value,
      // @ts-ignore
      handleChangeDate: (newValue: Dayjs | null) => { dispatch({ type: 'setStartDate', payload: newValue }); },
    },
  ];

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} pt title="Modifica atleta" />

        <Box sx={{
          overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '1.5rem', marginBottom: '2rem', width: '100%',
        }}
        >

          <Accordion sx={macroCardStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TitleBar>
                <Text type="h2">Measures</Text>
              </TitleBar>
            </AccordionSummary>
            <AccordionDetails>
              <>
                {measuresAccordionSliders.map((sl) => (
                  <CustomSlider
                    labelStyle={sliderLabelStyle}
                    sliderStyle={sliderStyle}
                    defaultValue={sl.defaultValue}
                    displayValue={sl.displayValue}
                    max={sl.max}
                    step={sl.step}
                    onChange={sl.onChange}
                    label={sl.label}
                    id={sl.id}
                  />
                ))}
              </>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={macroCardStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TitleBar>
                <Text type="h2">Maximal</Text>
              </TitleBar>
            </AccordionSummary>
            <AccordionDetails>
              <>
                {maximalAccordionSliders.map((sl) => (
                  <CustomSlider
                    labelStyle={sliderLabelStyle}
                    sliderStyle={sliderStyle}
                    defaultValue={sl.defaultValue}
                    displayValue={sl.displayValue}
                    max={sl.max}
                    step={sl.step}
                    onChange={sl.onChange}
                    label={sl.label}
                    id={sl.id}
                  />
                ))}
              </>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={macroCardStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TitleBar>
                <Text type="h2">Weight</Text>
              </TitleBar>
            </AccordionSummary>
            <AccordionDetails>
              <>
                {weightAccordionSliders.map((sl) => (
                  <CustomSlider
                    labelStyle={sliderLabelStyle}
                    sliderStyle={sliderStyle}
                    defaultValue={sl.defaultValue}
                    displayValue={sl.displayValue}
                    max={sl.max}
                    step={sl.step}
                    onChange={sl.onChange}
                    label={sl.label}
                    id={sl.id}
                  />
                ))}
              </>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={macroCardStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TitleBar>
                <Text type="h2">Macronutrients</Text>
              </TitleBar>
            </AccordionSummary>
            <AccordionDetails>
              <>
                {macroAccordionSliders.map((sl) => (
                  <CustomSlider
                    labelStyle={sliderLabelStyle}
                    sliderStyle={sliderStyle}
                    defaultValue={sl.defaultValue}
                    displayValue={sl.displayValue}
                    max={sl.max}
                    step={sl.step}
                    onChange={sl.onChange}
                    label={sl.label}
                  />
                ))}
              </>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded sx={infoCardStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#F9F9F9' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TitleBar>
                <Text type="h2">Account Info</Text>
              </TitleBar>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <StateControlledForm formFields={formField} />

                <PickersContainer>
                  <StateControlledForm formFields={dateFields} />
                </PickersContainer>

                <Divider />

                <CustomButton
                  onClick={onClickSubmitButton}
                  label="Modifica"
                />
              </>
            </AccordionDetails>
          </Accordion>

        </Box>
      </>
    </Layout>
  );
};

export default PTEditAthlete;
