import React from 'react';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { IMacronutrients } from '../../../../../common/interfaces/User';
import { MacronutrientsContainer } from './Macronutrients.style';

interface Props {
    macronutrients: IMacronutrients | undefined
}

export default function Macronutrients({
  macronutrients,
}: Props) {
  return (
    <MacronutrientsContainer>
      <DetailsBox label="Kcals">
        <Text type="body1">{macronutrients?.kcals.toString() || 'N/D'}</Text>
      </DetailsBox>
      <DetailsBox label="Grassi">
        <Text type="body1">{macronutrients?.fats.toString() || 'N/D'}</Text>
      </DetailsBox>
      <DetailsBox label="Carbo">
        <Text type="body1">{macronutrients?.carbs.toString() || 'N/D'}</Text>
      </DetailsBox>
      <DetailsBox label="Proteine">
        <Text type="body1">{macronutrients?.proteins.toString() || 'N/D'}</Text>
      </DetailsBox>
    </MacronutrientsContainer>
  );
}
