import React, { Dispatch, SetStateAction } from 'react';
import ExerciseTrainInfo from '../ExerciseTrainInfo/ExerciseTrainInfo';
import Text from '../../../../../components/CustomTextFormatter/Text';
import SetLoads from '../SetLoads/SetLoads';
import TrainingIconsGroup from '../TrainingIconsGroup/TrainingIconsGroup';
import { IExerciseInPlan } from '../../../../../common/interfaces/Exercise';
import {
  boldTextStyle,
  Container,
  ExerciseCompletedContainer,
  ExerciseInfoContainer,
  noteTextStyle,
} from './BelowTrainingPage.style';

interface Props {
    exerciseCompleted: boolean
    currentSet: number
    currentExercise: IExerciseInPlan | undefined,
    onChangeSlider: (e: React.ChangeEvent<HTMLInputElement>, id: string | number, value: number) => void
    recover: boolean
    setExpandExerciseList: Dispatch<SetStateAction<boolean>>
    onClickTimerIcon: () => void
    onClickStopTimer: () => void
    setOpenConfirmSkipDialog:Dispatch<SetStateAction<boolean>>
    onClickSendLoads:() => void
    }

export default function BelowTrainingPage({
  exerciseCompleted, currentSet, currentExercise, onChangeSlider, recover, setExpandExerciseList, onClickTimerIcon,
  setOpenConfirmSkipDialog, onClickStopTimer, onClickSendLoads,
}: Props) {
  return (
    <Container style={{
      height: exerciseCompleted ? '70%' : '40%',
    }}
    >
      {!exerciseCompleted && (
      <ExerciseTrainInfo currentSet={currentSet} currentExercise={currentExercise} />
      )}

      <ExerciseInfoContainer>
        <Text additionalStyle={boldTextStyle} type={exerciseCompleted ? 'body1' : 'h2'}>
          {currentExercise?.exercise.name || ''}
        </Text>

        {exerciseCompleted && (
          <ExerciseCompletedContainer>
            <Text additionalStyle={boldTextStyle} type="h2">
              Esercizio completato!
            </Text>
            {currentExercise?.setRep?.map((sr, index) => (
              <SetLoads index={index} onChangeSlider={onChangeSlider} />
            ))}
          </ExerciseCompletedContainer>
        )}

        {!exerciseCompleted && (
          <Text additionalStyle={noteTextStyle} type="body1">
            {currentExercise?.note || ''}
          </Text>
        )}
      </ExerciseInfoContainer>

      <TrainingIconsGroup
        exerciseCompleted={exerciseCompleted}
        recover={recover}
        setExpandExerciseList={setExpandExerciseList}
        onClickTimerIcon={onClickTimerIcon}
        onClickSendLoads={onClickSendLoads}
        onClickStopTimer={onClickStopTimer}
        setOpenConfirmSkipDialog={setOpenConfirmSkipDialog}
      />

    </Container>
  );
}
