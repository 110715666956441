/* eslint-disable array-callback-return */
import React, { FunctionComponent } from 'react';
import { Avatar, Box, Grid } from '@mui/material';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Text from '../../../components/CustomTextFormatter/Text';
import CustomSlider from '../../../components/CustomSlider/CustomSlider';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { CssTextField } from '../../../components/Form/Form.style';
import PTAddExerciseController from './controller/PTAddExercise.controller';
import { sliderLabelStyle, TextFieldContainer } from './PTAddExercise.style';
import { IMuscleGroup } from '../../../common/interfaces/MuscleGroup';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { IMuscleGroupsInExercise } from '../../../common/interfaces/Exercise';
import { pageMainCard } from '../../../common/styles/commonComponents';
import DetailsBox from '../../../components/DetailsBox/DetailsBox';
import useGetMuscleGroupIcons from '../../../hooks/useGetMuscleGroupIcons';
import Divider from '../../../components/Divider/Divider';

interface OwnProps {}

type Props = OwnProps;

const PTAddExercise: FunctionComponent<Props> = () => {
  const {
    loading,
    muscleGroupsList,
    selectedMuscleGroups,
    setSelectedMuscleGroups,
    muscleGroupsInExercise,
    setMuscleGroupsInExercise,
    name,
    setName,
    howTo,
    setHowTo,
    setYoutubeVideo,
    youtubeVideo,
    onSaveExercise,
    formError,
    setFormError,
    apiError,
    setApiError,
  } = PTAddExerciseController();

  const onSelectMuscleGroup = (newMuscleGroup: IMuscleGroup) => {
    let mg = [];
    if (selectedMuscleGroups.find((element) => element._id === newMuscleGroup._id)) {
      mg = selectedMuscleGroups.filter((m) => m._id !== newMuscleGroup._id);
    } else {
      mg.push(...selectedMuscleGroups, newMuscleGroup);
    }
    setSelectedMuscleGroups(mg);
  };

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} pt title="Aggiungi esercizio" />
        <Card
          additionalStyle={pageMainCard}
        >
          <>
            <TitleBar>
              <Text type="h2">Informazioni esercizio</Text>
            </TitleBar>

            <Box sx={{ overflow: 'auto', height: '60vh', flexGrow: 1 }}>

              <TextFieldContainer>
                <CssTextField
                  sx={{ input: { color: 'white!important' } }}
                  InputLabelProps={{
                    style: { color: '#fff!important' },
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="Name"
                  label="Nome"
                  placeholder="Panca piana"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <CssTextField
                  sx={{ input: { color: 'white!important' } }}
                  InputLabelProps={{
                    style: { color: '#fff!important' },
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  name="HowTo"
                  label="Istruzioni"
                  placeholder="Sdraiarsi su una panca piana e inarcare la schiena..."
                  type="text"
                  value={howTo}
                  onChange={(e) => setHowTo(e.target.value)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <CssTextField
                  sx={{ input: { color: 'white!important' } }}
                  InputLabelProps={{
                    style: { color: '#fff!important' },
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="youtubeVideo"
                  label="Video da Youtube"
                  placeholder="https://www.youtube.com/..."
                  type="text"
                  value={youtubeVideo}
                  onChange={(e) => setYoutubeVideo(e.target.value)}
                />
              </TextFieldContainer>

              <Grid container spacing={2} sx={{ marginTop: 0 }}>
                {muscleGroupsList?.map((muscleGroup) => {
                  const selected = !!selectedMuscleGroups.find((element) => element._id === muscleGroup._id);
                  return (
                    <Grid key={muscleGroup._id} item xs={4}>
                      <DetailsBox
                        label={muscleGroup.name}
                        onClick={() => onSelectMuscleGroup(muscleGroup)}
                        additionalStyle={{
                          background: selected
                            ? 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(89,89,89,1) 100%)' : 'transparent',
                        }}
                      >
                        <Avatar sx={{ background: 'transparent' }}>
                          {/* @ts-ignore */}
                          { useGetMuscleGroupIcons(muscleGroup.icon || '').selectedIcon}
                        </Avatar>
                      </DetailsBox>
                    </Grid>
                  );
                })}
              </Grid>

              <Divider />

              {selectedMuscleGroups?.map((mg) => (
                <CustomSlider
                  key={mg._id}
                  labelStyle={sliderLabelStyle}
                  defaultValue={0}
                  max={100}
                  step={5}
                  onChange={(e: any, id: any, value: number) => {
                    const currentMuscleGroups = [...muscleGroupsInExercise];
                    const percentageAlreadyAddedIndex = currentMuscleGroups
                      .findIndex((existingMg: IMuscleGroupsInExercise) => existingMg?.muscleGroup?._id === mg._id);
                    const obj = {
                      muscleGroup: mg,
                      percentage: value || 0,
                    };
                    if (percentageAlreadyAddedIndex !== -1) {
                      currentMuscleGroups[percentageAlreadyAddedIndex] = obj;
                    } else {
                      currentMuscleGroups.push(obj);
                    }
                    setMuscleGroupsInExercise(currentMuscleGroups);
                  }}
                  label={`${mg.name}`}
                />
              ))}

            </Box>

            <CustomButton
              onClick={onSaveExercise}
              label="Aggiungi"
            />

          </>
        </Card>

        <ErrorPopup
          open={formError !== ''}
          handleClose={() => setFormError('')}
          title="Aspetta!"
          caption={formError}
          actions
        />

        <ErrorPopup
          open={apiError !== ''}
          handleClose={() => setApiError('')}
          title="An error occurred :("
          caption={apiError}
          actions
        />

      </>
    </Layout>
  );
};

export default PTAddExercise;
