import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IUser } from '../../../../common/interfaces/User';
import { GET_ATHLETES_BY_PT } from '../../../../apollo/hooks/useGetAthletes';
import { UPDATE_TRAINING_PHASE } from '../../../../apollo/mutations/updateTrainingPhase';
import { AuthContext } from '../../../../context/auth';
import { DELETE_ATHLETE } from '../../../../apollo/mutations/deleteAthlete';

type ControllerFN = () => {
    searching: string;
    onChange: (e: any) => void
    onClickSearchButton: () => void
    resetSearch: () => void
    usersToDisplay: IUser[] | null
    loading: boolean
    apiError: string
    setApiError: Dispatch<SetStateAction<string>>
    setOpenDeletePopup: Dispatch<SetStateAction<boolean>>
    setAthleteToBeHandled: Dispatch<SetStateAction<IUser| Record<string, unknown>>>
    openDeletePopup: boolean
    onDeleteAthlete: () => void
};

const PTAthletesController: ControllerFN = () => {
  const [searching, setSearching] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [athletes, setAthletes] = useState<IUser[]| null>(null);
  const [usersToDisplay, setUsersToDisplay] = useState<IUser[]| null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [athleteToBeHandled, setAthleteToBeHandled] = useState < IUser | Record<string, unknown> >({});

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const [getAthletes, { loading: athletesLoading, error }] = useLazyQuery(GET_ATHLETES_BY_PT, {
    variables: {
      ptId: id,
    },
  });
  const [updateAthlete, { loading: updateAthleteLoading, error: updateAthleteError }] = useMutation(UPDATE_TRAINING_PHASE);
  const [deleteAthlete, { loading: deleteAthleteLoading, error: deleteAthleteError }] = useMutation(DELETE_ATHLETE);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else if (updateAthleteError) {
      setApiError(updateAthleteError.message);
    } else if (deleteAthleteError) {
      setApiError(deleteAthleteError.message);
    } else {
      setApiError('');
    }
  }, [error, updateAthleteError, deleteAthleteError]);

  useEffect(() => {
    const calculateLoadingValue = athletesLoading && updateAthleteLoading && deleteAthleteLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [athletesLoading, updateAthleteLoading, deleteAthleteLoading]);

  const getData = () => {
    getAthletes().then((res) => {
      setAthletes(res.data?.athletesByPtId);
      setUsersToDisplay(res?.data?.athletesByPtId || []);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const resetSearch = () => {
    setSearching('');
    setUsersToDisplay(athletes);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (athletes) {
      setUsersToDisplay([...athletes.filter((at: IUser) => at?.name?.includes(newValue))]);
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (athletes) {
      setUsersToDisplay([...athletes.filter((at: IUser) => at?.name?.includes(searching))]);
    }
  };

  const onDeleteAthlete = () => {
    setOpenDeletePopup(false);
    deleteAthlete({
      variables: {
        idUser: athleteToBeHandled._id,
      },
    }).then(() => {
      setAthleteToBeHandled({});
      getData();
    });
  };

  return {
    searching,
    onChange,
    resetSearch,
    usersToDisplay,
    loading,
    apiError,
    setApiError,
    openDeletePopup,
    setOpenDeletePopup,
    onDeleteAthlete,
    setAthleteToBeHandled,
    onClickSearchButton,
  };
};

export default PTAthletesController;
