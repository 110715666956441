import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Text from '../../../components/CustomTextFormatter/Text';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { CssTextField } from '../../../components/Form/Form.style';
import PTAddTrainingPhaseController from './controller/PTAddTrainingPhase.controller';
import { sliderLabelStyle, sliderStyle } from './PTAddTrainingPhase.style';
import CustomSlider from '../../../components/CustomSlider/CustomSlider';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { IFormFields } from '../../../components/StateControlledForm/interfaces/Form';
import { pageMainCard } from '../../../common/styles/commonComponents';

interface OwnProps {}

type Props = OwnProps;

const PTAddTrainingPhase: FunctionComponent<Props> = () => {
  const {
    onSaveTrainingPhase, addTrainingPhaseForm,
    onChangeEta, formError, setFormError, loading, apiError,
    setApiError,
  } = PTAddTrainingPhaseController();

  if (loading) {
    return (
      <LoaderSpinner />
    );
  }

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} pt title="Aggiungi fase" />
        <Card
          additionalStyle={pageMainCard}
        >
          <>
            <Box sx={{ overflow: 'auto', height: '60vh', flexGrow: 1 }}>

              <TitleBar>
                <Text type="h2">Fase di allenamento</Text>
              </TitleBar>
              {addTrainingPhaseForm?.map((formField: IFormFields) => (
                <Box key={formField.label} sx={{ position: 'relative' }}>
                  <CssTextField
                    sx={{ input: { color: 'white!important' } }}
                    InputLabelProps={{
                      style: { color: '#fff!important' },
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name={formField.label}
                    label={formField.label}
                    placeholder={formField.placeholder}
                    type={formField.type}
                    value={formField.value}
                    onChange={formField.onChange}
                  />
                </Box>
              ))}
              <CustomSlider
                labelStyle={sliderLabelStyle}
                sliderStyle={sliderStyle}
                defaultValue={1}
                min={1}
                max={50}
                step={1}
                onChange={(e, id, value) => { onChangeEta(value); }}
                label="Durata in settimane"
              />
            </Box>

            <CustomButton
              label="Aggiungi"
              onClick={onSaveTrainingPhase}
            />
          </>
        </Card>

        <ErrorPopup
          open={formError !== ''}
          handleClose={() => setFormError('')}
          title="Wait!"
          caption={formError}
          actions
        />

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTAddTrainingPhase;
