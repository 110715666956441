import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const AverageLoadPerExerciseStat = styled(Box)`
  gap: 1rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`;

export const AverageLoadsContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  `;

export const StatsContainer = styled(Box)`
  height: 40%;
  width: calc(100% - 3.6rem);
  background: linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%);
  padding: 1.5rem 1.8rem 1.5rem 1.8rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  gap: 3rem;
`;

export const iconStyle = {
  color: '#F9F9F9',
};
