import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { AuthContext } from '../../../../context/auth';
import { useGetTrainingPhasesByPtId } from '../../../../apollo/hooks/useGetTrainingPhasesByPtId';
import { UPDATE_TRAINING_PHASE } from '../../../../apollo/mutations/updateTrainingPhase';
import { IFormFields } from '../../../../components/StateControlledForm/interfaces/Form';
import { GET_TRAINING_PHASES_BY_ID } from '../../../../apollo/hooks/useGetTrainingPhaseById';

type ControllerFN = (trainingPhaseId: string | undefined) => {
  onSaveTrainingPhase: ()=>void,
  addTrainingPhaseForm: IFormFields[]
  onChangeEta: (value: number) => void
  formError: string
  setFormError: Dispatch<SetStateAction<string>>
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
  eta: number
};

const PTEditTrainingPhaseController: ControllerFN = (trainingPhaseId) => {
  const { PHASES_PT_PATH } = PATH_CONSTANTS;

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [period, setPeriod] = useState<string>('');
  const [eta, setEta] = useState<number>(1);
  const [idTrainingPhasesSet, setIdTrainingPhasesSet] = useState<string>('');
  const [formError, setFormError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<string>('');

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const { trainingPhasesLoading, trainingPhases } = useGetTrainingPhasesByPtId(id);

  const [trainingPhaseById, { loading: trainingPhaseLoading, error: errorTrainingPhaseById }] = useLazyQuery(GET_TRAINING_PHASES_BY_ID, {
    variables: { idTrainingPhasesSet, idTrainingPhase: trainingPhaseId },
  });

  const [updateTrainingPhase, { loading: updateTrainingPhaseLoading, error: updateTrainingPhaseError }] = useMutation(UPDATE_TRAINING_PHASE);

  useEffect(() => {
    if (trainingPhases?._id) {
      setIdTrainingPhasesSet(trainingPhases._id);
    }
  }, [trainingPhases]);

  useEffect(() => {
    if (idTrainingPhasesSet !== '') {
      trainingPhaseById().then((res) => {
        setName(res.data.trainingPhaseById?.name || '');
        setDescription(res.data.trainingPhaseById?.description || '');
        setPeriod(res.data.trainingPhaseById?.period || '');
        setEta(res.data.trainingPhaseById?.eta || 1);
      });
    }
  }, [idTrainingPhasesSet]);

  useEffect(() => {
    if (updateTrainingPhaseError) {
      setApiError(updateTrainingPhaseError.message);
    } else if (errorTrainingPhaseById) {
      setApiError(errorTrainingPhaseById.message);
    } else {
      setApiError('');
    }
  }, [updateTrainingPhaseError, errorTrainingPhaseById]);

  useEffect(() => {
    const calculateLoadingValue = updateTrainingPhaseLoading && trainingPhasesLoading && trainingPhaseLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateTrainingPhaseLoading, trainingPhasesLoading, trainingPhaseLoading]);

  const onChangeEta = (value: number) => {
    if (!Number.isNaN(value)) {
      setEta(value);
    }
  };

  const addTrainingPhaseForm: IFormFields[] = [
    {
      label: 'Name',
      placeholder: 'Fase 1',
      type: 'text',
      value: name,
      fieldType: 'TextField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value),
    },
    {
      label: 'Descrizione',
      placeholder: 'Carico progressivo',
      type: 'text',
      value: description,
      fieldType: 'TextField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value),
    },
    {
      label: 'Periodo',
      placeholder: 'Competitivo',
      type: 'text',
      value: period,
      fieldType: 'TextField',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPeriod(e.target.value),
    },
  ];

  const onSaveTrainingPhase = () => {
    if (name !== '' && period !== '' && description !== '') {
      const newPhase = {
        name,
        description,
        period,
        eta,
      };

      if (trainingPhases?.phases && trainingPhases?.phases?.length > 0) {
        const trainingPhaseToEdit = trainingPhases.phases?.findIndex((tp) => tp._id === trainingPhaseId);
        if (trainingPhaseToEdit > -1) {
          const updatedPhases = [...trainingPhases.phases.map((tp) => ({
            name: tp.name,
            description: tp.description,
            period: tp.period,
            eta: tp.eta,
          }))];
          updatedPhases[trainingPhaseToEdit] = { ...newPhase };
          const phaseInput = {
            phases: updatedPhases,
            pt: id,
          };
          updateTrainingPhase({
            variables: {
              phaseInput,
              idPhase: trainingPhases._id,
            },
          }).then(() => {
            navigate(`${PHASES_PT_PATH}/`);
          });
        }
      }
    } else {
      setFormError('You need to fill all the fields before continue');
    }
  };

  return {
    loading,
    setApiError,
    apiError,
    setFormError,
    formError,
    name,
    setName,
    onSaveTrainingPhase,
    addTrainingPhaseForm,
    onChangeEta,
    eta,
  };
};

export default PTEditTrainingPhaseController;
