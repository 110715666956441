import React from 'react';
import { Box, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { TEXT_FIELD } from './constants';
import { IFormFields } from './interfaces/Form';
import Text from '../CustomTextFormatter/Text';
import { CssTextField } from './Form.style';
import CustomButton from '../CustomButton/CustomButton';

interface Props {
  formFields: IFormFields[],
  formik: FormikProps<any>,
  buttonLabel:string,
  additionalStyle: any,
  additionalContainerStyle?: any,
  additionalInputStyle: any,
  additionalButtonStyle?: any
}

export default function Form(
  {
    formFields, formik, buttonLabel, additionalStyle, additionalInputStyle, additionalContainerStyle, additionalButtonStyle,
  } : Props,
) {
  return (
    <form onSubmit={formik.handleSubmit}>
      {formFields.map(({
        id, name, label, type, fieldType, error,
      }) => {
        if (fieldType === TEXT_FIELD) {
          return (
            <Box
              key={id}
              sx={{
                flexGrow: 1, position: 'relative', ...additionalContainerStyle,
              }}
            >
              <CssTextField
                sx={additionalStyle}
                InputLabelProps={additionalInputStyle}
                variant="outlined"
                margin="normal"
                fullWidth
                name={name}
                label={label}
                type={type}
                value={formik.values[id]}
                onChange={formik.handleChange}
                error={error}
                helperText={<Text type="body1">{formik?.errors[id]?.toString() || ''}</Text>}
              />
            </Box>
          );
        }
        return (
          <TextField
            key={id}
            fullWidth
            id={id}
            name={name}
            label={label}
            type={type}
            value={formik.values[id]}
            onChange={formik.handleChange}
            error={error}
            helperText={<Text type="body1">{formik?.errors[id]?.toString() || ''}</Text>}
          />
        );
      })}
      {/* <Box sx={{ */}
      {/*  display: 'flex', justifyContent: 'flex-end', ...additionalButtonStyle, */}
      {/* }} */}
      {/* > */}
      {/* <Button */}
      {/*  sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'white', width: 'fit-content' }} */}
      {/*  variant="contained" */}
      {/*  fullWidth */}
      {/*  type="submit" */}
      {/* > */}
      {/*  {buttonLabel} */}
      {/* </Button> */}
      <CustomButton type="submit" label={buttonLabel} />
      {/* </Box> */}
    </form>
  );
}
