import React, { FunctionComponent } from 'react';
import Card from '../../../../../components/Card/Card';
import Text from '../../../../../components/CustomTextFormatter/Text';
import { cardStyle, taskStyle, textStyle } from './HomePageTasks.style';

interface OwnProps {}

type Props = OwnProps;

const HomePageTasks: FunctionComponent<Props> = () => (
  <Card
    additionalStyle={cardStyle}
  >
    <>
      <Text type="h3" additionalStyle={textStyle}>
        Agenda
      </Text>
      <Text type="body1" additionalStyle={taskStyle}>
        Niente da fare oggi! :)
      </Text>
    </>
  </Card>
);

export default HomePageTasks;
