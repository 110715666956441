import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';

export const CalendarFrame = styled(Button)`
  width: 2rem!important;
  height: 2.3rem!important;
  min-width: 2rem!important;
  min-height: 2.3rem!important;
  border: 0.18rem solid #f9f9f9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 0.5rem;
  `;

export const CalendarBar = styled(Box)`
  width: 70%;
  border-top: 0.4rem solid #f9f9f9;
  position: absolute;
  top: 0.35rem;
  border-radius: 1rem;
  `;

export const CalendarLeftDetail = styled(Box)`
  width: 0.2rem;
  border-top: 0.5rem solid #f9f9f9;
  position: absolute;
  top: -0.4rem;
  left: 0.4rem;
  `;

export const CalendarRightDetail = styled(Box)`
  width: 0.2rem;
  border-top: 0.5rem solid #f9f9f9;
  position: absolute;
  top: -0.4rem;
  right: 0.4rem;
  `;
