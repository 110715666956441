import React from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Text from '../CustomTextFormatter/Text';
import DetailsBox from '../DetailsBox/DetailsBox';
import { ButtonContainer, detailsBoxStyle, textStyle } from './ConfirmDialog.style';

interface Props {
    title: string
    subtitle: string
    undoFunction: () => void
    confirmFunction: () => void
}

export default function ConfirmDialog({
  title, subtitle, undoFunction, confirmFunction,
}: Props) {
  return (
    <>
      <Text additionalStyle={textStyle} type="h2">{title}</Text>
      <Text type="body1">{subtitle}</Text>
      <ButtonContainer>
        <DetailsBox
          additionalStyle={detailsBoxStyle}
          label=""
        >
          <UndoIcon onClick={undoFunction} />
        </DetailsBox>
        <DetailsBox
          additionalStyle={detailsBoxStyle}
          label=""
        >
          <SkipNextIcon onClick={confirmFunction} />
        </DetailsBox>
      </ButtonContainer>
    </>
  );
}
