/* eslint-disable react/jsx-props-no-spreading */
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import * as React from 'react';
import Text from '../CustomTextFormatter/Text';
import { linearProgressStyle, Wrapper } from './LinearProgress.style';

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number } & {unit: string},
) {
  return (
    <Wrapper>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          sx={linearProgressStyle}
          variant="determinate"
          {...props}
          value={props.value > 100 ? 100 : props.value}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Text type="body1">
          {`${Math.round(
            props.value,
          )}${props.unit}`}
        </Text>
      </Box>
    </Wrapper>
  );
}
