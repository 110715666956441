import { ApolloError, gql, useQuery } from '@apollo/client';
import { IPlansCardWithStats } from '../../common/interfaces/Plans';

export const GET_PLANS_WITH_STATS_BY_USER = gql`
    query plansByUserWithStats($idUser: ID!){
        plansByUserWithStats(idUser: $idUser) {
            plan {
                _id
                name
                startDate
                endDate
                active
                caption
                days
            }
            planStats {
                muscleGroup
                percentage
                icon
            }
        }
    }
`;

type ControllerFN = (idUser:string | undefined) => {
  plansByUserWithStats: IPlansCardWithStats[];
  loading: boolean;
    error: ApolloError | undefined
};

export const useGetPlansWithStatsByUserId: ControllerFN = (idUser: string | undefined) => {
  const { data, loading, error } = useQuery(GET_PLANS_WITH_STATS_BY_USER, {
    variables: { idUser: idUser || '' },
  });

  const plansByUserWithStats = data?.plansByUserWithStats
    .sort((a: IPlansCardWithStats, b: IPlansCardWithStats) => Number(b.plan.active) - Number(a.plan.active));
  return { loading, plansByUserWithStats, error };
};
