export const phaseInfoCard = {
  background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
  width: '100%',
  borderRadius: '1rem!important',
  height: '80vh',
};

export const sliderContainerStyle = {
  display: 'flex', alignItems: 'center', gap: '2.5rem', margin: '2.5rem',
};

export const sliderLabelStyle = { color: '#F9F9F9' };

export const sliderStyle = { color: '#F9F9F9' };
