import React from 'react';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { IPlansCardWithStats } from '../../common/interfaces/Plans';
import Text from '../CustomTextFormatter/Text';
import Card from '../Card/Card';
import {
  cardContentStyle, cardStyle, chartCard, planCaption, StyledChip, validityRangeStyle,
} from './PlanCard.style';
import { PATH_CONSTANTS } from '../../helper/constants';
import { DoughnutChart } from '../Charts/Doughnut/DoughnutChart';
import PlanCardController from './controller/planCard.controller';

interface Props {
  plan: IPlansCardWithStats,
  additionalCardStyle?: any,
  pt?: boolean,
  userId?: string | undefined,
}

export default function PlanCard({
  plan, additionalCardStyle, pt, userId,
} : Props) {
  const { data, options } = PlanCardController(plan.planStats);
  const navigate = useNavigate();
  const { PLANS_PT_PATH, PLANS_PATH } = PATH_CONSTANTS;

  return (
    <Card
      additionalStyle={{ ...cardStyle, ...additionalCardStyle }}
      onClick={pt ? () => navigate(`${PLANS_PT_PATH}/${userId}/${plan.plan._id}`) : () => navigate(`${PLANS_PATH}/${plan.plan._id}`)}
    >
      <Stack
        direction="column"
        justifyContent="space-evenly"
        spacing={2}
      >
        <Text type="body1" additionalStyle={validityRangeStyle}>
          {`From ${format(new Date(plan.plan.startDate), 'dd/MM/yyyy')} to ${format(new Date(plan.plan.endDate), 'dd/MM/yyyy')}`}
        </Text>
        <StyledChip size="small" label={`${plan.plan.days} workout settimanali`} />
        <Text
          type="body1"
          additionalStyle={planCaption}
        >
          {plan.plan.caption}
        </Text>
        <Card additionalStyle={chartCard} cardContentStyle={cardContentStyle}>
          <DoughnutChart options={options} data={data} />
        </Card>
      </Stack>
    </Card>

  );
}
