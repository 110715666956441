import React, { FunctionComponent, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Fade, JackInTheBox } from 'react-awesome-reveal';
import { SplashBox, typographyStyle } from './SplashScreen.style';
import theme from '../../helper/theme';
import Text from '../CustomTextFormatter/Text';
import { ReactComponent as Loader } from '../../asset/icons/loader1.svg';

interface OwnProps {}

type Props = OwnProps;

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

const SplashScreen: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await sleep(3000);
      navigate('/dashboard');
    })();
  }, []);

  return (
    <SplashBox theme={theme}>
      <JackInTheBox delay={1000}>
        <Box sx={{ display: 'flex' }}>
          <Text type="h1" additionalStyle={typographyStyle}>Workout</Text>
          <Text type="h1" additionalStyle={typographyStyle}>  We</Text>
        </Box>
      </JackInTheBox>

      <Fade>
        <Loader style={{ width: '15rem', height: '10rem' }} />
      </Fade>

    </SplashBox>
  );
};

export default SplashScreen;
