/* eslint-disable no-param-reassign */
import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { IPlansCardWithStats } from '../../../../common/interfaces/Plans';
import { useGetPlansWithStatsByUserIdAndPlanId } from '../../../../apollo/hooks/useGetPlanWithStatsByUserIdAndPlanId';
import { AuthContext } from '../../../../context/auth';
import { groupPlanByDayAndMuscleGroup } from '../../../../helper/utility';

// eslint-disable-next-line no-unused-vars
type ControllerFN = (planId : string | undefined) => {
  planByUserWithStats: IPlansCardWithStats;
  loading: boolean;
  exercisesByDay: any;
  setSession: Dispatch<SetStateAction<string>>;
  session: string;
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PlanController: ControllerFN = (planId : string | undefined) => {
  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  const { planByUserWithStats, loading: planByUserWithStatsLoading, error } = useGetPlansWithStatsByUserIdAndPlanId(planId, id);
  const [exercisesByDay, setExerciseByDay] = useState<Record<string, unknown>>({});
  const [session, setSession] = useState<string>('1');

  const [loading, setLoading] = useState<boolean>(true);

  const [apiError, setApiError] = useState<string>('');

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  useEffect(() => {
    const calculateLoadingValue = planByUserWithStatsLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [planByUserWithStatsLoading]);

  useEffect(() => {
    if (planByUserWithStats) {
      setExerciseByDay(groupPlanByDayAndMuscleGroup(planByUserWithStats));
    }
  }, [planByUserWithStats]);

  return {
    planByUserWithStats, loading, exercisesByDay, setSession, session, apiError, setApiError,
  };
};

export default PlanController;
