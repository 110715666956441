/* eslint-disable no-nested-ternary */
import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import PlanStats from './components/PlanStats/PlanStats';
import StatsController from './controller/stats.controller';
import ExercisesController from './controller/exercises.controller';
import AthleteStats from './components/AthleteStats/AthleteStats';
import ExerciseStats from './components/ExerciseStats/ExerciseStats';
import LineChart from '../../../components/Charts/Line/LineChart';
import ExerciseStatsSelectorButton from './components/ExerciseStats/ExerciseStatsSelectorButton';
import Text from '../../../components/CustomTextFormatter/Text';
import { StatToDisplayEnum } from '../../../common/enums/StatsToDisplayEnum';
import { AverageLoadPerExerciseStat, iconStyle, StatsContainer } from './Reports.style';
import UserStatsController from './controller/user.controller';
import SlidingPopup from '../../../components/SlidingPopup/SlidingPopup';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';

interface OwnProps {}

type Props = OwnProps;

const Reports: FunctionComponent<Props> = () => {
  const {
    data,
    period,
    setPeriod,
    averageLoadsPerMuscleGroupsStats,
    statsLoading,
    setApiStatsError,
    apiStatsError,
  } = StatsController();

  const {
    searching,
    onChange,
    resetSearch,
    exerciseToDisplay,
    onClickExercise,
    showStats,
    exerciseByDateChart,
    setStatToDisplay,
    statToDisplay,
    averagePerExerciseStat,
    setShowStats,
    exerciseLoading,
    apiExerciseError,
    setApiExerciseError,
    onClickSearchButton,
  } = ExercisesController();

  const {
    dataWeight,
    dataMeasures,
    loading,
    userStats,
    dataWeightOpt,
    dataMeasuresOpt,
    apiUserError,
    setUserApiError,
  } = UserStatsController();

  if (exerciseLoading || loading || statsLoading) return <LoaderSpinner />;

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} title="Report" />

        <AthleteStats
          dataWeightOpt={dataWeightOpt}
          dataMeasuresOpt={dataMeasuresOpt}
          dataWeight={dataWeight}
          dataMeasures={dataMeasures}
          maximal={userStats}
        />

        <PlanStats
          data={data}
          period={period}
          setPeriod={setPeriod}
          averageLoadPerMuscleGroup={averageLoadsPerMuscleGroupsStats}
        />

        <ExerciseStats
          exerciseToDisplay={exerciseToDisplay}
          searching={searching}
          onChange={onChange}
          resetSearch={resetSearch}
          onClickSearchButton={onClickSearchButton}
          onClickExercise={onClickExercise}
        />

        <SlidingPopup
          open={showStats}
          handleClose={() => {
            setShowStats(false);
          }}
          additionalStyle={{ height: '30vh' }}
        >
          <StatsContainer>
            <ExerciseStatsSelectorButton statToDisplay={statToDisplay} setStatToDisplay={setStatToDisplay} />

            {statToDisplay === StatToDisplayEnum.PER_DATE && <LineChart options={dataWeightOpt} data={exerciseByDateChart} />}

            {statToDisplay === StatToDisplayEnum.TOTAL && (
            <AverageLoadPerExerciseStat>
              <Box sx={{
                background: 'rgba(255, 255, 255, 0.19)',
                borderRadius: '0.7rem',
                width: '4rem',
                height: '4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <AccessibilityNewIcon sx={iconStyle} />
              </Box>
              <Text type="h1">
                {`${averagePerExerciseStat.toFixed().toString()} kg`}
              </Text>
            </AverageLoadPerExerciseStat>
            )}

          </StatsContainer>
        </SlidingPopup>

        <ErrorPopup
          open={apiStatsError !== '' || apiExerciseError !== '' || apiUserError !== ''}
          handleClose={() => {
            setApiStatsError('');
            setApiExerciseError('');
            setUserApiError('');
          }}
          title="An error occurred :("
          caption={apiStatsError !== '' ? apiStatsError : apiExerciseError !== '' ? apiExerciseError : apiUserError}
          actions
        />

      </>
    </Layout>
  );
};

export default Reports;
