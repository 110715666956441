import React, { Dispatch, SetStateAction } from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import Text from '../../../../../components/CustomTextFormatter/Text';
import BoxedBackButton from '../../../../../components/BoxedBackButton/BoxedBackButton';
import Backdrop from '../../../../../components/Backdrop/Backdrop';
import panca from '../../../../../asset/images/panca.jpg';
import { Container } from './AboveTrainingPage.style';
import { IExerciseInPlan } from '../../../../../common/interfaces/Exercise';
import YoutubeEmbed from '../../../../../components/YoutubeEmbed/YoutubeEmbed';
import './Pulse.css';

interface Props {
    exerciseCompleted: boolean
    recover: boolean
    setRecover: Dispatch<SetStateAction<boolean>>
    currentExercise: IExerciseInPlan | undefined,
    currentExerciseVideo: string | undefined,
}

export default function AboveTrainingPage({
  exerciseCompleted, recover, setRecover, currentExercise, currentExerciseVideo,
}: Props) {
  const renderer: CountdownRendererFn | undefined = ({
    minutes, seconds, milliseconds, completed,
  } : {minutes: number, seconds: number, milliseconds: number, completed: boolean}) => {
    if (completed) {
      setRecover(false);
    } else {
      return (
        <Text type="h1" additionalStyle={{ color: '#f9f9f9', fontSize: '3rem' }}>
          {`${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}:${milliseconds / 10}`}
        </Text>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  return (
    <Container style={{
      height: exerciseCompleted ? '0%' : '60%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottomRightRadius: '4rem',
      borderBottomLeftRadius: '4rem',
      background: 'rgba(255, 255, 255, 0.01)',
      borderBottom: '0.01rem solid rgba(255, 255, 255, 0.1)',
    }}
    >

      {!recover && !exerciseCompleted && (
        <BoxedBackButton indexPage={false} />
      )}

      {recover && (
      <Backdrop additionalStyle={
            {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderBottomRightRadius: '4rem',
              borderBottomLeftRadius: '4rem',
              background: 'linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(54,54,54,1) 28%,'
                  + ' rgba(38,38,38,1) 64%, rgba(29,29,29,1) 85%, rgba(22,22,22,1) 100%)',
            }
        }
      >
        <div className="pulsing-1">
          <Countdown
            date={Date.now() + (currentExercise?.recovery ? currentExercise.recovery * 1000 : 0)}
            precision={2}
            intervalDelay={1}
            zeroPadTime={2}
            renderer={renderer}
          />
        </div>
      </Backdrop>
      )}

      {!recover && !exerciseCompleted && currentExerciseVideo && (
      <YoutubeEmbed embedId={currentExerciseVideo} />
      )}

      {!recover && !exerciseCompleted && !currentExerciseVideo && (
      <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} alt="panca" src={panca} />
      )}

    </Container>
  );
}
