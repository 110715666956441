import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;  
  
  /* Inside auto layout */
  
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  z-index: 1;
`;

export const Scroller = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0.5rem 0;
  overflow: auto;
  gap: 0.5rem;
`;
