import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { IFormFields } from '../../../../components/Form/interfaces/Form';
import { LOGIN } from '../../../../apollo/hooks/useLogin';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { AuthContext } from '../../../../context/auth';

const LoginController = () => {
  const { HOME_PATH, HOME_PT_PATH } = PATH_CONSTANTS;

  const context = useContext(AuthContext);

  const [variables, setVariables] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const [login, { loading, error }] = useLazyQuery(LOGIN, {
    variables,
  });

  const [apiError, setApiError] = useState<string>('');
  useEffect(() => {
    if (error) {
      setApiError(error.message);
    }
  }, [error]);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password is too short - should be 6 chars minimum'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setVariables(values);
      login().then(({ data: dataReponse }) => {
        if (dataReponse?.login && !error) {
          context.login(dataReponse.login);
          if (dataReponse.login.role === 'PT') {
            navigate(HOME_PT_PATH);
          } else {
            navigate(HOME_PATH);
          }
        }
      });
    },
  });

  const formField: IFormFields[] = [
    {
      id: 'email',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      fieldType: 'TextField',
      error: true,
    },
    {
      id: 'password',
      name: 'password',
      label: 'Password',
      type: 'password',
      fieldType: 'TextField',
      error: true,
    },
  ];

  return {
    formik, formField, loading, apiError, setApiError,
  };
};

export default LoginController;
