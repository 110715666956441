import React from 'react';
import Text from '../../../../../components/CustomTextFormatter/Text';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import { muscleGroupSection, titleBarStyle } from './SetLoads.style';
import CustomSlider from '../../../../../components/CustomSlider/CustomSlider';

interface Props {
    index: number
    onChangeSlider: (e: React.ChangeEvent<HTMLInputElement>, id: string | number, value: number) => void
}

export default function SetLoads({
  index, onChangeSlider,
}: Props) {
  return (
    <>
      <TitleBar additionalStyle={titleBarStyle}>
        <Text type="h1" additionalStyle={muscleGroupSection}>
          {`Set ${index + 1}`}
        </Text>
      </TitleBar>
      <CustomSlider id={index + 1} onChange={onChangeSlider} />
    </>
  );
}
