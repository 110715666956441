import React from 'react';
import TitleBar from '../TitleBar/TitleBar';
import Text from '../CustomTextFormatter/Text';
import BackButton from './components/BackButton/BackButton';
import { titleBarStyle } from './Pagebar.style';

interface Props {
    indexPage: boolean
    title: string
    pt?: boolean
    specificPath?: string
}

export default function Pagebar({
  indexPage, title, pt, specificPath,
} : Props) {
  return (
    <TitleBar additionalStyle={titleBarStyle}>
      <>
        <BackButton pt={pt || false} indexPage={indexPage} specificPath={specificPath} />
        <Text type="h1">
          {title}
        </Text>
      </>
    </TitleBar>
  );
}
