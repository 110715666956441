/* eslint-disable operator-linebreak */
import React, { ChangeEvent, useEffect, useReducer } from 'react';
import { Dayjs } from 'dayjs';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/material';
import CustomSlider from '../../../../../components/CustomSlider/CustomSlider';
import {
  PickersContainer, SliderContainer, sliderLabelStyle, sliderStyle,
} from './PlanInfoForm.style';
import SwitchButton from '../../../../../components/SwitchButton/SwitchButton';
import Divider from '../../../../../components/Divider/Divider';
import { infoPlanReducer } from './controller/reducer';
import { IPlanInfoState } from '../../interfaces/plan.form';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import StateControlledForm from '../../../../../components/StateControlledForm/StateControlledForm';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import Text from '../../../../../components/CustomTextFormatter/Text';

interface Props {
    onAddWeeklySession: (e: any, id: number | any, value: number)=>void
    onSubmitPlanInfo: (planInfo: IPlanInfoState)=>void
    planInfo: IPlanInfoState
    phaseList: string[]
    weeklyTrainingNumber: number
}

export default function PlanInfoForm({
  onAddWeeklySession,
  phaseList,
  weeklyTrainingNumber,
  onSubmitPlanInfo,
  planInfo,
} : Props) {
  const [{
    name, caption, phase, active, startDate, endDate,
  }, dispatch] =
      // @ts-ignore
      useReducer(
        infoPlanReducer,
        planInfo,
      );

  useEffect(() => {
    if (planInfo) {
      // @ts-ignore
      dispatch({ type: 'setPhase', payload: planInfo.phase.value });
      // @ts-ignore
      dispatch({ type: 'setName', payload: planInfo.name.value });
      // @ts-ignore
      dispatch({ type: 'setCaption', payload: planInfo.caption.value });
      // @ts-ignore
      dispatch({ type: 'setActive', payload: planInfo.active.value });
      // @ts-ignore
      dispatch({ type: 'setStartDate', payload: planInfo.startDate.value });
      // @ts-ignore
      dispatch({ type: 'setEndDate', payload: planInfo.endDate.value });
    }
  }, [planInfo]);

  const onClickSubmitButton = () => {
    let errors = false;
    if (name.value === '') {
      // @ts-ignore
      dispatch({ type: 'setErrorName', payload: 'Devi scegliere un nome' });
      errors = true;
    }
    if (!errors) {
      onSubmitPlanInfo({
        name, caption, phase, active, startDate, endDate,
      });
    }
  };

  const formField = [
    {
      fieldType: 'Select',
      id: 'phaseValue',
      value: phase.value,
      label: 'Fase di allenamento',
      list: phaseList,
      // @ts-ignore
      onChange: (e: SelectChangeEvent | any) => { dispatch({ type: 'setPhase', payload: e.target.value }); },
    },
    {
      type: 'text',
      fieldType: 'TextField',
      placeholder: 'Prima scheda',
      value: name.value,
      label: 'Nome',
      // @ts-ignore
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { dispatch({ type: 'setName', payload: e.target.value }); },
      error: name.error !== '',
      helperText: name.error,
    },
    {
      type: 'text',
      fieldType: 'TextField',
      placeholder: 'Focus sulla forza',
      value: caption.value,
      label: 'Descrizione',
      // @ts-ignore
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { dispatch({ type: 'setCaption', payload: e.target.value }); },
      error: caption.error !== '',
      helperText: caption.error,
    },
  ];

  const dateFields = [
    {
      fieldType: 'DatePicker',
      label: 'Data di inizio',
      dateValue: startDate.value,
      // @ts-ignore
      handleChangeDate: (newValue: Dayjs | null) => { dispatch({ type: 'setStartDate', payload: newValue }); },
    },
    {
      fieldType: 'DatePicker',
      label: 'Data di fine',
      dateValue: endDate.value,
      // @ts-ignore
      handleChangeDate: (newValue: Dayjs | null) => { dispatch({ type: 'setEndDate', payload: newValue }); },
    },
  ];

  return (
    <>

      <TitleBar>
        <Text type="h2">Informazioni piano</Text>
      </TitleBar>

      <Divider />

      <Box sx={{ overflow: 'auto', height: '60vh', flexGrow: 1 }}>

        <StateControlledForm formFields={formField} />

        <Divider />

        <SliderContainer>
          <CustomSlider
            displayValue={weeklyTrainingNumber}
            labelStyle={sliderLabelStyle}
            sliderStyle={sliderStyle}
            defaultValue={1}
            max={7}
            min={1}
            step={1}
            onChange={onAddWeeklySession}
            label="Wourkout settimanali"
          />
        </SliderContainer>

        <Divider />

        <PickersContainer>
          <StateControlledForm formFields={dateFields} />
        </PickersContainer>

        <Divider />

        <SwitchButton
          // @ts-ignore
          onChange={() => { dispatch({ type: 'setActive', payload: !active.value }); }}
          checked={active.value}
          labelChecked="Attivo"
          labelUnchecked="Non attivo"
          additionalStyle={{ marginTop: '1rem', alignSelf: 'flex-start', flexDirection: 'row' }}
        />

        <Divider />

      </Box>

      <CustomButton
        onClick={onClickSubmitButton}
        label="Avanti"
        disabled={weeklyTrainingNumber <= 0}
      />
    </>
  );
}
