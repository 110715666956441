import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const TrainingIconsGroupContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
`;

export const iconStyle = {
  width: '2.5rem',
  height: '2.5rem',
  color: '#f9f9f9',
};
