import { ApolloError, gql, useQuery } from '@apollo/client';
import { ITrainingPhase } from '../../common/interfaces/TrainingPhases';

export const GET_TRAINING_PHASES_BY_PT_ID = gql`
    query trainingPhases($ptId: ID!){
        trainingPhases(ptId: $ptId) {
            _id
            pt
            phases{
                name
                description
                period
                eta
                _id
            }
        }
    }
`;

// eslint-disable-next-line no-unused-vars
type ControllerFN = (ptId:string | undefined) => {
    trainingPhases: ITrainingPhase;
    trainingPhasesLoading: boolean;
    error: ApolloError | undefined
};

export const useGetTrainingPhasesByPtId: ControllerFN = (ptId: string | undefined) => {
  const { data, loading, error } = useQuery(GET_TRAINING_PHASES_BY_PT_ID, {
    variables: { ptId },
  });

  const trainingPhases = data?.trainingPhases;
  const trainingPhasesLoading = loading;

  return { trainingPhasesLoading, trainingPhases, error };
};
