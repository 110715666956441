import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Scroller = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0.7rem 0 0.8rem 0;
  overflow: auto;
  gap: 0.8rem;
`;

export const TextFieldContainer = styled(Box)`
  position: relative;
`;

export const sliderContainerStyle = {
  display: 'flex', alignItems: 'center', gap: '2.5rem', margin: '1.6rem',
};

export const sliderLabelStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'noWrap',
};

export const percentageMuscleGroupCard = {
  background: 'linear-gradient(180deg, hsla(0, 86%, 66%, 1) 0%, hsla(0, 86%, 66%, 0.8) 100%)',
  width: '100%',
  flexGrow: 1,
  borderRadius: '1rem!important',
};

export const muscleGroupCard = {
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
  width: '100%',
  borderRadius: '1rem!important',
};

export const addExerciseCard = {
  background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
  width: '100%',
  borderRadius: '1rem!important',
  height: '80vh',
};
