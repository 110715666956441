import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import Layout from '../../../components/Layout/Layout';
import Pagebar from '../../../components/Pagebar/Pagebar';
import Card from '../../../components/Card/Card';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Text from '../../../components/CustomTextFormatter/Text';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { CssTextField } from '../../../components/Form/Form.style';
import PTAddMuscleGroupsController from './controller/PTAddMuscleGroup.controller';
import { iconButtonStyle, IconContainer } from './PTAddMuscleGroup.style';
import { ReactComponent as AbsIcon } from '../../../asset/icons/mg/abs.svg';
import { ReactComponent as BackIcon } from '../../../asset/icons/mg/back.svg';
import { ReactComponent as BicepsIcon } from '../../../asset/icons/mg/biceps.svg';
import { ReactComponent as CardioIcon } from '../../../asset/icons/mg/cardio.svg';
import { ReactComponent as ChestIcon } from '../../../asset/icons/mg/chest.svg';
import { ReactComponent as DorsalIcon } from '../../../asset/icons/mg/dorsal.svg';
import { ReactComponent as LegsIcon } from '../../../asset/icons/mg/legs.svg';
import { ReactComponent as TricepsIcon } from '../../../asset/icons/mg/triceps.svg';
import { iconStyle } from '../../../common/styles/svgIcons.style';
import IconButton from '../../../components/IconButton/IconButton';
import ErrorPopup from '../../../components/ErrorPopup/ErrorPopup';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { pageMainCard } from '../../../common/styles/commonComponents';

interface OwnProps {}

type Props = OwnProps;

const PTAddMuscleGroup: FunctionComponent<Props> = () => {
  const {
    name,
    setName,
    onSaveMuscleGroup,
    setIcon,
    icon,
    formError,
    setFormError,
    loading,
    setApiError,
    apiError,
  } = PTAddMuscleGroupsController();

  if (loading) return (<LoaderSpinner />);

  return (
    <Layout>
      <>
        <Pagebar indexPage={false} pt title="Aggiungi gruppo" />
        <Card
          additionalStyle={pageMainCard}
          cardContentStyle={{ gap: '2rem' }}
        >
          <>

            <Box sx={{
              overflow: 'auto', height: '60vh', flexGrow: 1, gap: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
            >
              <Card
                additionalStyle={{
                  width: '95%',
                  backgroundColor: 'transparent',
                  borderRadius: '1rem',
                  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
                }}
              >
                <>
                  <TitleBar>
                    <Text type="h2">Info</Text>
                  </TitleBar>
                  <CssTextField
                    sx={{ input: { color: 'white!important' } }}
                    InputLabelProps={{
                      style: { color: '#fff!important' },
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="Name"
                    label="Nome"
                    placeholder="Pettorali"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </>
              </Card>

              <Card
                additionalStyle={{
                  width: '95%',
                  backgroundColor: 'transparent',
                  borderRadius: '1rem',
                  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
                }}
                cardContentStyle={{ gap: '1rem' }}
              >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                  <TitleBar>
                    <Text type="h2">
                      Icona
                    </Text>
                  </TitleBar>

                  <IconButton
                    onClick={() => setIcon('no')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'no' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <BlockIcon sx={{ color: 'white' }} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('cardio')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'cardio' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <CardioIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('abs')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'abs' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <AbsIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('back')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'back' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <BackIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('dorsal')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'dorsal' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <DorsalIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('chest')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'chest' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <ChestIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('legs')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'legs' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <LegsIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('biceps')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'biceps' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <BicepsIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>

                  <IconButton
                    onClick={() => setIcon('triceps')}
                    additionalStyle={{ ...iconButtonStyle, backgroundColor: icon === 'triceps' ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.2)' }}
                  >
                    <IconContainer>
                      <TricepsIcon style={iconStyle} />
                    </IconContainer>
                  </IconButton>
                </Box>
              </Card>
            </Box>

            <CustomButton
              label="Registra"
              onClick={onSaveMuscleGroup}
            />
          </>
        </Card>

        <ErrorPopup
          open={formError !== ''}
          handleClose={() => setFormError('')}
          title="Wait!"
          caption={formError}
          actions
        />

        <ErrorPopup open={apiError !== ''} handleClose={() => setApiError('')} title="An error occurred :(" caption={apiError} actions />
      </>
    </Layout>
  );
};

export default PTAddMuscleGroup;
