import React from 'react';
import { Box, ListItemAvatar } from '@mui/material';
import Text from '../CustomTextFormatter/Text';
import useGetMuscleGroupIcons from '../../hooks/useGetMuscleGroupIcons';
import { iconStyle } from '../../common/styles/svgIcons.style';
import { ListItemContainer, StyledAvatar } from './ListItem.style';

export default function ListItem({
  avatarSource, name, age, plansNumber, onClick, additionalStyle, noAvatar,
} : { avatarSource?: string, name: string, age?: string, plansNumber?: string, onClick?: ()=>void, additionalStyle?:any, noAvatar?: boolean }) {
  const { selectedIcon } = useGetMuscleGroupIcons(avatarSource || '');

  return (
    <ListItemContainer
      sx={additionalStyle}
      onClick={onClick}
    >
      {!avatarSource && !noAvatar && <StyledAvatar>{name.charAt(0)}</StyledAvatar>}
      {avatarSource && (
        <ListItemAvatar sx={iconStyle}>
          <StyledAvatar>
            {selectedIcon}
          </StyledAvatar>
        </ListItemAvatar>
      )}
      <Text type="subtitle2" additionalStyle={{ flexGrow: 1 }}>{name}</Text>
      {age && plansNumber && (
        <Box>
          <Text type="body2">
            {`Età: ${age}`}
          </Text>
          <Text type="body2">
            {`Piani: ${plansNumber}`}
          </Text>
        </Box>
      )}
    </ListItemContainer>
  );
}
