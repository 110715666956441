import React, { Dispatch, SetStateAction } from 'react';
import HorizontalSection from '../../../../../components/HorizontalSection/HorizontalSection';
import StatsCard from '../../../../../components/StatsCard/StatsCard';
import { DoughnutChart } from '../../../../../components/Charts/Doughnut/DoughnutChart';
import PeriodSelectionButtons from '../../../../../components/PeriodSelectionButtons/PeriodSelectionButtons';
import { AverageLoadsContainer } from '../../Reports.style';
import StatsPlanExerciseCard
  from '../../../../../components/StatsCard/components/StatsPlanExerciseCard/StatsPlanExerciseCard';
import { IAverageLoadPerMuscleGroupStatsResponse } from '../../../../../common/interfaces/Stats';
import { DoughnutData } from '../../../../../components/Charts/Doughnut/interfaces/Doughnut';

interface Props {
    data: DoughnutData | Record<string, unknown>
    period: number
    setPeriod: Dispatch<SetStateAction<number>>
    averageLoadPerMuscleGroup: IAverageLoadPerMuscleGroupStatsResponse[] | undefined
}

export default function PlanStats({
  data, period, setPeriod, averageLoadPerMuscleGroup,
}: Props) {
  return (
    <HorizontalSection title="Piano" additionalStyle={{ flexGrow: 1 }}>
      <>
        <StatsCard statName="Gruppi muscolari focali">
          <>
            <DoughnutChart data={data} />
            <PeriodSelectionButtons period={period} setPeriod={setPeriod} />
          </>
        </StatsCard>
        <StatsCard statName="Carichi medi">
          <AverageLoadsContainer>
            {averageLoadPerMuscleGroup?.map((stat) => (
              <StatsPlanExerciseCard muscleGroup={stat} />
            ))}
          </AverageLoadsContainer>
        </StatsCard>
      </>
    </HorizontalSection>
  );
}
