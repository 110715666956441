import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  height: 30%;
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 10px 17px 9px rgba(186,186,186,0.62);
  width: 70%;
  padding: 2rem;
`;

export const textStyle = { textAlign: 'center' };
