import {
  Dispatch, SetStateAction, useEffect, useMemo, useState,
} from 'react';
import { format } from 'date-fns';
import { useGetUserStats } from '../../../../apollo/hooks/useGetUserStats.';
import { IUser } from '../../../../common/interfaces/User';
import { ILineData } from '../../../../components/Charts/Line/interfaces/Line';
import { BarData } from '../../../../components/Charts/Bar/interfaces/Bar';
import { ChartOpt } from '../../../../common/interfaces/Chart';

type ControllerFN = (userId: string) => {
  dataWeight: ILineData | Record<string, unknown>
  dataMeasures: BarData | Record<string, unknown>
  loading: boolean
  userStats: IUser
  dataWeightOpt: ChartOpt
  dataMeasuresOpt: ChartOpt
  apiUserError: string
  setUserApiError: Dispatch<SetStateAction<string>>
};

const UserStatsController: ControllerFN = (userId) => {
  const [apiUserError, setUserApiError] = useState<string>('');

  const { userStats, loading, error } = useGetUserStats(userId);

  useEffect(() => {
    if (error) {
      setUserApiError(error.message);
    } else {
      setUserApiError('');
    }
  }, [error]);

  const dataWeight = useMemo(() => ({
    labels: userStats?.weight?.map((w) => format(w.date, 'dd/MM')),
    datasets: [
      {
        label: 'Andamento del peso',
        data: userStats?.weight?.map((w) => w.weight),
        borderColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
    ],
  }), [userStats]);

  const dataWeightOpt = {
    responsive: true,
    title: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
          fontWeight: 'bold',
        },
      },
      y: {
        ticks: {
          color: 'white',
          fontWeight: 'bold',
          callback(value: string) {
            return `${value} kg`;
          },
        },
      },
    },
  };

  const dataMeasures = useMemo(() => ({
    labels: userStats?.measures?.map((w) => format(w.date, 'dd/MM')),
    datasets: [
      {
        label: 'BMI',
        data: userStats?.measures?.map((w) => w.bmi),
        backgroundColor: 'rgba(255, 253, 250, 1)',
      },
      {
        label: 'Torace',
        data: userStats?.measures?.map((w) => w.bust),
        backgroundColor: 'rgba(54, 162, 235, 1)',
      },
      {
        label: 'Vita',
        data: userStats?.measures?.map((w) => w.waist),
        backgroundColor: 'rgba(255, 206, 86, 1)',
      },
      {
        label: 'Fianchi',
        data: userStats?.measures?.map((w) => w.hips),
        backgroundColor: 'rgba(75, 192, 192, 1)',
      },
      {
        label: 'Coscia',
        data: userStats?.measures?.map((w) => w.thigh),
        backgroundColor: 'rgba(153, 102, 255, 1)',
      },
    ],
  }), [userStats]);

  const dataMeasuresOpt = {
    responsive: true,
    title: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
          fontWeight: 'bold',
        },
      },
      y: {
        ticks: {
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },
  };

  return {
    dataWeight, dataMeasures, loading, userStats, dataWeightOpt, dataMeasuresOpt, apiUserError, setUserApiError,
  };
};

export default UserStatsController;
