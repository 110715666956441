import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { IExercise, IExerciseInPlan, ISetRep } from '../../../../common/interfaces/Exercise';
import { IMuscleGroup } from '../../../../common/interfaces/MuscleGroup';
import { useGetExercisesByPt } from '../../../../apollo/hooks/useGetExercisesByPt';
import { useGetMuscleGroupsByPt } from '../../../../apollo/hooks/useGetMuscleGroupsByPt';
import { ADD_PLAN } from '../../../../apollo/mutations/addPlan';
import { AuthContext } from '../../../../context/auth';
import { useGetTrainingPhasesByPtId } from '../../../../apollo/hooks/useGetTrainingPhasesByPtId';
import { IPlansInput, IVolumes } from '../../../../common/interfaces/Plans';
import { createPlanInputObjectFromPlanResponse } from '../../../../apollo/helper/utils';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { IPlanInfoState } from '../interfaces/plan.form';
import { IExerciseDetails } from '../interfaces/exerciseDetails.form';

interface ISuperSetExercises {
  exercises: string[]
  randomColor: string
}

const PTAddPlanController = (userId: string | undefined) => {
  const { PLANS_PT_PATH } = PATH_CONSTANTS;

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const [muscleGroupsOrder, setMuscleGroupsOrder] = useState<string[]>([]);
  // Viene riempita solo con il giorno selezionato
  const [exercisesByDayByMuscleGroup, setExerciseByDayByMuscleGroup] = useState<Record<string, IExerciseInPlan[]>>({});
  const [exerciseToDisplay, setExerciseToDisplay] = useState<IExercise[]>([]);
  const [muscleGroupsList, setMuscleGroupsList] = useState<IMuscleGroup[]>([]);
  const [phaseList, setPhaseList] = useState<string[]>(['Nessuna fase']);
  const [planInput, setPlanInput] = useState<IPlansInput | any>(null);
  const [finalizeError, setFinalizeError] = useState<boolean>(false);
  const [viewVolumesError, setViewVolumesError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<string>('');
  // Screen handler
  const [page, setPage] = useState<number>(1);
  const [selectedDay, setSelectedDay] = useState<number>(1);
  const [searching, setSearching] = useState<string>('');
  const [selectedMuscleGroup, setSelectedMuscleGroup] = useState <string>('-1');
  const [planExercisesByDay, setPlanExercisesByDay] = useState<any>({});

  const [openAddExercisePopup, setOpenAddExercisePopup] = useState<boolean>(false);
  const [editExerciseInPlanMode, setEditExerciseInPlanMode] = useState<boolean>(false);
  // Indica l'esercizio che sta venendo aggiunto o modificato
  const [selectedExercise, setSelectedExercise] = useState<IExercise | null>(null);

  // Plan Info
  const [planInfo, setPlanInfo] = useState<IPlanInfoState>({
    name: {
      value: '',
      error: '',
    },
    caption: {
      value: '',
      error: '',
    },
    phase: {
      value: 'Nessuna fase',
      error: '',
    },
    active: {
      value: true,
      error: '',
    },
    startDate: {
      value: dayjs(),
      error: '',
    },
    endDate: {
      value: dayjs(),
      error: '',
    },
  });
  const [weeklyTrainings, setWeeklyTrainings] = useState<number[]>([1]);

  const [exerciseDetails, setExerciseDetails] = useState<IExerciseDetails>({
    formFields: {
      note: {
        value: '',
        error: '',
      },
      recovery: {
        value: 120,
        error: '',
      },
      typeSet: {
        value: 'Normal',
        error: '',
      },
      restPauseSets: {
        value: 1,
        error: '',
      },
      rep: {
        value: 1,
        error: '',
      },
    },
    selectedExerciseSetRep: [],
  });

  const { trainingPhasesLoading, trainingPhases, error: trainingPhaseError } = useGetTrainingPhasesByPtId(id);
  const { exercisesByPtId, loading: exercisesByPtIdLoading, error: exerciseByPtIdError } = useGetExercisesByPt();
  const { muscleGroupsByPtId, loading: muscleGroupsByPtIdLoading, error: muscleGroupsByPtIdError } = useGetMuscleGroupsByPt();

  const [addPlan] = useMutation(ADD_PLAN);

  useEffect(() => {
    if (trainingPhases?.phases && trainingPhases?.phases?.length > 0) {
      const phasesName = trainingPhases.phases.map((tp) => tp.name);
      setPhaseList((prev: string[]) => [...prev, ...phasesName]);
    }
  }, [trainingPhases]);

  useEffect(() => {
    if (muscleGroupsByPtIdError) {
      setApiError(muscleGroupsByPtIdError.message);
    } else if (exerciseByPtIdError) {
      setApiError(exerciseByPtIdError.message);
    } else if (trainingPhaseError) {
      setApiError(trainingPhaseError.message);
    } else {
      setApiError('');
    }
  }, [trainingPhaseError, exerciseByPtIdError, muscleGroupsByPtIdError]);

  useEffect(() => {
    const calculateLoadingValue = trainingPhasesLoading && muscleGroupsByPtIdLoading && exercisesByPtIdLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [trainingPhasesLoading, muscleGroupsByPtIdLoading, exercisesByPtIdLoading]);

  useEffect(() => {
    if (exercisesByPtId) {
      setExerciseToDisplay(exercisesByPtId);
    }
  }, [exercisesByPtId]);

  useEffect(() => {
    if (muscleGroupsByPtId) {
      setMuscleGroupsList(muscleGroupsByPtId);
    }
  }, [muscleGroupsByPtId]);

  const createExerciseByMuscleGroupByDay = (plan: IPlansInput, updatedDay?:number) => {
    if (plan) {
      const groupByDay = plan.exercise?.reduce((group: any, product: any) => {
        const { day } = product;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        group[day] = group[day] ?? [];
        // @ts-ignore
        group[day].push(product);
        return group;
      }, {});

      const groupByMg = groupByDay[updatedDay?.toString() || selectedDay.toString()]?.reduce((group: any, product: any) => {
        const muscleGroup = product.exercise.muscleGroups[0].muscleGroup.name;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        group[muscleGroup] = group[muscleGroup] ?? [];
        // @ts-ignore
        group[muscleGroup].push({ ...product });
        return group;
      }, {});

      const mgOrder: string[] = [];

      if (groupByMg) {
        Object.keys(groupByMg).forEach((mg: string) => {
          mgOrder.push(mg);
        });

        setMuscleGroupsOrder(mgOrder);
        setExerciseByDayByMuscleGroup(groupByMg);
      } else {
        setExerciseByDayByMuscleGroup({});
      }
    }
  };

  // COMMON
  const handleChangePage = (direction: string) => {
    setPage((prev) => (direction === 'back' ? prev - 1 : prev + 1));
  };

  // COMMON PAGE 1 e 3
  const onChangeSelectedDay = (day: number) => {
    setSelectedDay(day);
    // creo exerciseByMuscleGroupByDay ogni volta che cambio il giorno
    createExerciseByMuscleGroupByDay(planInput, day);
    setExerciseToDisplay(exercisesByPtId);
  };

  // PAGE 1 - PLAN INFO
  const onAddWeeklySession = (e: React.ChangeEvent<HTMLInputElement>, _idW: number | never, value: number) => {
    const arr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < value; i++) {
      arr.push(i + 1);
    }

    setWeeklyTrainings(arr);
  };

  const onSubmitPlanInfo = (planInfoSubmit: IPlanInfoState) => {
    setPlanInfo(planInfoSubmit);
    setPage(2);
  };

  // PAGE 2 - CHOOSE EXERCISE

  // Searchbar

  const resetSearch = () => {
    setSearching('');
    setExerciseToDisplay(selectedMuscleGroup !== '-1'
    // @ts-ignore
      ? exercisesByPtId.filter((ex) => ex?.muscleGroups[0]?.muscleGroup._id === selectedMuscleGroup)
      : exercisesByPtId);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (exercisesByPtId) {
      setExerciseToDisplay(selectedMuscleGroup !== '-1'
      // @ts-ignore
        ? exercisesByPtId.filter((ex) => ex?.name?.includes(newValue) && ex?.muscleGroups[0]?.muscleGroup._id === selectedMuscleGroup)
        : exercisesByPtId.filter((ex) => ex?.name?.includes(newValue)));
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (exercisesByPtId) {
      setExerciseToDisplay(selectedMuscleGroup !== '-1'
      // @ts-ignore
        ? exercisesByPtId.filter((ex) => ex?.name?.includes(searching) && ex?.muscleGroups[0]?.muscleGroup._id === selectedMuscleGroup)
        : exercisesByPtId.filter((ex) => ex?.name?.includes(searching)));
    }
  };

  const reorderPlanExerciseByDay = () => {
    const orderedExerciseList: IExercise[] = [];
    Object.keys(exercisesByDayByMuscleGroup).forEach((key) => {
      orderedExerciseList.push(...exercisesByDayByMuscleGroup[key]);
    });
    const currentExerciseList = [...planInput.exercise];

    // SORT EXERCISE
    const noCurrentSessionExerciseList: IExercise[] = [];
    currentExerciseList.forEach((ex) => {
      if (ex.day !== selectedDay) {
        noCurrentSessionExerciseList.push(ex);
      }
    });
    const updatedExerciseList = [
      ...noCurrentSessionExerciseList,
      ...orderedExerciseList,
    ];

    const updatedPlanInput = { ...planInput };
    updatedPlanInput.exercise = updatedExerciseList;
    setPlanInput(updatedPlanInput);
    createExerciseByMuscleGroupByDay(updatedPlanInput);
  };

  const reorderPlan = () => {
    if (exercisesByDayByMuscleGroup && Object.keys(exercisesByDayByMuscleGroup).length) {
      reorderPlanExerciseByDay();
    }
  };

  const onAddSupersetToExercisesInPlan = (supersetExercises: ISuperSetExercises) => {
    const planDraftClone = { ...planInput };
    const newExerciseList = planDraftClone?.exercise?.map((ex: IExerciseInPlan) => {
      if (supersetExercises.exercises.some((ss: string) => ss === ex.exercise._id)) {
        return {
          ...ex,
          setRep: ex.setRep.map((sr: ISetRep) => ({
            ...sr,
            type: 'SuperSet',
          })),
          superSet: supersetExercises.exercises,
          color: supersetExercises.randomColor,
        };
      }
      return ex;
    });
    planDraftClone.exercise = [...newExerciseList];
    setPlanInput(planDraftClone);
  };

  // Apre popup al click su un esercizio da aggiungere
  const onAddExerciseToPlan = (ex: IExercise) => {
    setOpenAddExercisePopup(true);
    setSelectedExercise(ex);
  };

  // Selezione mg dal filtro
  const onSelectMuscleGroup = (newMuscleGroup: string) => {
    setSelectedMuscleGroup(newMuscleGroup);
    // -1 indicates no filter
    if (newMuscleGroup === '-1') {
      setExerciseToDisplay(exercisesByPtId);
    } else {
      // @ts-ignore
      const filteredExercise = exercisesByPtId.filter((ex) => ex?.muscleGroups[0]?.muscleGroup._id === newMuscleGroup);
      setExerciseToDisplay(filteredExercise);
    }
  };

  const onGoToSelectTrainingSets = () => {
    if (Object.keys(planExercisesByDay).length === weeklyTrainings.length) {
      const exercisesArray: any = [];
      Object.keys(planExercisesByDay).forEach((day) => {
        planExercisesByDay[day].forEach((ex: any) => {
          const exObj = {
            exercise: {
              _id: ex._id,
              name: ex.name,
              muscleGroups: ex.muscleGroups,
            },
            note: ex.note,
            recovery: parseInt(ex.recovery, 10),
            day: parseInt(day.substring(3, 4), 10),
            setRep: ex.setRep,
            superSet: [],

          };
          exercisesArray.push(exObj);
        });
      });
      const {
        name,
        caption,
        active,
        phase,
        startDate,
        endDate,
      } = planInfo;
      const planInputDraft = {
        name: name.value,
        caption: caption.value,
        active: active.value,
        phase: phase.value,
        days: weeklyTrainings.length,
        owner: userId,
        exercise: exercisesArray,
        startDate: dayjs(startDate.value).format('MM/DD/YYYY'),
        endDate: dayjs(endDate.value).format('MM/DD/YYYY'),
        volumes: [],
      };
      setPlanInput(planInputDraft);
    } else {
      setFinalizeError(true);
    }
  };

  const onClickEditExerciseToPlan = (ex: IExerciseInPlan) => {
    const existingExercise = planExercisesByDay[`day${selectedDay}`].find(({ _id }:{_id: string}) => ex.exercise._id === _id);
    if (existingExercise) {
      setEditExerciseInPlanMode(true);
      setOpenAddExercisePopup(true);
      setSelectedExercise(ex.exercise);
      setExerciseDetails((prev) => ({
        ...prev,
        formFields: {
          ...exerciseDetails.formFields,
          note: {
            value: existingExercise.note,
            error: '',
          },
          recovery: {
            value: existingExercise.recovery,
            error: '',
          },
        },
        selectedExerciseSetRep: existingExercise.setRep,
      }));
    }
  };

  const onDeleteExerciseFromPlan = (exId:string) => {
    // const exerciseToDisplayAgain = planExercisesByDay[`day${selectedDay}`].filter((ex: IExercise) => ex._id === exId);
    // setExerciseToDisplay((prev: IExercise[]) => [...prev, exerciseToDisplayAgain[0]]);
    const newPlan = planExercisesByDay;
    newPlan[`day${selectedDay}`] = planExercisesByDay[`day${selectedDay}`].filter((ex: IExercise) => ex._id !== exId);
    if (newPlan[`day${selectedDay}`].length <= 0) {
      delete newPlan[`day${selectedDay}`];
    }
    setPlanExercisesByDay(newPlan);

    // UPDATE DI exercisesByDayByMuscleGroup PER AGGIORNARE IL RISULTATO NELLA SCHEDA PREVIEW
    const exercisesByDayByMuscleGroupClone = { ...exercisesByDayByMuscleGroup };
    let exToDeleteIndex;
    let exToDeleteMg;
    Object.keys(exercisesByDayByMuscleGroupClone).forEach((mg) => {
      exToDeleteIndex = exercisesByDayByMuscleGroupClone[mg].findIndex((exInMg: IExerciseInPlan) => exInMg.exercise._id === exId);
      if (exToDeleteIndex !== -1) {
        exToDeleteMg = mg;
      }
    });
    if (exToDeleteMg && exToDeleteIndex !== -1 && exToDeleteIndex !== undefined) {
      exercisesByDayByMuscleGroupClone[exToDeleteMg].splice(exToDeleteIndex, 1);
      if (exercisesByDayByMuscleGroupClone[exToDeleteMg].length === 0) {
        delete exercisesByDayByMuscleGroupClone[exToDeleteMg];
      }
    }
    setExerciseByDayByMuscleGroup(exercisesByDayByMuscleGroupClone);
  };

  // PAGE 3 - SELECT TRAINING SETS
  const onSetVolumes = (volumes: IVolumes[]) => {
    const plan = { ...planInput };
    const volumesClone = [...volumes];
    plan.exercise.forEach((ex: any) => {
      const exerciseVolumes = ex.setRep.reduce((a: number, c: ISetRep) => a + c.rep, 0);

      const mgFoundInVolumes = volumesClone.findIndex((vol) => ex.exercise.muscleGroups[0].muscleGroup._id === vol.muscleGroup._id);

      if (mgFoundInVolumes !== -1) {
        volumesClone[mgFoundInVolumes] = {
          ...volumesClone[mgFoundInVolumes],
          volume: volumesClone[mgFoundInVolumes].volume + exerciseVolumes,
        };
      } else {
        volumesClone.push({
          muscleGroup: {
            _id: ex.exercise.muscleGroups[0].muscleGroup._id,
            name: ex.exercise.muscleGroups[0].muscleGroup.name,
          },
          trainingSets: 0,
          volume: exerciseVolumes,
        });
      }
    });
    plan.volumes = [...volumesClone];
    setPlanInput(plan);
    if (plan.volumes.length > 0) {
      handleChangePage('forward');
    } else {
      setViewVolumesError(true);
    }
  };

  const onViewVolumes = () => {
    // if (planInput.volumes.length > 0) {
    //   setPage(4);
    // } else {
    //   setViewVolumesError(true);
    // }
  };

  // PAGE 4 - VIEW VOLUMES

  const onAddPlan = () => {
    const parsedPlan = createPlanInputObjectFromPlanResponse(planInput);
    addPlan({
      variables: {
        planInput: parsedPlan,
        idUser: userId,
        ptId: id,
      },
    }).then(() => {
      navigate(`${PLANS_PT_PATH}/${userId}`);
    });
  };

  // POPUP AGGIUNTA ESERCIZIO
  const onEditExerciseInPlan = (ex: IExercise | null, submittedExerciseDetails: IExerciseDetails) => {
    const { formFields, selectedExerciseSetRep } = submittedExerciseDetails;
    const { note, recovery } = formFields;
    if (ex) {
      const planExerciseClone = { ...planExercisesByDay };
      planExerciseClone[`day${selectedDay}`] = planExerciseClone[`day${selectedDay}`].map((existingExercise: IExercise) => {
        if (existingExercise._id === ex?._id) {
          return {
            ...existingExercise,
            recovery: recovery.value,
            note: note.value,
            setRep: selectedExerciseSetRep,
          };
        }
        return existingExercise;
      });
      setEditExerciseInPlanMode(false);
      setPlanExercisesByDay(planExerciseClone);
      setOpenAddExercisePopup(false);
      setSelectedExercise(null);

      // UPDATE DI exercisesByDayByMuscleGroup PER AGGIORNARE IL RISULTATO NELLA SCHEDA PREVIEW
      const exMuscleGroup = ex.muscleGroups && ex.muscleGroups[0]?.muscleGroup?.name ? ex.muscleGroups[0].muscleGroup.name : '';
      const exercisesByDayByMuscleGroupExactMg = [...exercisesByDayByMuscleGroup[exMuscleGroup]];
      const exToEditIndex = exercisesByDayByMuscleGroupExactMg.findIndex((exInMg) => exInMg.exercise._id === ex._id);
      exercisesByDayByMuscleGroupExactMg[exToEditIndex] = {
        ...exercisesByDayByMuscleGroupExactMg[exToEditIndex],
        recovery: recovery.value,
        note: note.value,
        setRep: selectedExerciseSetRep,
      };
      const exercisesByDayByMuscleGroupClone = { ...exercisesByDayByMuscleGroup };
      exercisesByDayByMuscleGroupClone[exMuscleGroup] = [...exercisesByDayByMuscleGroupExactMg];
      setExerciseByDayByMuscleGroup(exercisesByDayByMuscleGroupClone);
    }
  };

  const onSaveExerciseInPlan = (exercise: IExercise, submittedExerciseDetails: IExerciseDetails) => {
    const { formFields, selectedExerciseSetRep } = submittedExerciseDetails;
    const { note, recovery } = formFields;
    const a: Record<string, IExercise[]> = { ...planExercisesByDay };
    const b = exerciseToDisplay?.filter((ex: IExercise) => ex?._id === exercise?._id);
    // Initialize
    if (!a[`day${selectedDay}`]) {
      a[`day${selectedDay}`] = [];
    }

    if (b) {
      const obj = {
        ...b[0],
        recovery: recovery.value,
        note: note.value,
        setRep: selectedExerciseSetRep,
      };
      if (a[`day${selectedDay}`].findIndex((ex) => ex._id === exercise._id) === -1) {
        a[`day${selectedDay}`].push(obj);
      }
    }
    setPlanExercisesByDay(a);

    const exercisesArray: any = [];
    Object.keys(a).forEach((day) => {
      a[day].forEach((ex: any) => {
        const exObj = {
          exercise: {
            _id: ex._id,
            name: ex.name,
            muscleGroups: ex.muscleGroups,
          },
          note: ex.note,
          recovery: parseInt(ex.recovery, 10),
          day: parseInt(day.substring(3, 4), 10),
          setRep: ex.setRep,
          superSet: [],

        };
        exercisesArray.push(exObj);
      });
    });
    const {
      name,
      caption,
      active,
      phase,
      startDate,
      endDate,
    } = planInfo;
    const planInputDraft = {
      name: name.value,
      caption: caption.value,
      active: active.value,
      phase: phase.value,
      days: weeklyTrainings.length,
      owner: userId || '',
      exercise: exercisesArray,
      startDate: dayjs(startDate.value).format('MM/DD/YYYY'),
      endDate: dayjs(endDate.value).format('MM/DD/YYYY'),
      volumes: [],
    };
    setPlanInput(planInputDraft);
    createExerciseByMuscleGroupByDay(planInputDraft);
    setOpenAddExercisePopup(false);
    setSelectedExercise(null);
  };

  const handleClosePopup = () => {
    setSelectedExercise(null);
    setOpenAddExercisePopup(false);
  };

  return {
    onAddWeeklySession,
    weeklyTrainings,
    selectedDay,
    onChangeSelectedDay,
    searching,
    onChange,
    resetSearch,
    selectedMuscleGroup,
    onSelectMuscleGroup,
    page,
    exerciseToDisplay,
    onAddExerciseToPlan,
    onDeleteExerciseFromPlan,
    onEditExerciseInPlan,
    onSaveExerciseInPlan,
    openAddExercisePopup,
    selectedExercise,
    muscleGroupsList,
    loading,
    onAddPlan,
    phaseList,
    planInput,
    onGoToSelectTrainingSets,
    onAddSupersetToExercisesInPlan,
    finalizeError,
    setFinalizeError,
    viewVolumesError,
    setViewVolumesError,
    editExerciseInPlanMode,
    handleClosePopup,
    onSubmitPlanInfo,
    planInfo,
    exerciseDetails,
    apiError,
    setApiError,
    exercisesByDayByMuscleGroup,
    setExerciseByDayByMuscleGroup,
    muscleGroupsOrder,
    setMuscleGroupsOrder,
    onClickEditExerciseToPlan,
    onSetVolumes,
    onViewVolumes,
    handleChangePage,
    reorderPlan,
    onClickSearchButton,
  };
};

export default PTAddPlanController;
