/* eslint-disable no-param-reassign */
import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPDATE_PLAN } from '../../../../apollo/mutations/updatePlan';
import { IExercise, IExerciseInPlan, ISetInLoad } from '../../../../common/interfaces/Exercise';
import { useGetActiveUserPlan } from '../../../../apollo/hooks/useGetActiveUserPlan';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { IPlansCardResponse } from '../../../../common/interfaces/Plans';
import { createPlanInputObjectFromPlanResponse } from '../../../../apollo/helper/utils';
import { AuthContext } from '../../../../context/auth';
import { useGetExerciseByPtIdVideo } from '../../../../apollo/hooks/useGetExerciseByPtIdVideo';
import { getKeyByValue } from '../../../../helper/utility';

enum ExerciseStatusEnum {
  UNDONE = 'undone',
  DONE = 'done',
  SKIPPED= 'skipped'
}

type ControllerFN = (numberSession : string | undefined) => {
  currentExercise: IExerciseInPlan | undefined,
  currentSet: number,
  recover: boolean,
  setRecover: Dispatch<SetStateAction<boolean>>
  expandExerciseList: boolean,
  setExpandExerciseList: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setExercise: (e?: any, id?: string, skipped?: boolean, currentExStatus?: Record<string, ExerciseStatusEnum>) => void
  trainingCompleted: boolean
  setTrainingCompleted: Dispatch<SetStateAction<boolean>>
  exerciseCompleted: boolean
  onClickTimerIcon: () => void
  onClickStopTimer: () => void
  onChangeSlider: (e: any, id: string | number, value: number) => void
  onPlayClick: (id: string) => void
  onClickSendLoads: () => void
  onTrainingCompleted: () => void
  activePlan: IPlansCardResponse[] | any
  exercisesByDay: Record<string, any>
  setOpenConfirmSkipDialog: Dispatch<SetStateAction<boolean>>
  openConfirmSkipDialog: boolean
  selectedRating: number
  setSelectedRating: Dispatch<SetStateAction<number>>
  setTrainingNote: Dispatch<SetStateAction<string>>
  trainingNote: string
  currentExerciseVideo: string
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const ExerciseTrainingController: ControllerFN = (numberSession : string | undefined) => {
  const { HOME_PATH } = PATH_CONSTANTS;

  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<string>('');
  const [recover, setRecover] = useState<boolean>(false);
  const [exercisesByDay, setExerciseByDay] = useState<Record<string, []>>({});
  const [trainingNote, setTrainingNote] = useState<string>('');
  const [currentExerciseVideo, setCurrentExerciseVideo] = useState<string>('');
  const [selectedRating, setSelectedRating] = useState<number>(2);
  const [exercisesList, setExercisesList] = useState<any>({});
  const [exerciseIndex, setExerciseIndex] = useState<number>(0);
  const [currentExercise, setCurrentExercise] = useState<IExerciseInPlan | undefined>(undefined);
  const [currentSet, setCurrentSet] = useState<number>(1);
  const [trainingCompleted, setTrainingCompleted] = useState<boolean>(false);
  const [exerciseCompleted, setExerciseCompleted] = useState<boolean>(false);
  const [exerciseLoads, setExerciseLoads] = useState<Record<string, number>>({});
  const [expandExerciseList, setExpandExerciseList] = useState<boolean>(false);
  const [timeoutStart, setTimeoutStart] = useState<any>(null);
  const [openConfirmSkipDialog, setOpenConfirmSkipDialog] = useState<boolean>(false);
  const [exercisesStatus, setExercisesStatus] = useState<Record<string, ExerciseStatusEnum>>({});

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';
  // @ts-ignore
  const ptId = user?.pt[0]?._id || '';

  const { activePlan, loading: activePlanLoading } = useGetActiveUserPlan(id);
  const { exercisesByPtIdVideo, loading: exercisesByPtIdVideoLoading } = useGetExerciseByPtIdVideo(ptId);
  const [updatePlan, { loading: updatePlanLoading, error }] = useMutation(UPDATE_PLAN);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  useEffect(() => {
    const calculateLoadingValue = exercisesByPtIdVideoLoading && activePlanLoading && updatePlanLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [activePlanLoading, exercisesByPtIdVideoLoading, updatePlanLoading]);

  useEffect(() => {
    if (activePlan) {
      const groupByDay = activePlan.exercise?.reduce((group: any, product: any) => {
        const { day } = product;
        // @ts-ignore
        group[day] = group[day] ?? [];
        // @ts-ignore
        group[day].push(product);
        return group;
      }, {});

      const exId: any = {};
      groupByDay[numberSession || '1'].forEach((ex: IExerciseInPlan) => {
        exId[ex._id] = ExerciseStatusEnum.UNDONE;
      });
      setExercisesStatus(exId);

      Object.keys(groupByDay).forEach((day) => {
        // @ts-ignore
        const groupByMg = groupByDay[day].reduce((group, product) => {
          const { name } = product.exercise.muscleGroups[0].muscleGroup;
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          group[name] = group[name] ?? [];
          // @ts-ignore
          group[name].push(product);
          return group;
        }, {});
        // @ts-ignore
        groupByDay[day] = groupByMg;
      });
      setExerciseByDay(groupByDay);
    }
  }, [activePlan]);

  useEffect(() => {
    if (currentExercise && exercisesByPtIdVideo) {
      // @ts-ignore
      const currentExerciseVideoFound = exercisesByPtIdVideo?.find((e: IExercise) => e._id === currentExercise?.exercise._id).video.split('=')[1];
      setCurrentExerciseVideo(currentExerciseVideoFound);
    }
  }, [currentExercise, exercisesByPtIdVideo]);

  useEffect(() => {
    if (Object.keys(exercisesByDay).length) {
      const a: any = [];
      // @ts-ignore
      Object.keys(exercisesByDay[numberSession]).forEach((mg) => {
        // @ts-ignore
        exercisesByDay[numberSession][mg].forEach((e: any) => {
          a.push(e);
        });
      });
      setExercisesList(a);
    }
  }, [exercisesByDay]);

  useEffect(() => {
    if (exercisesList) {
      console.log(exercisesList, 'exerciseList');
      setCurrentExercise(exercisesList[0]);
    }
  }, [exercisesList]);

  useEffect(() => {
    if (recover === false) {
      navigator.vibrate([100]);
    }
  }, [recover]);

  const setExercise = (e?: any, idE?: string, skipped?: boolean, currentExStatus?: Record<string, ExerciseStatusEnum>) => {
    setOpenConfirmSkipDialog(false);
    let exStatus = { ...currentExStatus } || null;
    if (skipped) {
      const currentExerciseId: string = currentExercise?._id || '';
      exStatus = {
        ...exercisesStatus,
        [currentExerciseId]: ExerciseStatusEnum.SKIPPED,
      };
      setExercisesStatus(exStatus);
    }

    setCurrentSet(1);
    if (idE !== null && idE !== undefined) {
      setExerciseIndex(parseInt(idE || '0', 10));
      setCurrentExercise(exercisesList[idE || '0']);
    } else {
      setExerciseIndex((prev) => prev + 1);
      setExerciseCompleted(false);
      if ((exerciseIndex + 1) < exercisesList.length) {
        setCurrentExercise(exercisesList[exerciseIndex + 1]);
      } else if (exStatus && (getKeyByValue(exStatus, ExerciseStatusEnum.SKIPPED)
          || getKeyByValue(exStatus, ExerciseStatusEnum.UNDONE))) {
        const undoneExerciseId = getKeyByValue(exStatus, ExerciseStatusEnum.SKIPPED)
            || getKeyByValue(exStatus, ExerciseStatusEnum.UNDONE);
        const undoneExerciseIndex = exercisesList.findIndex((ex: any) => ex._id === undoneExerciseId);
        setCurrentExercise(exercisesList[undoneExerciseIndex]);
      } else {
        setTrainingCompleted(true);
      }
    }
  };

  const changeSetNumber = () => {
    if (currentExercise && (currentSet < currentExercise?.setRep?.length)) {
      setCurrentSet((prev) => prev + 1);
    } else {
      setExerciseCompleted(true);
    }
  };

  const onClickTimerIcon = () => {
    setRecover(true);
    navigator.vibrate([100]);
    setTimeoutStart(setTimeout(changeSetNumber, currentExercise?.recovery ? currentExercise.recovery * 1000 : 0));
  };

  const onClickStopTimer = () => {
    setRecover(false);
    navigator.vibrate([100, 100, 100]);
    changeSetNumber();
    clearTimeout(timeoutStart);
  };

  const onChangeSlider = (e: any, idS: any, value: any) => {
    const index = idS;
    const obj: any = {};
    obj[index] = value;
    const dataToSend = {
      ...exerciseLoads,
      ...obj,
    };
    setExerciseLoads(dataToSend);
  };

  const onClickSendLoads = () => {
    const loadToAdd: ISetInLoad[] = [];
    Object.keys(exerciseLoads).forEach((load) => {
      const obj: ISetInLoad = {
        noSet: parseInt(load, 10),
        loadInKg: exerciseLoads[load],
      };
      loadToAdd.push(obj);
    });
    const loadsInput = {
      date: new Date(),
      set: loadToAdd,
    };
    activePlan?.exercise?.forEach((ex: any) => {
      if (ex._id === currentExercise?._id) {
        ex.loads = [...ex.loads, loadsInput];
      }
    });
    const currentExerciseId: string = currentExercise?._id || '';
    const exStatus = {
      ...exercisesStatus,
      [currentExerciseId]: ExerciseStatusEnum.DONE,
    };
    setExercisesStatus(exStatus);
    setExercise(null, undefined, false, exStatus);
    setExerciseCompleted(false);
  };

  const onTrainingCompleted = () => {
    if (activePlan) {
      activePlan.lastTrainingDate = new Date();
      activePlan.lastTrainingNote = trainingNote;
      activePlan.lastTrainingRating = selectedRating;
      const planInput = createPlanInputObjectFromPlanResponse(activePlan);
      console.log(planInput, 'planInpi');
      updatePlan({
        variables: {
          idUser: id,
          idPlan: activePlan._id,
          planInput,
          ptId,
        },
      }).then(() => {
        navigate(HOME_PATH);
      });
    }
  };

  const onPlayClick = (idE: string) => {
    const exIndex = exercisesList.findIndex((exercise: any) => exercise._id === idE);
    setExercise(null, exIndex);
    setExpandExerciseList(false);
  };

  return {
    currentExercise,
    recover,
    loading,
    setExercise,
    trainingCompleted,
    currentSet,
    onClickTimerIcon,
    setRecover,
    exerciseCompleted,
    onChangeSlider,
    onClickSendLoads,
    activePlan,
    exercisesByDay,
    onPlayClick,
    expandExerciseList,
    setExpandExerciseList,
    onClickStopTimer,
    setOpenConfirmSkipDialog,
    openConfirmSkipDialog,
    setTrainingCompleted,
    selectedRating,
    setSelectedRating,
    trainingNote,
    setTrainingNote,
    onTrainingCompleted,
    currentExerciseVideo,
    apiError,
    setApiError,
  };
};

export default ExerciseTrainingController;
