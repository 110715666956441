import React from 'react';
import { Grid } from '@mui/material';
import Text from '../CustomTextFormatter/Text';
import { StyledSlider } from './CustomSlider.style';

interface Props {
    labelStyle?: Record<string, unknown>,
    sliderStyle?: Record<string, unknown>,
    onChange: (e: React.ChangeEvent<HTMLInputElement>, id: number | string, value: number) => void
    label?: string
    id?: string | number
    max?: number
    min?: number
    step?: number
    defaultValue?: number
    displayValue?: number
}

export default function CustomSlider({
  onChange, labelStyle, label, id, max, step, sliderStyle, defaultValue, displayValue, min,
}: Props) {
  return (

    <Grid container alignItems="center" justifyContent="space-around" sx={{ marginTop: '2rem', marginLeft: 0 }}>
      <Grid item xs={label !== '' ? 6 : 12}>
        <StyledSlider
          aria-label="Carichi"
          defaultValue={defaultValue !== undefined ? defaultValue : 30}
          value={displayValue}
          valueLabelDisplay="on"
          step={step || 1.25}
          min={min || 0}
          max={max || 200}
          // @ts-ignore
          onChangeCommitted={(e, value) => onChange(e, id, value)}
          sx={{ height: '11px', color: 'white', ...sliderStyle }}
        />
      </Grid>
      <Grid item xs={3}>
        <Text additionalStyle={labelStyle} type="body1">{label || ''}</Text>
      </Grid>
    </Grid>
  );
}
