import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ContentContainer = styled(Box)`
  height: 100%; 
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  `;

export const IconContainer = styled(Box)`
  background: rgba(255, 255, 255, 0.19);
  border-radius: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  `;
