import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IExercise } from '../../../../common/interfaces/Exercise';
import { GET_EXERCISES_BY_PT } from '../../../../apollo/hooks/useGetExercisesByPt';
import { AuthContext } from '../../../../context/auth';
import { DELETE_TRAINING_PHASE } from '../../../../apollo/mutations/deleteTrainingPhase';
import { DELETE_EXERCISE } from '../../../../apollo/mutations/deleteExercise';

type ControllerFN = () => {
    searching: string;
    onChange: (e: any) => void
    onClickSearchButton: () => void
    resetSearch: () => void
    exerciseToDisplay: IExercise[] | null
    loading: boolean
    apiError: string
    setApiError: Dispatch<SetStateAction<string>>
    setOpenDeletePopup: Dispatch<SetStateAction<boolean>>
    setExerciseToBeHandled: Dispatch<SetStateAction<IExercise| Record<string, unknown>>>
    openDeletePopup: boolean
    onDeleteExercise: () => void
};

const PTExercisesController: ControllerFN = () => {
  const [searching, setSearching] = useState<string>('');
  const [exerciseToDisplay, setExerciseToDisplay] = useState<IExercise[]| null>(null);
  const [exercisesByPtId, setExercisesByPtId] = useState<IExercise[]| null>(null);
  const [apiError, setApiError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [exerciseToBeHandled, setExerciseToBeHandled] = useState < IExercise | Record<string, unknown> >({});

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const ptId = user?._id || '';

  const [getExerciseByPtId, { loading: exerciseByPtIdLoading, error }] = useLazyQuery(GET_EXERCISES_BY_PT, {
    variables: {
      ptId,
    },
  });
  const [updateExercise, { loading: updateExerciseLoading, error: updateTrainingPhaseError }] = useMutation(DELETE_TRAINING_PHASE);
  const [deleteExercise, { loading: deleteExerciseLoading, error: deleteTrainingPhaseError }] = useMutation(DELETE_EXERCISE);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else if (updateTrainingPhaseError) {
      setApiError(updateTrainingPhaseError.message);
    } else if (deleteTrainingPhaseError) {
      setApiError(deleteTrainingPhaseError.message);
    } else {
      setApiError('');
    }
  }, [error, updateTrainingPhaseError, deleteTrainingPhaseError]);

  useEffect(() => {
    const calculateLoadingValue = exerciseByPtIdLoading && updateExerciseLoading && deleteExerciseLoading;
    if (calculateLoadingValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [exerciseByPtIdLoading, updateExerciseLoading, deleteExerciseLoading]);

  const getData = () => {
    getExerciseByPtId().then((res) => {
      setExercisesByPtId(res.data?.exercisesByPtId);
      setExerciseToDisplay(res?.data?.exercisesByPtId);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const resetSearch = () => {
    setSearching('');
    setExerciseToDisplay(exercisesByPtId);
  };

  const onChange = (e: any) => {
    const newValue:string = e.target.value;
    setSearching(newValue);
    if (exercisesByPtId) {
      setExerciseToDisplay([...exercisesByPtId.filter((ex: IExercise) => ex?.name?.includes(newValue))]);
    }
    if (newValue === '') {
      resetSearch();
    }
  };

  const onClickSearchButton = () => {
    if (exercisesByPtId) {
      setExerciseToDisplay([...exercisesByPtId.filter((ex: IExercise) => ex?.name?.includes(searching))]);
    }
  };

  const onDeleteExercise = () => {
    setOpenDeletePopup(false);
    deleteExercise({
      variables: {
        id: exerciseToBeHandled._id,
        ptId,
      },
    }).then(() => {
      setExerciseToBeHandled({});
      getData();
    });
  };

  return {
    searching,
    onChange,
    resetSearch,
    exerciseToDisplay,
    loading,
    apiError,
    setApiError,
    onDeleteExercise,
    setOpenDeletePopup,
    openDeletePopup,
    setExerciseToBeHandled,
    onClickSearchButton,
  };
};

export default PTExercisesController;
