/* eslint-disable no-param-reassign */
import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { format } from 'date-fns';
import { useGetActiveUserPlan } from '../../../../apollo/hooks/useGetActiveUserPlan';
import { IPlansCardResponse } from '../../../../common/interfaces/Plans';
import { useGetLoggedUserMacronutrients } from '../../../../apollo/hooks/useGetLoggedUserMacronutrients';
import { IMacronutrients } from '../../../../common/interfaces/User';
import { AuthContext } from '../../../../context/auth';

interface ILastTrainingStats {
  date: string
  rating: number
  note: string
}

interface Props {
  lastTrainingStats: ILastTrainingStats
  activePlan: IPlansCardResponse | undefined,
  macronutrients: IMacronutrients | undefined,
  loading: boolean,
  sessions: string[] | null
  openNotePopup: boolean
  setOpenNotePopup: Dispatch<SetStateAction<boolean>>
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
}

const TrainingController: () =>
    Props = () => {
      const { user } = useContext(AuthContext);
      // @ts-ignore
      const id = user?._id || '';

      const { activePlan, loading: activePlanLoading, error: activePlanError } = useGetActiveUserPlan(id);
      const { macronutrients, loading: macronutrientsLoading, error: macronutrientsError } = useGetLoggedUserMacronutrients();

      const [loading, setLoading] = useState<boolean>(true);
      const [apiError, setApiError] = useState<string>('');
      const [exercisesByDay, setExerciseByDay] = useState<Record<string, unknown>>({});
      const [lastTrainingStats, setLastTrainingStats] = useState<ILastTrainingStats>({
        date: 'N/D',
        rating: 0,
        note: '',
      });
      const [sessions, setSessions] = useState<string[] | null>(null);
      const [openNotePopup, setOpenNotePopup] = useState<boolean>(false);

      useEffect(() => {
        if (activePlanError) {
          setApiError(activePlanError.message);
        } else if (macronutrientsError) {
          setApiError(macronutrientsError.message);
        } else {
          setApiError('');
        }
      }, [activePlanError, macronutrientsError]);

      useEffect(() => {
        const calculateLoadingValue = macronutrientsLoading && activePlanLoading;
        if (calculateLoadingValue) {
          setLoading(true);
        } else {
          setLoading(false);
        }
      }, [activePlanLoading, macronutrientsLoading]);

      useEffect(() => {
        if (activePlan) {
          const groupByDay = activePlan.exercise?.reduce((group: any, product: any) => {
            const { day } = product;
            // @ts-ignore
            group[day] = group[day] ?? [];
            // @ts-ignore
            group[day].push(product);
            return group;
          }, {});
          setExerciseByDay(groupByDay);
          if (activePlan.lastTrainingDate) {
            const parsedDate = format(new Date(activePlan.lastTrainingDate), 'dd/MM/yyyy') !== '01/01/1970'
              ? format(new Date(activePlan.lastTrainingDate), 'dd/MM/yyyy') : 'N/D';
            setLastTrainingStats({
              date: parsedDate,
              rating: activePlan.lastTrainingRating || 0,
              note: activePlan.lastTrainingNote || '',
            });
          }
        }
      }, [activePlan]);

      useEffect(() => {
        if (Object.keys(exercisesByDay).length) {
          setSessions(Object.keys(exercisesByDay));
        }
      }, [exercisesByDay]);

      return {
        activePlan,
        macronutrients,
        loading,
        sessions,
        lastTrainingStats,
        openNotePopup,
        setOpenNotePopup,
        apiError,
        setApiError,
      };
    };

export default TrainingController;
