import { ApolloError, gql, useQuery } from '@apollo/client';
import { IExercise } from '../../common/interfaces/Exercise';

const GET_EXERCISE_BY_ID = gql`
    query exercise($id: ID!){
        exercise(id: $id) {
            _id
            name
            howTo
            muscleGroups{
                _id
                muscleGroup{
                    name
                }
                percentage
            }
            video
        }
    }
`;

type ControllerFN = (id: string | undefined) => {
    exercise: IExercise | undefined;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetExerciseById: ControllerFN = (id: string | undefined) => {
  const { data, loading, error } = useQuery(GET_EXERCISE_BY_ID, {
    variables: { id },
  });
  const exercise = data?.exercise;
  return { loading, exercise, error };
};
