import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ButtonContainerRow = styled(Box)`
  width: 100%;
  display: flex;
  gap: 1.5rem;
`;

export const shortcutStyle = {
  // background: 'linear-gradient(145deg, rgba(89,89,89,1) 0%, rgba(67,67,67,1) 100%)',
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
  width: '50%',
  height: '7.5rem',
  borderRadius: '1rem!important',
};

export const athletesButton = {
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
  width: '50%',
  height: '7.5rem',
  borderRadius: '0.5rem!important',
};

export const plansButton = {
  background: 'linear-gradient(180deg, hsla(0, 86%, 66%, 1) 0%, hsla(0, 86%, 66%, 0.8) 100%)',
  width: '50%',
  height: '7.5rem',
  borderRadius: '0.5rem!important',
};

export const exercisesButton = {
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
  width: '50%',
  height: '7.5rem',
  borderRadius: '0.5rem!important',
};

export const muscleGroupsButton = {
  background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
  width: '50%',
  height: '7.5rem',
  borderRadius: '0.5rem!important',
};
