// eslint-disable-next-line no-unused-vars
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useGetUser } from '../../../../apollo/hooks/useGetUser';
import { IUser } from '../../../../common/interfaces/User';

// eslint-disable-next-line no-unused-vars
type ControllerFN = (userId : string | undefined) => {
  userInfo: IUser | null;
  loading: boolean
  apiError: string
  setApiError: Dispatch<SetStateAction<string>>
};

const PTAthleteController: ControllerFN = (userId : string | undefined) => {
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [apiError, setApiError] = useState<string>('');

  const { user, loading, error } = useGetUser(userId);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    } else {
      setApiError('');
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      setUserInfo(user);
    }
  }, [user]);

  return {
    userInfo, loading, apiError, setApiError,
  };
};

export default PTAthleteController;
