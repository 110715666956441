import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ContentWrapper = styled(Box)`
  background-color: rgba(255,255, 255, 0.2); 
  border-radius: 1rem; 
  padding: 0.5rem;
`;

export const cardStyle = {
  boxShadow: '0.31rem 0.31rem 0.25rem rgba(0, 0, 0, 0.08)',
  borderRadius: '1rem',
  width: '21.25rem',
  minWidth: '21.25rem',
  maxWidth: '21.25rem',
  background: 'linear-gradient(180deg, #6681F6 0%, hsla(196, 85%, 65%, 1)  100%)',
};

export const cardContentStyle = {
  height: '100%',
};

export const titleStyle = {
  color: 'white',
  flexGrow: 1,
};
