import React, { Dispatch, SetStateAction } from 'react';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import { ButtonContainer, buttonStyle } from './ExerciseStatsSelectorButton.style';
import { StatToDisplayEnum } from '../../../../../common/enums/StatsToDisplayEnum';

export default function ExerciseStatsSelectorButton({ statToDisplay, setStatToDisplay } :
{ statToDisplay: string, setStatToDisplay: Dispatch<SetStateAction<StatToDisplayEnum>>}) {
  return (
    <ButtonContainer>
      <CustomButton
        additionalStyle={{
          backgroundColor: statToDisplay === StatToDisplayEnum.TOTAL ? 'rgba(249, 249, 249, 0.5)' : 'rgba(249, 249, 249, 0.2)',
          fontWeight: statToDisplay === StatToDisplayEnum.TOTAL ? 'bold' : '600',
          ...buttonStyle,
        }}
        onClick={() => setStatToDisplay(StatToDisplayEnum.TOTAL)}
        label="Totale"
      />
      <CustomButton
        additionalStyle={{
          backgroundColor: statToDisplay === StatToDisplayEnum.PER_DATE ? 'rgba(249, 249, 249, 0.5)' : 'rgba(249, 249, 249, 0.2)',
          fontWeight: statToDisplay === StatToDisplayEnum.PER_DATE ? 'bold' : '600',
          ...buttonStyle,
        }}
        onClick={() => setStatToDisplay(StatToDisplayEnum.PER_DATE)}
        label="Per data"
      />
    </ButtonContainer>
  );
}
