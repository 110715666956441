import { IExerciseDetailsForm, IExerciseDetailsState } from '../../../interfaces/exerciseDetails.form';
import { ISetRep } from '../../../../../../common/interfaces/Exercise';

type ExerciseDetailsAction =
    { type: 'setFormFields'; payload: IExerciseDetailsForm }
    | { type: 'setSelectedExerciseSetRep'; payload: ISetRep[] }
    | { type: 'setError'; payload: string }

export const exerciseDetailsReducer = (state: IExerciseDetailsState, action: ExerciseDetailsAction) => {
  switch (action.type) {
    case 'setFormFields': {
      return {
        ...state,
        formFields: action.payload,
      };
    }
    case 'setSelectedExerciseSetRep': {
      return {
        ...state,
        selectedExerciseSetRep: action.payload,
      };
    }
    case 'setError': {
      return {
        ...state,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
