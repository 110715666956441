import { ApolloError, gql, useQuery } from '@apollo/client';
import { IAverageLoadPerMuscleGroupStatsResponse } from '../../common/interfaces/Stats';

const GET_AVERAGE_LOAD_PER_MUSCLE_GROUP = gql`
    query averageLoadsPerMuscleGroupsStats($idUser: ID!, $period: Int!){
        averageLoadsPerMuscleGroupsStats(idUser: $idUser, period: $period) {
            muscleGroup
            averageLoad
            icon
        }
    }
`;

type ControllerFN = (idUser: string) => {
    averageLoadsPerMuscleGroupsStats: IAverageLoadPerMuscleGroupStatsResponse[] | undefined;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetAverageLoadPerMuscleGroup: ControllerFN = (id: string) => {
  const { data, loading, error } = useQuery(GET_AVERAGE_LOAD_PER_MUSCLE_GROUP, {
    variables: { idUser: id, period: 0 },
  });
  const averageLoadsPerMuscleGroupsStats = data?.averageLoadsPerMuscleGroupsStats;
  return { averageLoadsPerMuscleGroupsStats, loading, error };
};
