import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const macroCardStyle = {
  background: 'linear-gradient(180deg, hsla(0, 86%, 66%, 1) 0%, hsla(0, 86%, 66%, 0.8) 100%)',
  width: '100%',
  borderRadius: '1rem!important',
};

export const sliderContainerStyle = {
  display: 'flex', alignItems: 'center', gap: '2.5rem', margin: '1.2rem',
};

export const sliderLabelStyle = { color: '#F9F9F9' };

export const sliderStyle = { color: '#F9F9F9' };

export const infoCardStyle = {
  background: 'linear-gradient(180deg, #2DD799 0%, hsla(196, 85%, 65%, 1) 65.17%)',
  width: '100%',
  borderRadius: '1rem!important',
};

export const PickersContainer = styled(Box)`
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
`;
