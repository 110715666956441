import {
  ChangeEvent, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { SIGNUP } from '../../../../apollo/mutations/signup';
import { PATH_CONSTANTS } from '../../../../helper/constants';
import { AuthContext } from '../../../../context/auth';
import { IAthleteState } from '../interfaces/athlete.form';
import { IMeasuresInput, IWeightInput } from '../../../../common/interfaces/User';

const AddAthleteController = () => {
  const { ATHLETES_PT_PATH } = PATH_CONSTANTS;
  const [kcals, setKcals] = useState<number>(0);
  const [proteins, setProteins] = useState<number>(0);
  const [fats, setFats] = useState<number>(0);
  const [carbs, setCarbs] = useState<number>(0);
  const [measures, setMeasures] = useState<IMeasuresInput>({
    date: dayjs(),
    bmi: 0,
    bust: 0,
    waist: 0,
    hips: 0,
    thigh: 0,
  });
  const [weight, setWeight] = useState<IWeightInput>({
    date: dayjs(),
    weight: 0,
  });
  const [apiError, setApiError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  // Athlete Info
  const [athleteInfo, setAthleteInfo] = useState<IAthleteState>({
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    name: {
      value: '',
      error: '',
    },
    age: {
      value: '',
      error: '',
    },
    startDate: {
      value: dayjs(),
      error: '',
    },
  });

  const macroAccordionSliders = [
    {
      defaultValue: 0,
      max: 5000,
      step: 50,
      onChange: (e: ChangeEvent<HTMLInputElement>, id: number|string, value: number) => { setKcals(value); },
      label: 'Kcals',
    },
    {
      defaultValue: 0,
      max: 500,
      step: 10,
      onChange: (e: ChangeEvent<HTMLInputElement>, id: number|string, value: number) => { setCarbs(value); },
      label: 'Carbs',
    },
    {
      defaultValue: 0,
      max: 300,
      step: 10,
      onChange: (e: ChangeEvent<HTMLInputElement>, id: number|string, value: number) => { setProteins(value); },
      label: 'Prots',
    },
    {
      defaultValue: 0,
      max: 300,
      step: 10,
      onChange: (e: ChangeEvent<HTMLInputElement>, id: number|string, value: number) => { setFats(value); },
      label: 'Fats',
    },
  ];

  const onChangeMeasuresSliders = (e: ChangeEvent<HTMLInputElement>, id: number|string, value: number) => {
    setMeasures({
      ...measures,
      [id]: value,
    });
  };

  const measuresAccordionSliders = [
    {
      defaultValue: 0,
      max: 60,
      step: 0.1,
      onChange: onChangeMeasuresSliders,
      label: 'BMI',
      id: 'bmi',
    },
    {
      defaultValue: 0,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Torace',
      id: 'bust',
    },
    {
      defaultValue: 0,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Vita',
      id: 'waist',
    },
    {
      defaultValue: 0,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Fianchi',
      id: 'hips',
    },
    {
      defaultValue: 0,
      max: 300,
      step: 1,
      onChange: onChangeMeasuresSliders,
      label: 'Coscia',
      id: 'thigh',
    },
  ];

  const weightAccordionSliders = [
    {
      defaultValue: 0,
      max: 150,
      step: 0.5,
      onChange: (e: ChangeEvent<HTMLInputElement>, id: number|string, value: number) => setWeight({ ...weight, weight: value }),
      label: 'kg',
      id: 'weight',
    },
  ];

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  // @ts-ignore
  const id = user?._id || '';

  const [signup, { error, loading: signupLoading }] = useMutation(SIGNUP);

  useEffect(() => {
    if (error) {
      setApiError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (signupLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [signupLoading]);

  const onSubmitAthlete = (athleteInfoSubmit: IAthleteState) => {
    setAthleteInfo(athleteInfoSubmit);
    signup({
      variables: {
        userInput: {
          email: athleteInfoSubmit.email.value,
          password: athleteInfoSubmit.password.value,
          name: athleteInfoSubmit.name.value,
          age: athleteInfoSubmit.age.value,
          startDate: athleteInfoSubmit.startDate.value,
          role: 'ATHLETE',
          pt: [id],
          macronutrients: {
            kcals,
            proteins,
            carbs,
            fats,
          },
          measures: [{ ...measures }],
          weight: [{ ...weight }],
        },
      },
    }).then(() => {
      navigate(ATHLETES_PT_PATH);
    });
  };

  return {
    measuresAccordionSliders,
    macroAccordionSliders,
    athleteInfo,
    apiError,
    setApiError,
    loading,
    onSubmitAthlete,
    weightAccordionSliders,
  };
};

export default AddAthleteController;
