import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fe5f55',
    },
    secondary: {
      main: '#577399',
      dark: '#2A333C',
    },
    background: {
      default: '#f9f9f9',
      paper: '#1B1833',
    },
    text: {
      primary: '#F9F9F9',
      secondary: '#F9F9F9',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontWeight: 600,
      fontSize: '1.4rem',
      lineHeight: 2.12,
    },
    subtitle1: {
      fontSize: '1.4rem',
    },
    subtitle2: {
      fontWeight: 700,
      lineHeight: 0.81,
    },
    h2: {
      fontWeight: 'normal',
      fontSize: '1.5rem',
      lineHeight: 0.75,
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.12rem',
      lineHeight: '1.37rem',
    },
    body1: {
      fontWeight: 600,
      fontSize: '0.9rem',
      lineHeight: 1.37,
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.9rem',
      lineHeight: 1.37,
      letterSpacing: '-0.8px',
    },
  },
  overrides: {
    MuiInput: {
      root: {
        color: 'white!important',
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        // eslint-disable-next-line max-len
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
