import React, { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { Fade, IconButton } from '@mui/material';
import styled from 'styled-components';
import { searchBarIconStyle } from '../../pages/pt/PTHomePage/PTHomePage.style';

interface Props {
    placeholder: string
    value: string
    onCancelSearch: () => void
    onChange: (e: any) => void
    onClick: () => void
    showCancelButton: boolean
}

const StyledInput = styled.input`
  flex-grow: 1;
  background: transparent;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-width: 0;
  color: #f9f9f9;
  padding: 1rem;
  font-size: 1rem;
  &:focus {
    border: none;
    outline: none;
   }
`;

const StyledContainer = styled.div`
  height: 3.5rem;
  border-radius: 1.5rem;
  width: 100%;
  background: transparent;
  border: 0.01rem solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const CustomSearchBar = ({
  placeholder, value, onChange, onCancelSearch, onClick, showCancelButton,
}: Props) => (
  <StyledContainer>
    <StyledInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      type="text"
      id="searchbar"
      name="search"
    />
    <Fade in={value === '' || !showCancelButton}>
      <IconButton
        sx={{ padding: '1rem', position: 'absolute', right: '1rem' }}
        aria-label="search"
        onClick={onClick}
      >
        <FiSearch style={searchBarIconStyle} />
      </IconButton>
    </Fade>
    {showCancelButton && (
    <Fade in={value !== ''}>
      <IconButton
        sx={{ padding: '1rem', position: 'absolute', right: '1rem' }}
        aria-label="search"
        onClick={onCancelSearch}
      >
        <RxCross2 style={searchBarIconStyle} />
      </IconButton>
    </Fade>
    )}
  </StyledContainer>
);

export default CustomSearchBar;
