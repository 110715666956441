import React, {
  Dispatch, SetStateAction, useRef, useState,
} from 'react';
import { IMuscleGroup } from '../../../../../common/interfaces/MuscleGroup';
import AddExerciseToPlan from '../AddExerciseToPlan/AddExerciseToPlan';
import { IExercise, IExerciseInPlan } from '../../../../../common/interfaces/Exercise';
import { selectExerciseCard } from '../PlanPreview/PlanPreview.style';
import Card from '../../../../../components/Card/Card';
import Divider from '../../../../../components/Divider/Divider';
import PlanPreview from '../PlanPreview/PlanPreview';
import ViewSelector from './components/ViewSelector';

interface IAddPlanFilters {
    searching: string,
    onChange: (e: any) => void,
    onClickSearchButton: () => void,
    resetSearch: () => void,
    muscleGroupsList: IMuscleGroup[],
    selectedMuscleGroup: string | null,
    onSelectMuscleGroup: (newMuscleGroup: string) => void,
    onAddExerciseToPlan: (ex: IExercise) => void,
    exerciseToDisplay: IExercise[] | any
    onAddSupersetToExercisesInPlan: (supersetExercisesList: any) => void
    exercisesByDayByMuscleGroup: Record<string, IExerciseInPlan[]>
    setExerciseByDayByMuscleGroup: Dispatch<SetStateAction<Record<string, IExerciseInPlan[]>>>
    muscleGroupsOrder: string[]
    setMuscleGroupsOrder: Dispatch<SetStateAction<string[]>>
    onClickEditExerciseToPlan: (ex: IExerciseInPlan) => void
    onDeleteExerciseFromPlan: (exId: string) => void
 }

export default function ChooseExercise({
  exercisesByDayByMuscleGroup, setExerciseByDayByMuscleGroup,
  searching, onChange, resetSearch, muscleGroupsList, selectedMuscleGroup, onSelectMuscleGroup,
  exerciseToDisplay, onAddExerciseToPlan, onAddSupersetToExercisesInPlan, onClickSearchButton,
  muscleGroupsOrder, setMuscleGroupsOrder, onClickEditExerciseToPlan, onDeleteExerciseFromPlan,
} : IAddPlanFilters) {
  const [boxPosition, setBoxPosition] = useState<string>('translateX(4px)');
  const [viewPreview, setViewPreview] = useState<boolean>(false);

  const gridItemRef = useRef<HTMLDivElement>(null);

  const handleClick = (position: string) => {
    setViewPreview(!viewPreview);
    if (gridItemRef.current) {
      const { width } = gridItemRef.current.getBoundingClientRect();
      if (position === 'right') {
        setBoxPosition(`translateX(${width - 4}px)`);
      } else {
        setBoxPosition('translateX(4px)');
      }
    }
  };

  return (
    <Card additionalStyle={selectExerciseCard} cardContentStyle={{ gap: '1rem' }}>
      <>

        <ViewSelector
          boxPosition={boxPosition}
          handleClick={handleClick}
          gridItemRef={gridItemRef}
        />

        <Divider additionalStyle={{ margin: 0 }} />

        <AddExerciseToPlan
          searching={searching}
          onChange={onChange}
          resetSearch={resetSearch}
          open={!viewPreview}
          exerciseToDisplay={exerciseToDisplay}
          onAddExerciseToPlan={onAddExerciseToPlan}
          onSelectMuscleGroup={onSelectMuscleGroup}
          selectedMuscleGroup={selectedMuscleGroup}
          muscleGroupsList={muscleGroupsList}
          exercisesByDayByMuscleGroup={exercisesByDayByMuscleGroup}
          onClickSearchButton={onClickSearchButton}
        />

        <PlanPreview
          muscleGroupsOrder={muscleGroupsOrder}
          setMuscleGroupsOrder={setMuscleGroupsOrder}
          exercisesByDayByMuscleGroup={exercisesByDayByMuscleGroup}
          setExerciseByDayByMuscleGroup={setExerciseByDayByMuscleGroup}
          open={viewPreview}
          onAddSupersetToExercisesInPlan={onAddSupersetToExercisesInPlan}
          onClickEditExerciseToPlan={onClickEditExerciseToPlan}
          onDeleteExerciseFromPlan={onDeleteExerciseFromPlan}
        />

      </>
    </Card>
  );
}
