import React, { FunctionComponent } from 'react';
import { Box, Grid } from '@mui/material';
import Card from '../../../../../components/Card/Card';
import { IPlansInput, IVolumes } from '../../../../../common/interfaces/Plans';
import Divider from '../../../../../components/Divider/Divider';
import { IconContainer } from '../../PTEditPlan.style';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import DetailsBox from '../../../../../components/DetailsBox/DetailsBox';
import Text from '../../../../../components/CustomTextFormatter/Text';
import StatsCard from '../../../../../components/StatsCard/StatsCard';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import { pageMainCard } from '../../../../../common/styles/commonComponents';

interface OwnProps {
    plan: IPlansInput | any
    handleChangePage: (direction: string) => void
    onAddPlan: ()=>void
}

type Props = OwnProps;

const ViewVolumes: FunctionComponent<Props> = ({
  plan, onAddPlan, handleChangePage,
}) => (
  <Card
    additionalStyle={pageMainCard}
  >
    <>

      <TitleBar>
        <Text type="h2">Volumi</Text>
      </TitleBar>

      <Divider />

      <Box sx={{
        flexGrow: 1, height: '60vh', overflow: 'auto', display: 'flex', gap: '1rem', flexDirection: 'column',
      }}
      >

        <Box sx={{
          height: '100%', overflow: 'auto', gap: '1rem', flexDirection: 'column', display: 'flex',
        }}
        >

          {plan?.volumes?.map((vol: IVolumes) => (
            <StatsCard
              additionalCardStyle={{
                marginRight: 0,
                width: '100%',
                maxWidth: '100%!important',
                background: 'transparent',
                boxShadow: '0rem 0rem 0rem',
                borderBottom: '0.01rem solid rgba(255, 255, 255, 0.1)',
              }}
              statName={vol.muscleGroup?.name || 'N/D'}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DetailsBox label="Serie allenanti">
                    <Text type="body1">{`${vol.trainingSets.toString()}` || 'N/D'}</Text>
                  </DetailsBox>
                </Grid>
                <Grid item xs={6}>
                  <DetailsBox label="Volumi">
                    <Text type="body1">{`${vol.volume.toString()}` || 'N/D'}</Text>
                  </DetailsBox>
                </Grid>
              </Grid>
            </StatsCard>
          ))}
        </Box>

      </Box>

      <Divider />

      <IconContainer>
        <CustomButton
          onClick={() => { handleChangePage('back'); }}
          label="Indietro"
        />
        <CustomButton
          onClick={onAddPlan}
          label="Modifica piano"
        />
      </IconContainer>

    </>
  </Card>
);

export default ViewVolumes;
