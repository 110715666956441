import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex; 
  align-items: center;
  width: 100%;
  `;

export const linearProgressStyle = {
  height: '1rem',
  borderRadius: '1.5rem',
  backgroundColor: 'rgba(255,255,255,0.3)',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: 'rgba(255,255,255,1)',
  },
};
