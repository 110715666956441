import { ApolloError, gql, useQuery } from '@apollo/client';
import { IPlansCardResponse } from '../../common/interfaces/Plans';

const GET_ACTIVE_PLAN_VOLUMES = gql`
    query activePlan($idUser: ID!){
        activePlan(idUser: $idUser) {
            volumes{
                muscleGroup{
                    name
                }
                volume
            }
        }
    }
`;

// eslint-disable-next-line no-unused-vars
type ControllerFN = (idUser: string | undefined) => {
    activePlanVolumes: IPlansCardResponse[] | undefined;
    loading: boolean;
    error: ApolloError | undefined
};

export const useGetActivePlanVolumes: ControllerFN = (idUser: string | undefined) => {
  const { data, loading, error } = useQuery(GET_ACTIVE_PLAN_VOLUMES, {
    variables: { idUser },
  });
  const activePlanVolumes = data?.activePlan;
  return { loading, activePlanVolumes, error };
};
