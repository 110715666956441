import { Dayjs } from 'dayjs';
import { IPlanInfoState } from '../../../interfaces/plan.form';

type InfoPlanAction =
    { type: 'setName'; payload: string }
    | { type: 'setCaption'; payload: string }
    | { type: 'setPhase'; payload: string }
    | { type: 'setActive'; payload: boolean }
    | { type: 'setStartDate'; payload: Dayjs | null }
    | { type: 'setEndDate'; payload: Dayjs | null }
    | { type: 'setErrorName'; payload: string }
    | { type: 'setErrorCaption'; payload: string }
    | { type: 'setErrorPhase'; payload: string }
    | { type: 'setErrorActive'; payload: boolean }
    | { type: 'setErrorStartDate'; payload: Dayjs | null }
    | { type: 'setErrorEndDate'; payload: Dayjs | null }

export const infoPlanReducer = (state: IPlanInfoState, action: InfoPlanAction) => {
  switch (action.type) {
    case 'setName': {
      return {
        ...state,
        name: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setCaption': {
      return {
        ...state,
        caption: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setPhase': {
      return {
        ...state,
        phase: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setActive': {
      return {
        ...state,
        active: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setStartDate': {
      return {
        ...state,
        startDate: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setEndDate': {
      return {
        ...state,
        endDate: {
          value: action.payload,
          error: '',
        },
      };
    }
    case 'setErrorName': {
      return {
        ...state,
        name: {
          ...state.name,
          error: action.payload,
        },
      };
    }
    case 'setErrorCaption': {
      return {
        ...state,
        caption: {
          ...state.caption,
          error: action.payload,
        },
      };
    }
    case 'setErrorPhase': {
      return {
        ...state,
        phase: {
          ...state.phase,
          error: action.payload,
        },
      };
    }
    case 'setErrorActive': {
      return {
        ...state,
        active: {
          ...state.active,
          error: action.payload,
        },
      };
    }
    case 'setErrorStartDate': {
      return {
        ...state,
        startDate: {
          ...state.startDate,
          error: action.payload,
        },
      };
    }
    case 'setErrorEndDate': {
      return {
        ...state,
        endDate: {
          ...state.endDate,
          error: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
