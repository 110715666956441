import React from 'react';
import Text from '../CustomTextFormatter/Text';
import {
  CalendarBar, CalendarFrame, CalendarLeftDetail, CalendarRightDetail,
} from './CustomCalendarIconLike.style';

interface Props {
    additionalStyle?: Record<string, unknown>,
    day: string,
    onClick: () => void
}

export default function CustomCalendarIconLike({
  additionalStyle, day, onClick,
}: Props) {
  return (
    <CalendarFrame onClick={onClick} sx={additionalStyle}>
      <CalendarBar />
      <CalendarLeftDetail />
      <CalendarRightDetail />
      <Text type="body1" additionalStyle={{ marginTop: '0.8rem' }}>{day}</Text>
    </CalendarFrame>
  );
}
