import React from 'react';
import Text from '../../../CustomTextFormatter/Text';
import Card from '../../../Card/Card';
import {
  cardContentStyle,
  cardStyle,
  LinearProgressContainer,
  muscleGroupNameStyle,
  MuscleNameContainer,
} from './StatsPlanExerciseCard.style';
import LinearProgressWithLabel from '../../../LinearProgress/LinearProgress';
import useGetMuscleGroupIcons from '../../../../hooks/useGetMuscleGroupIcons';
import { IAverageLoadPerMuscleGroupStatsResponse } from '../../../../common/interfaces/Stats';

export default function StatsPlanExerciseCard({ muscleGroup } : { muscleGroup: IAverageLoadPerMuscleGroupStatsResponse }) {
  const { selectedIcon } = useGetMuscleGroupIcons(muscleGroup.icon);
  return (
    <Card additionalStyle={cardStyle} cardContentStyle={cardContentStyle}>
      <>
        <MuscleNameContainer>
          {selectedIcon}
          <Text type="body1" additionalStyle={muscleGroupNameStyle}>
            {muscleGroup.muscleGroup}
          </Text>
        </MuscleNameContainer>
        <LinearProgressContainer>
          <LinearProgressWithLabel value={muscleGroup.averageLoad} unit="Kg" />
        </LinearProgressContainer>
      </>
    </Card>

  );
}
