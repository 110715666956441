import React, { Dispatch, SetStateAction } from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TimerIcon from '@mui/icons-material/Timer';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import IconButton from '../../../../../components/IconButton/IconButton';
import { iconStyle, timerIconStyle } from './ExerciseTrainActionsButtons.style';

interface Props {
    setExpandExerciseList: Dispatch<SetStateAction<boolean>>
    onClickTimerIcon: () => void
    setOpenConfirmSkipDialog: Dispatch<SetStateAction<boolean>>
}

export default function ExerciseTrainActionButtons({
  setExpandExerciseList, onClickTimerIcon, setOpenConfirmSkipDialog,
}: Props) {
  return (
    <>
      <IconButton onClick={() => setExpandExerciseList(true)}>
        <FormatListBulletedIcon sx={iconStyle} />
      </IconButton>
      <IconButton onClick={onClickTimerIcon}>
        <TimerIcon sx={timerIconStyle} />
      </IconButton>
      <IconButton onClick={() => setOpenConfirmSkipDialog(true)}>
        <SkipNextIcon sx={iconStyle} />
      </IconButton>
    </>
  );
}
