import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const athletesCard = {
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
  width: '100%',
  flexGrow: 0.9,
  borderRadius: '1rem!important',
  // maxHeight: '70vh',
  overflow: 'auto',
};

export const athletesCardContent = { display: 'flex', flexDirection: 'column' };

export const AddIconContainer = styled(Box)`
  height: 2.5rem;
  width: 2.5rem;
  background-color: rgba(255,255,255,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
  align-self: flex-end;
`;
