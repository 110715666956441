import {
  ApolloClient, DefaultOptions, HttpLink, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

// const BASE_URL = 'http://localhost:8000/graphql';
// const BASE_URL = 'https://tif-test.onrender.com/graphql';
// const BASE_URL = 'https://tif-be.herokuapp.com/graphql';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

const authLink = setContext(() => {
  const token = localStorage.getItem('jwt');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
});

export default client;
