import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const PickersContainer = styled(Box)`
  display: flex;
  gap: 2rem;
`;

export const SliderContainer = styled(Box)`
  width: 100%;
  margin: 1rem 0 1rem 0;
`;

export const TextFieldContainer = styled(Box)`
  position: relative;
`;

export const sliderContainerStyle = {
  display: 'flex', alignItems: 'center', gap: '2.5rem', margin: '1.2rem',
};

export const sliderLabelStyle = { color: '#F9F9F9' };

export const sliderStyle = { color: '#F9F9F9' };
